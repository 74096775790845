import React, { useState, useEffect, useRef } from "react";
import { Modal, Row, Col, Form, InputNumber, Card, List, Input, Checkbox, Tooltip } from "antd-min";
import { fmtMsg } from "@app/util/func";
import { SchoolLocale } from "@app/locales/localeid";
import { GLLocale } from "gl-commonui";
import { RegionInfoExtended } from "../subscription";
import { StudentInformation } from "..";
import { SubscriptionType } from "@app/service/class";
import { PerfectScrollbar } from "@app/components";
import { LicenseDisabled, RegionType } from "@app/util";
import { connect } from 'react-redux';
import { StateType } from '@app/states';

interface StandardDualSectionDescModalProps {
    onSave: (newInfo: { newDigitalCount: number, newTextbookCount: number, textbooknote: string, digitalnote: string, licensePlusStudentEdited: boolean, removedStudents: string[], noteType: SubscriptionType }) => void;
    onCancel: () => void;
    subType: SubscriptionType;
    licenseCount: { sub: { newCnt: number }, license: { newCnt: number } };
    students: any[];
    visible: boolean;
    title: string;
    regionInfo: RegionInfoExtended;
    canEditLicense: boolean;
    langLoaded?: string;
    currentStudentId?: any;
    disableFutureLicenseEdit?: LicenseDisabled;
};

export const StandardDualSectionDescModal = connect(({ intl: { langLoaded } }: StateType) => ({ langLoaded }))((props: StandardDualSectionDescModalProps) => {
    const { onSave, onCancel, licenseCount, students, visible, title, regionInfo, canEditLicense, subType, langLoaded, currentStudentId, disableFutureLicenseEdit } = props;
    const regionType = regionInfo.id == process.env.jpRegionId ? RegionType.Japan : RegionType.Standard;

    const [decreaseValue, setDecreaseValue] = useState(0);
    const [studentsToRemove, setStudentsToRemove] = useState([]);
    const [note, setNote] = useState("");
    const [isStudentSelectionMode, setIsStudentSelectionMode] = useState<boolean>(false || currentStudentId); // true if currentStudentId is present.
    const _scrollBarRef = useRef(null);

    useEffect(() => {
        if (studentsToRemove.length) {
            setIsStudentSelectionMode(true);
        } else {
            setIsStudentSelectionMode(false);
        }
    }, [studentsToRemove.length]);

    useEffect(() => {
        setTimeout(() => {
            if (_scrollBarRef.current) {
                _scrollBarRef.current.updateScroll();
            }
        }, 500)
    }, [])

    useEffect(() => {
        currentStudentId 
        ? (setStudentsToRemove([...studentsToRemove, currentStudentId]), setDecreaseValue(-1))
        : setStudentsToRemove(studentsToRemove.filter(i => i != currentStudentId));
    }, [currentStudentId])

    const saveInfo = () => {

        if (subType === SubscriptionType.Digital) {
            onSave({
                newDigitalCount: licenseCount.sub.newCnt + decreaseValue,
                newTextbookCount: licenseCount.license.newCnt + decreaseValue,
                textbooknote: note,
                digitalnote: "",
                licensePlusStudentEdited: (decreaseValue < 0 && studentsToRemove.length > 0),
                removedStudents: studentsToRemove,
                noteType: SubscriptionType.Textbook,
            });
        } else {
            onSave({
                newDigitalCount: licenseCount.license.newCnt + decreaseValue,
                newTextbookCount: licenseCount.sub.newCnt + decreaseValue,
                textbooknote: "",
                digitalnote: note,
                licensePlusStudentEdited: (decreaseValue < 0 && studentsToRemove.length > 0),
                removedStudents: studentsToRemove,
                noteType: SubscriptionType.Digital,
            });
        }
    }

    const studentRemoved = (e) => {
        const stuToRemove = e.target.checked
            ? [...studentsToRemove, e.target.value]
            : studentsToRemove.filter(i => i != e.target.value);
            setDecreaseValue(-stuToRemove.length);
            setStudentsToRemove(stuToRemove);
    }

    const ellipsis = (str: string) => {
        if(str && str.length > 20) {
            return <Tooltip title={str} mouseLeaveDelay={0}>{str.substring(0, 20).concat('...')}</Tooltip>;
        }
        return str;
    }

    const renderStudent = (item) => {
        return <List.Item className="sub-modal__item">
            <div className="sub-modal__list-item">
                <Checkbox value={item.id} onChange={studentRemoved} checked={studentsToRemove.findIndex(i => i === item.id) > -1}>
                    {ellipsis(item.name)}
                </Checkbox >
                <StudentInformation infoIconClass="sub-modal__infoicon" studentId={item.id} isDelete={false} showInfoInDrawer={true} />
            </div>
        </List.Item>;
    }

    const valueParser = val => {
        if (val > 0){
            return -val;
        } else if (val < 0) {
            return val;
        }
        return 0;
     }

    const showDecreaseNote = regionInfo && regionInfo.billForPartialMonthRemove && !regionInfo.schoolClassInvoiceInfo.isCurrentInvoiceGenerated;
    const noteLengthError = fmtMsg({ id: GLLocale.FormCprLessOrEqualsThan }, { name: fmtMsg({ id: GLLocale.FormFormatLength }, { name: fmtMsg({ id: SchoolLocale.LicenseNote }).replace(':', "") }), value: 200 });
    const isLicenseCountValid = (licenseCount.license.newCnt + decreaseValue) === (students.length - studentsToRemove.length);
    const isDirty = decreaseValue < 0 || studentsToRemove.length;

    const noteError = note.length > 200;
    return <Modal
        destroyOnClose
        visible={visible}
        title={title}
        onCancel={onCancel}
        onOk={saveInfo}
        okText={fmtMsg({ id: SchoolLocale.EditClassLicenseModalSaveButton })}
        cancelText={fmtMsg({ id: GLLocale.Cancel })}
        okButtonProps={{ className: "sub-modal__btn sub-modal__btn--submit", disabled: !isLicenseCountValid || !isDirty || (regionType === RegionType.Japan && !note.length) }}
        cancelButtonProps={{ className: "sub-modal__btn sub-modal__btn--cancel" }}
        className="sub-modal"
    >
        <Row type="flex">
            <Col className="sub-modal__ccount">{fmtMsg({ id: SchoolLocale.LicenseStudentCnt })}</Col>
            <Col>{licenseCount.license.newCnt}</Col>
        </Row>
        <Form.Item
            label={fmtMsg({ id: SchoolLocale.EditClassDescBy })}
            required={true}
            validateStatus={isLicenseCountValid ? "success" : "error"}
            help={isLicenseCountValid ? null : fmtMsg({ id: SchoolLocale.EditClassLicenceDescDualError })}
            className="sub-modal__input"
        >
            <InputNumber
                value={decreaseValue}
                min={-licenseCount.license.newCnt}
                max={0}
                precision={0}
                parser={valueParser}
                onChange={(value) => {value > 0 ? setDecreaseValue(-value) : setDecreaseValue(value)}} 
                disabled={(disableFutureLicenseEdit && disableFutureLicenseEdit.isLicenseEditDisabled) || isStudentSelectionMode}/>
        </Form.Item>
        <Row type="flex">
            <Col className="sub-modal__ncount">{fmtMsg({ id: SchoolLocale.EditClassNewTotal })}:</Col>
            <Col>{licenseCount.license.newCnt + decreaseValue}</Col>
        </Row>

        <Card title={fmtMsg({ id: SchoolLocale.EditClassCheckStudents })} bordered>
            <PerfectScrollbar style={{ maxHeight: 150 }} className="sub-modal__list" ref = {(ref) => { _scrollBarRef.current = ref; }}>
                <div>
                    <List grid={{ xs: 2, sm: 2, md: 3, gutter: 10 }} dataSource={students} rowKey={(s) => s.id} renderItem={renderStudent}></List>
                </div>
            </PerfectScrollbar>
        </Card>
        <Form.Item
            validateStatus={noteError ? 'error' : 'success'}
            className={noteError ? 'sub-error-row' : 'sub-normal-row'}
            help={noteError ? noteLengthError : null}
            label={fmtMsg({ id: SchoolLocale.LicenseHistoryNotes })}
            required={regionType === RegionType.Japan}
        >
            <Input.TextArea rows={4} disabled={!canEditLicense} defaultValue="" placeholder={fmtMsg({ id: (regionType === RegionType.Japan && langLoaded == 'en') ? SchoolLocale.LicenseDecreaseNotePlaceholderTextJapan : SchoolLocale.LicenseDecreaseNotePlaceholderText}) } onChange={e => setNote(e.target.value)} />
        </Form.Item>
        {showDecreaseNote &&
            <div className="sub-modal__note">
                <span className="sub-modal__label">{ fmtMsg(SchoolLocale.LicenseNote)} </span>
                <span>{fmtMsg({ id: SchoolLocale.EditClassReduceNote })}</span>
            </div>
        }
    </Modal >

})
