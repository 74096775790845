import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { withRouter, GLGlobal, GLLocale, GLUtil, GLForm, GLFormComponentProps, connect, loadable } from 'gl-commonui';
import { Button, Row, Col, Select, InputNumber, Modal, Input, message, Skeleton } from 'antd-min';
import { SchoolLocale, GSAdminLocale, MovePromoteStudentsLocale } from "../../../locales/localeid";
import { ClassHelper, lazyInject, TYPES, fmtMsg, DateHelper, AdjustmentDateFormat, CONSTS } from '../../../util/index';
import { SecondaryTitle } from '../../../components';
import { StateType } from '@app/states';
import { GSSchoolAction, subscriptionTypeUsage, SubscriptionActionType, StudentSubscriptionType, GSAdminAction } from '../../../util/enum';
import { getLicense, goToStudents, updateLicense, getStudentsBy, updateLicenseWithMaterial, reload, ManualAdjustmentInfoModel, setFutureAnnualOuter } from '../../../states/school/class';
import { SubscriptionType, SubscriptionTypeText, CurriculumType, ISchoolClassService } from '../../../service/class';
import { PathConfig } from '../../../config/pathconfig';
import { SchoolModel } from '../../../service/schools';
import { getUsageText, showSubscription, getPeriodFormattedDate, newDigitalCntCpr } from "./licenceComponents";
import { IRegionService, RegionModel } from '@app/service/admin/regions';
import { ISchoolLicenseService, UpdateAdjustmentResultCode } from '@app/service/admin/license';
import moment from "moment";
import { RegenerateInvoice, RegenerateDisableColumns } from './components/regenerate-invoice';
import { isInternalUser, disableFutureLicenseEdit } from "@app/util/func";
import { setMoveStudentsStatus } from "@app/states/school/class-manager";
import "./licenceComponents/license.less";

const getComponent = (exportCallback) => loadable({
    loader: () => import("react-beautiful-dnd"),
    export: exportCallback
})
const DragDropContext = getComponent(({ DragDropContext }) => DragDropContext)
export const Droppable = getComponent(({ Droppable }) => Droppable)
export const Draggable = getComponent(({ Draggable }) => Draggable)

export interface LicenseModel {
    curriculumType?: any
    subTypeLable?: string
    subType?: string
    counts?: {
        sub: { cnt, newCnt, note },
        license: { cnt, newCnt, note }
    }
    period?: () => string
    billedFrom?: () => string
    lastBilledPeriod?: () => string
    lastPeriod?: string
    total?: number
}

export interface LicensePageProps extends LicenseModel {
    getLicense: (d) => void;
    goToStudents: () => void;
    updateLicense: (d: { schoolId: string, classId: string, data: any }) => void;
    updateLicenseWithMaterial: (d: { schoolId: string, classId: string, data: any }) => void;
    getStudentsBy: (d) => void;
    reload: (d) => void;
    school?: SchoolModel;
    students: any;
    origStudents: any[];
    classRoles: any;
    setFutureAnnualOuter: (d) => void;
    futureAnnualOuter: boolean;
    licenseUpdated: boolean;
    startDate?: Date;
    licenseEditError: boolean;
    endDate?: Date;
    setMoveStudentsStatus: (d) => void;
    moveStudentDisable: boolean;
    licenseType: number;
}

interface LicensePageState {
    origSchool: SchoolModel;
    regionInfo: RegionModel;
    adjustmentModalVisible: boolean;
    regenerateDrawerVisible: boolean;
    selectedCycleDate: string;
    cycleAdjustmentData: ManualAdjustmentInfoModel;
    origCycleAdjustmentData: ManualAdjustmentInfoModel;
    classInvoiceInfo: any;
    isCreateClassWizard: boolean;
    origCounts: {
        sub: { cnt, newCnt, note },
        license: { cnt, newCnt, note }
    };
}

@withRouter
@GLForm.create()
@connect(({ schoolClass: { licenseEditError, futureAnnualOuter, licenseUpdated, total, classRoles, license: { counts, period, billedFrom, lastBilledPeriod, curriculumType, subTypeLable, subType, startDate }, students, origStudents, model: { endDate, licenseType } }, school: { current: school }, campus: { model }, classManager: { moveStudentDisable } }: StateType) => ({
    total,
    curriculumType,
    licenseType,
    subTypeLable,
    subType,
    counts,
    period,
    billedFrom,
    lastBilledPeriod,
    school,
    students,
    origStudents,
    classRoles,
    licenseEditError,
    futureAnnualOuter,
    licenseUpdated,
    startDate,
    endDate,
    moveStudentDisable
}), {
    getLicense,
    goToStudents,
    getStudentsBy,
    updateLicense,
    updateLicenseWithMaterial,
    setFutureAnnualOuter,
    reload,
    setMoveStudentsStatus
}, ({ state: { curriculumType, licenseType, startDate, subType, counts, total, school: { subscriptionTypeUsage }, students }, own: { match: { params: { schoolId, classId } } }, dispatch: { updateLicense, updateLicenseWithMaterial } }) => ({
    updateLicense,
    updateLicenseWithMaterial,
    model: { curriculumType, licenseType, startDate, subType, counts, total, school: { subscriptionTypeUsage }, students: formatStudentForLittleSEED(students, curriculumType), }
}))
export class LicensePage extends Component<LicensePageProps & RouteComponentProps<any> & GLFormComponentProps, LicensePageState> {
    isMaterial = false;
    @lazyInject(TYPES.IRegionService)
    regionService: IRegionService;

    @lazyInject(TYPES.ISchoolLicenseService)
    licenseService: ISchoolLicenseService;

    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: ISchoolClassService;

    constructor(props) {
        super(props);
        this.reRenderHandler = this.reRenderHandler.bind(this);
        this.toggleDrawerVisibility = this.toggleDrawerVisibility.bind(this);
        this.state = {
            origSchool: {},
            regionInfo: null,
            adjustmentModalVisible: false,
            regenerateDrawerVisible: false,
            selectedCycleDate: undefined,
            cycleAdjustmentData: null,
            origCycleAdjustmentData: null,
            classInvoiceInfo: null,
            isCreateClassWizard: false,
            origCounts: props.counts ? JSON.parse(JSON.stringify(props.counts)) : null
        }
    }
    reRenderHandler() {
        this.forceUpdate();
    }
    componentDidMount() {
        this.loadData();
        const obj = JSON.parse(sessionStorage.getItem("isCreatedClass"));
        if (obj && obj.isCreatedClass) {
            sessionStorage.removeItem("isCreatedClass");
            this.setState({ isCreateClassWizard: true })
        }
    }

    loadData = () => {
        const { school, getLicense, getStudentsBy, match: { params: { schoolId, classId, regionId } }, setFutureAnnualOuter } = this.props;
        setFutureAnnualOuter(this.props.match.params)
        school.id && getLicense({ schoolId, classId });
        school.id && getStudentsBy({ schoolId, classId });
        regionId && schoolId && classId && this.getInvoiceInfo();
        regionId && classId && this.getLicenceAdjustmentInfo();
        this.getRegionInfo();
    }

    componentWillReceiveProps(nextProps) {
        const { school, getLicense, getStudentsBy, match: { params: { regionId, schoolId, classId } } } = this.props;

        if (nextProps.school.id !== school.id) {
            getLicense({ schoolId, classId });
            getStudentsBy({ schoolId, classId });
        }
        if (school.id && !this.state.origSchool.id) {
            this.setState({ origSchool: school })
        }
        if (nextProps.match.params.regionId !== regionId) {
            this.getRegionInfo();
        }
        if (nextProps.match.params.classId !== classId) {
            this.getInvoiceInfo();
            this.getLicenceAdjustmentInfo();
        }
        if (nextProps.counts && nextProps.counts.sub && !this.state.origCounts) {
            this.setState({ origCounts: JSON.parse(JSON.stringify(nextProps.counts)) })
        }
        if (!this.props.licenseEditError && nextProps.licenseEditError) {
            reload({ licenseEditError: false })
        }
    }

    componentDidUpdate() {
        const { reload, licenseUpdated, futureAnnualOuter, classRoles, school: { allowSchoolEditLicense } } = this.props;
        const canEditLicense = ClassHelper.canEditLicense(classRoles, allowSchoolEditLicense) && !futureAnnualOuter;
        const isActionValid = GLGlobal.isActionValid(GSSchoolAction.SaveLicense);

        if (canEditLicense && isActionValid) {
            if (licenseUpdated) {
                reload({ licenseUpdated: false })
                this.loadData();
            }
        }
    }

    getRegionInfo = () => {
        const { match: { params: { regionId, classId } } } = this.props;
        const tasks = [
            this.regionService.getRegionBillingInfo({ id: regionId }),
            this.regionService.getSchoolCycleInvoiceGenerated({ regionId, schoolClassId: classId })
        ];
        Promise.all(tasks).then(res => {
            const [regionInfo, schoolClassInvoiceInfo] = res;
            regionInfo.schoolClassInvoiceInfo = schoolClassInvoiceInfo;
            this.setState({ regionInfo });
        });
    }

    getLicenceAdjustmentInfo = () => {
        const { match: { params: { regionId, classId } } } = this.props;
        this.licenseService.getSchoolClassManualAdjustments({ regionId, schoolClassId: classId })
            .then(response => {
                this.setState({
                    cycleAdjustmentData: this.formatDateForLicenceAdjustment(response),
                    origCycleAdjustmentData: response
                });
            });
    }

    afterRegenerate = () => {
        this.getInvoiceInfo();
        this.getLicenceAdjustmentInfo();
    }

    getInvoiceInfo = () => {
        const { match: { params: { regionId, schoolId, classId } } } = this.props;
        this.licenseService.getClassInvoiceInfo({ regionId, schoolId, classId })
            .then(classInvoiceInfo => {
                let keys = ["currentCycle", "lastCycle", "nextCycle"];
                for (let k in keys) {
                    classInvoiceInfo[keys[k]].billingCycleStart = classInvoiceInfo[keys[k]].billingCycleStart
                        ? classInvoiceInfo[keys[k]].billingCycleStart.substring(0, 10)
                        : null;
                    classInvoiceInfo[keys[k]].billingCycleEnd = classInvoiceInfo[keys[k]].billingCycleEnd
                        ? classInvoiceInfo[keys[k]].billingCycleEnd.substring(0, 10)
                        : null;
                }
                if (classInvoiceInfo) {
                    classInvoiceInfo.currentLicensePeriodStart = classInvoiceInfo.currentLicensePeriodStart.substring(0, 10);
                    classInvoiceInfo.currentLicensePeriodEnd = classInvoiceInfo.currentLicensePeriodEnd.substring(0, 10);
                }
                if (classInvoiceInfo.currentCycleInvoiceDate) {
                    classInvoiceInfo.currentCycleInvoiceDate = classInvoiceInfo.currentCycleInvoiceDate.substring(0, 10);
                }
                this.setState({ classInvoiceInfo });
            });
    }

    formatDateForLicenceAdjustment = (data: ManualAdjustmentInfoModel) => {
        if (!data) {
            return data;
        }
        let dataLocal = JSON.parse(JSON.stringify(data));
        dataLocal.adjustments = dataLocal.adjustments.map(a => {
            a.billingDate = DateHelper.toLocalDate(moment(a.billingDate.substring(0, 10), AdjustmentDateFormat));
            return a;
        })
        return dataLocal;
    }

    onStudentDragEnd = result => {
        const { reload, students, origStudents, school: { subscriptionTypeUsage: usage, subscriptionType: subtype } } = this.props;
        const { destination, source, draggableId } = result;

        if (!destination || destination.droppableId === source.droppableId || usage !== subscriptionTypeUsage.Standard) {
            return;
        }

        const requestedSubscription = destination.droppableId == SubscriptionType[subtype].toLowerCase() + "-license-info" ? subtype : Number(!subtype);
        const origStudent = origStudents.find(item => item.id === draggableId);
        const isUpgrade = origStudent.subscriptionType !== StudentSubscriptionType.Dual && requestedSubscription !== subtype;
        const isDowngrade = origStudent.subscriptionType === StudentSubscriptionType.Dual && requestedSubscription === subtype;
        const studentsUpgraded = [];
        const studentsDowngraded = [];

        const continueWithDrag = () => {
            let addOrSub = 0;
            // Only add students to state if changing subscriptionType from original
            if (isUpgrade) { // asking for upgrade
                studentsUpgraded.push(draggableId);
                addOrSub = 1;
            }
            else if (isDowngrade) { // asking for degrade
                studentsDowngraded.push(draggableId);
                addOrSub = -1;
            }
            const { counts, period, billedFrom, lastBilledPeriod, curriculumType, subTypeLable, subType } = this.props;
            const updatedRecord = {
                sub: counts.sub,
                license: { ...counts.license, preNewCnt: counts.license.preNewCnt + addOrSub, newCnt: counts.license.newCnt + addOrSub }
            };
            const { match: { params: { schoolId } } } = this.props;
            const newDigitalCount = subType === SubscriptionType.Digital ? updatedRecord.sub.preNewCnt : updatedRecord.license.preNewCnt;
            const newTextbookCount = subType === SubscriptionType.Textbook ? updatedRecord.sub.preNewCnt : updatedRecord.license.preNewCnt;
            const studentSubscriptionChanges = [{
                action: 1,
                isLicenseDowngrade: isDowngrade,
                isSubChangeRequestDelete: false,
                schoolId: schoolId,
                studentId: draggableId,
                subscriptionType: requestedSubscription
            }]
            this.onLicenseChange({ newDigitalCount, newTextbookCount, textbooknote: "", digitalnote: "", licensePlusStudentEdited: false, removedStudents: [], noteType: null }, studentSubscriptionChanges);
        }

        const updateDowngradeTitle = isUpgrade ? fmtMsg({ id: SchoolLocale.EditClassMoveToDual }, { 0: origStudent.name })
            : subtype === SubscriptionType.Digital
                ? fmtMsg({ id: SchoolLocale.EditClassMoveToStandard }, { 0: origStudent.name })
                : fmtMsg({ id: SchoolLocale.EditClassMoveToStandard }, { 0: origStudent.name });

        // Ask user if want to move the student
        Modal.confirm({
            title: updateDowngradeTitle,
            onOk: continueWithDrag,
            okText: fmtMsg({ id: SchoolLocale.EditClassLicenseModalConfirmButtonOk }),
            cancelText: fmtMsg({ id: GSAdminLocale.ModelButtonNo })
        })
    }

    toggleDrawerVisibility() {
        this.setState({
            regenerateDrawerVisible: !this.state.regenerateDrawerVisible
        });
    }

    onRemoveStudent = (studentId: string) => {
        const { match: { params } } = this.props;
        this.schoolClassService.removeStudent(params.schoolId, params.classId, studentId)
            .then(() => {
                this.loadData();
            });
    }

    onLicenseChange = (newCount: { newDigitalCount: number, newTextbookCount: number, textbooknote: string, digitalnote: string, licensePlusStudentEdited: boolean, removedStudents: string[], noteType: SubscriptionType }, studentSubscriptionChanges: any[] = []) => {
        const { newDigitalCount, newTextbookCount, textbooknote, digitalnote, licensePlusStudentEdited, removedStudents, noteType } = newCount;
        const { match: { params } } = this.props;
        const schoolClassStatus = {  // This object is for license count change, must be present always even if not changed.
            "newStudentCount": newDigitalCount >= newTextbookCount ? newDigitalCount : newTextbookCount,
            newTextbookLicenses: newTextbookCount,
            newDigitalLicenses: newDigitalCount,
            "textbookLicenseEditNote": textbooknote,
            "digitalLicenseEditNote": digitalnote,
        }
        const data = { schoolClassStatus, studentSubscriptionChanges, removedStudents, licensePlusStudentEdited, noteType };
        const req = { schoolId: params.schoolId, classId: params.classId, data };
        this.props.updateLicense(req);
    }

    saveAdjustments = () => {
        const { cycleAdjustmentData, origCycleAdjustmentData } = this.state;
        let formattedcycleAdjustmentData = {
            ...cycleAdjustmentData, adjustments: [...cycleAdjustmentData.adjustments].map((r, index) => {
                r.billingDate = origCycleAdjustmentData.adjustments[index].billingDate
                return r;
            }),
            schoolClassId: this.props.match.params.classId,
        }
        // hide modal
        this.setState({ adjustmentModalVisible: false, selectedCycleDate: "" });
        // save data and retrieve the new data
        this.licenseService.saveSchoolClassManualAdjustments(formattedcycleAdjustmentData)
            .then(result => {
                if (result.processResults.find(res => res === UpdateAdjustmentResultCode.AdjustmentUpdated)) {
                    message.success(fmtMsg({ id: SchoolLocale.EditClassAdjustmentSaveSuccess }));
                }
                if (result.processResults.find(res => res === UpdateAdjustmentResultCode.InvoiceNotFound)) {
                    /** Reasons:
                     * 1. Class created after invoice generation.
                     * 2. Billing day is pushed back before invoice generation to a date before today.
                     * 3. Billing start date is pushed after billing date.
                     * 4. Rare: Timezone is changed (only in case when billing start day and billing date are same)
                     * 5. More rare: Invoice did not ran for the school class for some wierd reason.
                     */
                    message.warning(fmtMsg({ id: SchoolLocale.SaveAdjustmentInvoiceUnavailable }));
                }

                this.getLicenceAdjustmentInfo();
                this.getInvoiceInfo();
            })
            .catch(e => message.error(fmtMsg({ id: SchoolLocale.EditClassAdjustmentSaveFail })));
    }

    adjustmentModal = () => {
        const { adjustmentModalVisible, selectedCycleDate, cycleAdjustmentData, regionInfo } = this.state;
        const { Option } = Select;
        if (!cycleAdjustmentData) {
            return;
        }
        const selectedCycleIndex = !selectedCycleDate
            ? 0
            : cycleAdjustmentData.adjustments.findIndex(e => e.billingDate === selectedCycleDate);

        const currentCycleAdjustment = cycleAdjustmentData.adjustments[selectedCycleIndex];

        const handleCycleChange = (selectedCycleDate: string) => {
            this.setState({ selectedCycleDate })
        }

        const handleAdjusmentChange = (value: number) => {
            const newCycleAdjusments = [...cycleAdjustmentData.adjustments].map((a, index) => {
                if (index === selectedCycleIndex) {
                    a.adjustment = value;
                }
                return a;
            })
            this.setState({ cycleAdjustmentData: { ...cycleAdjustmentData, adjustments: newCycleAdjusments } })
        }

        const noteChanged = ({ target: { value } }) => {
            const newCycleAdjusments = [...cycleAdjustmentData.adjustments].map((a, index) => {
                if (index === selectedCycleIndex) {
                    a.note = value;
                }
                return a;
            })
            this.setState({ cycleAdjustmentData: { ...cycleAdjustmentData, adjustments: newCycleAdjusments } })
        }

        const getSkipNote = (currentAdj, index) => {
            if (index === 0) {
                return currentAdj.shouldSkip && !cycleAdjustmentData.isInvoiceGenerated ? fmtMsg({ id: SchoolLocale.InvoiceUnaffectedNote }) : null;
            } else {
                return currentAdj.shouldSkip ? fmtMsg({ id: SchoolLocale.InvoiceUnaffectedNote }) : null;
            }
        }

        return <Modal
            visible={adjustmentModalVisible}
            onCancel={() => { this.adjustmentClicked(true) }}
            onOk={this.saveAdjustments}
            okText={fmtMsg({ id: GSAdminLocale.ButtonSave })}
            title={fmtMsg({ id: SchoolLocale.EditClassAdjustLicence })}
            okButtonProps={{ disabled: cycleAdjustmentData.adjustments.some(x => x.adjustment > 1000 || x.adjustment < -1000) || !selectedCycleDate, type: "primary", className: "license-modal__btn license-modal__btn--submit" }}
            cancelButtonProps={{ className: "license-modal__btn license-modal__btn--cancel" }}
            className="license-modal__adjustment"
            width="410px"
            destroyOnClose
        >
            <Row className="license-modal__adjustment__billingdate">
                <Col span={8}>
                    {fmtMsg({ id: SchoolLocale.EditClassBillingDate })}
                </Col>
                <Col>
                    <Select
                        className="asmod__select"
                        onChange={handleCycleChange}
                        placeholder={fmtMsg({ id: SchoolLocale.UnitPlanAdjustLicenseTitle })}
                    >
                        {
                            cycleAdjustmentData.adjustments.map((c, index) => <Option key={index} value={c.billingDate}>{c.billingDate}</Option>)
                        }
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col span={8}>{fmtMsg({ id: SchoolLocale.StudetnInviteCnt })}</Col>
                <Col>
                    <InputNumber
                        className="asmod__number"
                        value={!selectedCycleDate ? undefined : currentCycleAdjustment.adjustment}
                        disabled={!selectedCycleDate}
                        precision={0}
                        onChange={handleAdjusmentChange} />
                </Col>

            </Row>
            <Row>
                <Col prefixCls="license-modal__adjustment__notes" span={8}>{fmtMsg({ id: SchoolLocale.EditClassManualAdjustmentNote })}</Col>
            </Row>
            <Row>
                <Input.TextArea 
                    rows={4}
                    maxLength={200}
                    value={currentCycleAdjustment.note}
                    disabled={!selectedCycleDate}
                    onChange={noteChanged}
                ></Input.TextArea>
            </Row>
            {
                <>
                    <Row className="license-modal__adjustment__error">
                        {(currentCycleAdjustment.adjustment > 1000 || currentCycleAdjustment.adjustment < -1000)
                            ? fmtMsg({ id: SchoolLocale.LicenseAdjustmentValidCountMsg }) : null}
                    </Row>
                    <Row className="license-modal__adjustment__error license-modal__adjustment__warning">
                        {getSkipNote(currentCycleAdjustment, selectedCycleIndex)}
                    </Row>
                </>
            }
        </Modal>
    }

    adjustmentClicked = (cancelClicked = false) => {
        if (!this.state.cycleAdjustmentData) {
            return;
        }
        if (cancelClicked) {
            this.setState({ adjustmentModalVisible: !this.state.adjustmentModalVisible, cycleAdjustmentData: this.formatDateForLicenceAdjustment(this.state.origCycleAdjustmentData), selectedCycleDate: "" }) // reset data from props
        }
        this.setState({ adjustmentModalVisible: !this.state.adjustmentModalVisible });
    }

    goToStudents = () => {
        const { goToStudents } = this.props;
        goToStudents();
        this.setState({ isCreateClassWizard: false });
    }

    goToMaterialRequest = () => {
        const params = GLUtil.pathParse({ path: PathConfig.Students });
        this.setState({ isCreateClassWizard: false });
        this.props.history.push(GLUtil.pathStringify(PathConfig.ClassCart, params));
    }

    render() {
        const { form, curriculumType, licenseType, startDate, subTypeLable, subType, counts, billedFrom, lastBilledPeriod, total, school: { subscriptionTypeUsage: usage, allowMaterialRequest, allowSchoolEditLicense, regionId, id: schoolId, campusId, annualPrepComplete }, match: { params }, students, classRoles, futureAnnualOuter, endDate, moveStudentDisable, setMoveStudentsStatus } = this.props;
        const { regionInfo, classInvoiceInfo, isCreateClassWizard } = this.state;
        const regionInfoForDate = regionInfo && { regionInfo: { billingStartDay: regionInfo.billingStartDay, billingPeriod: regionInfo.billingPeriod } }
        const { currentLicensePeriodStart, currentLicensePeriodEnd } = classInvoiceInfo ? classInvoiceInfo : { currentCycle: null, currentLicensePeriodStart: null, currentLicensePeriodEnd: null };
        const currentLicensePeriod = currentLicensePeriodStart && currentLicensePeriodEnd && getPeriodFormattedDate(currentLicensePeriodStart, currentLicensePeriodEnd, regionInfoForDate);
        const nextTip = GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationNext });
        const cancelTip = GLGlobal.intl.formatMessage({ id: GLLocale.Cancel });
        const subTypeLableText = subTypeLable && GLGlobal.intl.formatMessage({ id: SubscriptionTypeText[subTypeLable] });
        const licenseTypeText = licenseType && fmtMsg({id: CONSTS.LicenseType.get(licenseType)});
        const pathMoveStudent = regionId ? GLUtil.pathStringify(PathConfig.MoveStudent, { regionId, ...params }) : null;
        const canEditLicense = ClassHelper.canEditLicense(classRoles, allowSchoolEditLicense) && !futureAnnualOuter;
        const now = new Date();
        const compareStartDate = new Date(startDate);
        const pathMovePromoteStudent = regionId && schoolId && campusId ? GLUtil.pathStringify(PathConfig.MovePromoteStudentsFromClass, { regionId, schoolId, campusId, ...params }) : null;
        const movePromoteStudentButton = GLGlobal.isActionValid(GSSchoolAction.MovePromoteStudents) && !futureAnnualOuter && pathMovePromoteStudent && compareStartDate <= now &&
            <div
                className={disableFutureLicenseEdit(regionInfo, endDate, classInvoiceInfo).isLicenseEditDisabled || annualPrepComplete ? 'move-student-link-wrapper' : ''}
            >
                <Link to={pathMovePromoteStudent}
                    className={disableFutureLicenseEdit(regionInfo, endDate, classInvoiceInfo).isLicenseEditDisabled || annualPrepComplete ?
                        'move-student-link move-student-link__disabled' :
                        'move-student-link'}>
                    <FormattedMessage id={SchoolLocale.MoveStudentText} />
                </Link>
            </div>

        const moveStudentButton = GLGlobal.isActionValid(GSSchoolAction.ClassMoveStudent) && pathMoveStudent && !futureAnnualOuter &&
            <div className={disableFutureLicenseEdit(regionInfo, endDate, classInvoiceInfo).isLicenseEditDisabled ? 'move-student-link-wrapper' : ''}>
                <Link to={pathMoveStudent} className={disableFutureLicenseEdit(regionInfo, endDate, classInvoiceInfo).isLicenseEditDisabled ? 'move-student-link move-student-link__disabled' : 'move-student-link'}>
                    <FormattedMessage id={SchoolLocale.MoveStudentText} />
                </Link>
            </div>
        if (disableFutureLicenseEdit(regionInfo, endDate, classInvoiceInfo).isLicenseEditDisabled !== moveStudentDisable) {
            setMoveStudentsStatus(disableFutureLicenseEdit(regionInfo, endDate, classInvoiceInfo).isLicenseEditDisabled)
        }


        return (
            <DragDropContext onDragEnd={this.onStudentDragEnd}>
                <div className='content-layout license-crud' >
                    <div>
                        <SecondaryTitle title={SchoolLocale.LicenseTitle} children={movePromoteStudentButton} />
                        <GLForm form={form}>
                            <LicenseInfo allowRegenerate={regionInfo && regionInfo.schoolClassInvoiceInfo && regionInfo.schoolClassInvoiceInfo.isCurrentInvoiceGenerated && now > compareStartDate} regenerateCallback={this.afterRegenerate} classInvoiceInfo={this.state.classInvoiceInfo} drawerVisible={this.state.regenerateDrawerVisible} toggleDrawerVisibility={this.toggleDrawerVisibility} regionId={params.regionId} schoolId={params.schoolId} campusId={params.campusId} schoolClassId={params.classId} label={licenseTypeText} currentLicensePeriod={currentLicensePeriod} usage={getUsageText(usage)} params={{ regionId, ...params }} adjustOnClick={this.adjustmentClicked} push={this.props.history.push} />
                            {showSubscription(curriculumType, subType, usage === subscriptionTypeUsage.Dual, usage === subscriptionTypeUsage.Single, counts, billedFrom && billedFrom(), lastBilledPeriod && lastBilledPeriod(), total, this.reRenderHandler, students, classRoles, canEditLicense, regionId, this.onLicenseChange, this.onRemoveStudent, regionInfo, classInvoiceInfo, disableFutureLicenseEdit(regionInfo, endDate, classInvoiceInfo))}
                        </GLForm>
                        <div className='submit-btns'>
                            <div>
                                {isCreateClassWizard && allowMaterialRequest &&
                                    <>
                                        <div><Button type="primary" onClick={this.goToMaterialRequest} title={nextTip}>{nextTip}</Button></div>
                                        <div><Button onClick={this.goToStudents} title={cancelTip}>{cancelTip}</Button></div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {this.adjustmentModal()}
            </DragDropContext>
        )
    }
}

const formatStudentForLittleSEED = (students: any[], curriculumType) => {
    if (students && curriculumType == CurriculumType.LittleSEED) {
        return students.map(student => (student.subscriptionType = StudentSubscriptionType.TextBook, student));
    }
    return students;
}
const LicenseInfo = ({ label, currentLicensePeriod, usage, params, adjustOnClick, push, regionId, schoolId, campusId, schoolClassId, toggleDrawerVisibility, drawerVisible, classInvoiceInfo, regenerateCallback, allowRegenerate }) => {
    const onLicenseHistoryclick = (e) => {
        e.preventDefault();
        const to = params.regionId ? GLUtil.pathStringify(PathConfig.ClassLicenseHistory, params) : '/';
        push(to);
    }
    const labelScaleton = !label || !label.length;
    return <>
        <Row type="flex" className="edit-class">
            <Col sm={16} xs={24}>
                <Row type="flex" className="edit-class__item-row edit-class__item-row--top">
                    <div><FormattedMessage id={SchoolLocale.LicenseType} /></div>
                    <div className="edit-class__label"> {labelScaleton ? <Skeleton active paragraph={{ rows: 1, width: 150 }} title={false}></Skeleton> : label} </div>
                </Row>
            </Col>
            <Col sm={8} xs={24}>
                <Row className="edit-class__item-row edit-class__item-row--top edit-class__link edit-class__link--lh">
                    <Link onClick={onLicenseHistoryclick} to="">{fmtMsg({ id: SchoolLocale.LicenseChangeHistory })}</Link>
                </Row>

                {
                    GLGlobal.isActionValid(GSAdminAction.ClassManualAdjustment) &&
                    <>
                        <Row className="edit-class__item-row edit-class__link">
                            <a onClick={adjustOnClick}>
                                {fmtMsg({ id: SchoolLocale.EditClassAdjustLicence })}
                            </a>
                        </Row>
                        {
                            allowRegenerate && <Row className="edit-class__item-row edit-class__link">
                                <a onClick={toggleDrawerVisibility}>{fmtMsg({ id: SchoolLocale.RegenerateInvoiceText })}</a>
                                <RegenerateInvoice
                                    regionId={regionId}
                                    schoolId={schoolId}
                                    campusId={campusId}
                                    schoolClassId={schoolClassId}
                                    onSaveCallback={regenerateCallback}
                                    maskClosable
                                    visible={drawerVisible}
                                    destroyOnClose
                                    closable
                                    onClose={toggleDrawerVisibility}
                                    width={600}
                                    disableColumns={[RegenerateDisableColumns.Campus, RegenerateDisableColumns.Class]}
                                />
                            </Row>
                        }
                    </>
                }
            </Col>
        </Row>
    </>
};


