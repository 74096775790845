import React, { Component } from "react";
import { GLGlobal, GLUtil, GLLocale } from "gl-commonui";
import { Button, Modal, Avatar, Row, Col, Drawer } from "antd-min";
import { GLIcon, Loading } from "@app/components";
import { lazyInject, TYPES, DateHelper, fmtMsg } from "@app/util";
import { IStudentService } from "@app/service/school/student";
import { SchoolLocale } from "@app/locales/localeid";

interface StudentInformationProps {
    studentId?: string;
    isDelete?: boolean;
    onOK?: () => void;
    infoIconClass?: string;
    showInfoInDrawer?: boolean;
}

interface StudentInformationStates {
    avatarUrl?: string;
    name?: string;
    englishName?: string;
    birthday?: string;
    gender?: string;
    registeredDate?: string;
    parentName?: string;
    showAvatar?: boolean;
    visible?: boolean;
    loading: boolean;
}

interface StudentInformationResponseModel {
    id: string;
    name: string;
    englishName: string;
    birthday?: string;
    gender?: boolean;
    registDate: string;
    parentName?: string;
}

export class StudentInformation extends Component<StudentInformationProps, StudentInformationStates> {
    @lazyInject(TYPES.IStudentService)
    service: IStudentService

    constructor(props, cxontext) {
        super(props);
        this.state = { visible: false, loading: false };
    }

    setInfo() {
        Promise.all([this.getStudentInfo(), this.getStudentAvatar()]).then((res) => {
            const [info, avatar]: [StudentInformationResponseModel, any] = res;
            const avatarurl = avatar && avatar.length && avatar[0].avatar ? avatar[0].avatar : "";
            this.setState({
                showAvatar: avatarurl ? true : false,
                avatarUrl: avatarurl,
                name: info.name,
                englishName: info.englishName,
                birthday: info.birthday ? DateHelper.formatDate2Local(info.birthday) : "",
                gender: info.gender == null ? "" : info.gender ? GLGlobal.intl.formatMessage({ id: SchoolLocale.StudentMale }) :
                    GLGlobal.intl.formatMessage({ id: SchoolLocale.StudetnFemale }),
                registeredDate: info.registDate ? DateHelper.formatDate2Local(info.registDate) : "",
                parentName: info.parentName,
                loading: false
            });
        });
    }

    getStudentInfo() {
        const { showInfoInDrawer } = this.props;
        return this.service.getStudentInfo({
            id: this.props.studentId,
            includeParentName: showInfoInDrawer
        });
    }

    getStudentAvatar() {
        return this.service.getStudentAvatar({
            studentIds: [this.props.studentId]
        });
    }

    showModal = () => {
        this.setState({ visible: true, loading: true });
    }

    hideModal = () => {
        this.setState({ visible: false });
    }

    renderModalContent = () => {
        const { showInfoInDrawer } = this.props;
        const name = GLGlobal.intl.formatMessage({ id: SchoolLocale.LicenseStudentName });
        const englishName = GLGlobal.intl.formatMessage({ id: SchoolLocale.LicenseStudentEnglishName });
        const parent = GLGlobal.intl.formatMessage({ id: SchoolLocale.Parent });
        const birthday = GLGlobal.intl.formatMessage({ id: SchoolLocale.LicenseStudentBirthday });
        const gender = GLGlobal.intl.formatMessage({ id: SchoolLocale.LicenseStudentGender });
        const registeredDate = GLGlobal.intl.formatMessage({ id: SchoolLocale.LicenseStudentRegisteredDate });
        const tips = GLGlobal.intl.formatMessage({ id: SchoolLocale.LicenseStudentTips });

        return <div className={showInfoInDrawer ? "" : "student-info-modal__modal-content"}>
            <div className="avatar">
                <Avatar className="avatar-lg" icon="user" style={{ display: this.state.showAvatar || this.state.showAvatar == undefined ? "none" : "block" }} />
                <img src={this.state.avatarUrl} style={{ display: this.state.showAvatar ? "block" : "none" }} />
            </div>
            <div className="info">
                <Row><Col className="left">{name}</Col><Col className="right">{this.state.name}</Col></Row>
                <Row><Col className="left">{englishName}</Col><Col className="right">{this.state.englishName}</Col></Row>
                {showInfoInDrawer && <Row><Col className="left">{parent}</Col><Col className="right">{this.state.parentName}</Col></Row>}
                <Row><Col className="left">{birthday}</Col><Col className="right">{this.state.birthday}</Col></Row>
                <Row><Col className="left">{gender}</Col><Col className="right">{this.state.gender}</Col></Row>
                <Row><Col className="left">{registeredDate}</Col><Col className="right">{this.state.registeredDate}</Col></Row>
                <Row><Col className="left tips">{tips}</Col></Row>
            </div>
        </div>
    }

    render() {
        const { isDelete, onOK, infoIconClass, showInfoInDrawer } = this.props;
        const drawerModalInfoTitle = GLGlobal.intl.formatMessage({ id: SchoolLocale.BtnInfo });
        const props = {
            visible: this.state.visible,
            title: isDelete ? GLGlobal.intl.formatMessage({ id: SchoolLocale.TextSureDel }) :
                drawerModalInfoTitle,
            wrapClassName: "student-info-modal",
            onCancel: this.hideModal,
            width: "495px",
            height: "345px",
            footer: isDelete ? [
                <Button key="add" type="primary" htmlType='submit' onClick={() => onOK()}>{fmtMsg(GLLocale.Ok)}</Button>,
                <Button key="cancel" onClick={() => this.hideModal()}>{fmtMsg(GLLocale.Cancel)}</Button>,
            ] : null
        };
        const src = GLUtil.getStaticImage(`image/info-circle.png`);
        return <>
            <span title={drawerModalInfoTitle}>
                {isDelete && <GLIcon type="close-circle-o" labelId={SchoolLocale.BtnDelete} onClick={() => {
                    this.showModal();
                    this.setInfo();
                }} />}
                {!isDelete && <img src={src} className={`info-circle ${infoIconClass && typeof infoIconClass === "string" ? infoIconClass : ""}`} onClick={() => {
                    this.showModal();
                    this.setInfo();
                }} />}
            </span>
            {showInfoInDrawer ?
                <Drawer
                    title={drawerModalInfoTitle}
                    visible={this.state.visible}
                    onClose={this.hideModal}
                    width={300}
                    placement="right"
                    className="student-info-drawer"
                    closable
                >
                    {this.state.loading ? <Loading visible={true}></Loading>  : this.renderModalContent()}
                </Drawer>
                : <Modal {...props}>
                    {this.renderModalContent()}
                </Modal>
            }
        </>
    }
}