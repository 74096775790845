import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { SecondaryTitle, SubmitBtns } from '../../../components';
import { withRouter, GLGlobal, connect, GLUtil } from 'gl-commonui';
import { StateType } from '@app/states';
import { SchoolLocale } from '../../../locales/localeid';
import { create, goToList } from '../../../states/school/class';
import { ClassForm } from './components';
import { get as getSchool } from '../../../states/school/school';
import { GSSchoolAction } from '../../../util/enum';
import {isSystemAdmin} from "@app/util";

interface SchoolClassNewPageProps {
    school: any
    campus : any
    userRoles: string[]
    create: (d) => void
    goToList: () => void
    getSchool: (d) => void
}
interface SchoolClassNewPageStates {
    isNextBtnDisabled?: boolean;
    isPromote?: boolean;
}

@withRouter
@connect(({ school: { current, userRoles } , campus: {model} }: StateType) => ({
    school: current,
    campus: model,
    userRoles
}), {
    create,
    goToList,
    getSchool
})
export class SchoolClassNewPage extends Component<RouteComponentProps<any> & SchoolClassNewPageProps, SchoolClassNewPageStates> {
    constructor(props) {
        super(props);
        this.state = { isNextBtnDisabled: true, isPromote: GLUtil.queryParse().isPromote };
    }
    componentDidMount() {
        const { schoolId, classId: id } = this.props.match.params;
        this.props.getSchool({ id: schoolId });
    }
    componentWillUpdate() {
        const isPromoteQuery = GLUtil.queryParse().isPromote;
        if (!this.state.isPromote && isPromoteQuery) {
            this.setState({ isPromote: true })
        }
        else if (this.state.isPromote && !isPromoteQuery) {
            this.setState({ isPromote: false })
        }
    }
    isEditLicense = false
    setNextBtnEnabled(isDisabled) {
        this.setState({ isNextBtnDisabled: isDisabled });
    }
    render() {
        const { regionId } = this.props.match.params;
        const { create, goToList, match: { params: { schoolId, campusId } }, school, userRoles, campus } = this.props;
        const {isPromote} = this.state;
        const isGrapeSEEDConnectSettingVisible = isSystemAdmin();
        return (
            <div className='content-layout class-crud class-crud-new' >
                <div>
                    <SecondaryTitle title={isPromote ? SchoolLocale.BreadTextPromoteClass : SchoolLocale.ClassNewEditTitle} />
                    <ClassForm isGrapeSEEDConnectVisible={isGrapeSEEDConnectSettingVisible} isEdit={false} isPromoteClass={isPromote} regionId={regionId} school={school} schoolId={schoolId} campus={campus} classRoles={userRoles} model={{ schoolId, campusId, schoolClassTime: [] }} handleSubmit={create} callback={(d) => this.setNextBtnEnabled(d)}>
                        <SubmitBtns isDisabled={this.state.isNextBtnDisabled} submitTitle={SchoolLocale.InvitationNext} onCancel={goToList} submitAction={GSSchoolAction.SaveClass} />
                    </ClassForm>
                </div>
            </div>
        )
    }
}
