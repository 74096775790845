import React, { Component } from 'react';
import { withRouter, connect } from 'gl-commonui';
import { StateType } from '@app/states';
import { RegionInfoExtended, Students } from '.';
import { SubscriptionType } from '@app/service/class/model';
import { StudentSubscriptionType } from '@app/util/enum';
import { LicenseDisabled } from '@app/util';

export interface SubscribedStudentsProps {
    componentType;
    isDual: boolean;
    isSingle: boolean;
    match?: { params };
    students?: any[];
    total?: string;
    subscriptionType?: number;
    licenseTypeN?: number;
    canEditLicense: any;
    classRoles: string[];
    regionId?: string;
    counts?: {
        sub: { cnt, newCnt, note },
        license: { cnt, newCnt, note }
    };
    onLicenseChange: (newInfo: {
        newDigitalCount: number;
        newTextbookCount: number;
        textbooknote: string;
        digitalnote: string;
        licensePlusStudentEdited: boolean;
        removedStudents: string[];
        noteType: SubscriptionType;
    }) => void;
    onRemoveStudent: (studentId: string) => void;
    subTypeN?: number;
    regionInfo: RegionInfoExtended;
    disableFutureLicenseEdit?: LicenseDisabled;
}

@withRouter
@connect(({ schoolClass: { students, total } }: StateType) => ({
    students,
    total,
}), {})
export class SubscribedStudents extends Component<SubscribedStudentsProps>{
    render() {
        return <Students {...this.props} />;
    }
}
