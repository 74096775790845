import React from 'react';
import { Form, Input, Tooltip, Icon } from 'antd-min';
import {SchoolLocale} from '@app/locales/localeid';
import { FormattedMessage } from 'react-intl';

export const ClassInternalNotes = ({ defaultNotes, form }) => {
    return (
        <Form.Item
            className="class-internal-notes"
            label={
                <span>
                    <FormattedMessage id={SchoolLocale.ClassNotes} />
                    <Tooltip
                        title={<FormattedMessage id={SchoolLocale.ClassInternalNotesPlaceholder} />}
                        overlayClassName="class-internal-notes-popover"
                        placement="topLeft"
                        key="class-internal-notes"
                    >
                        <Icon type="info-circle" className="contact-info-icon" />
                    </Tooltip >
                </span>
            }
        >
            {form.getFieldDecorator('notes', {
                initialValue: defaultNotes,
            })(
                <Input.TextArea maxLength={500} />)}
        </Form.Item>
    )
}
