import React, { Component } from "react";
import { GLLocale, GLUtil } from "gl-commonui";
import {
    Form,
    InputNumber,
    Input,
    Row,
    Col,
    Table,
    Modal,
    List,
    Checkbox,
    Card,
    Popover,
    Button,
    Skeleton
} from "antd-min";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { SchoolLocale, GSAdminLocale } from "@app/locales/localeid";
import { SubscriptionType, CurriculumType } from "@app/service/class";
import {
    fmtMsg,
    mergeClasses,
    StudentSubscriptionType,
    DateHelper,
    CONSTS,
    GSAdminAction,
    LicenseDisabled,
    isFutureClass
} from "@app/util";
import { LicenseModel } from "..";
import {
    RegionModel,
    SchoolClassBillingInfoModel
} from "@app/service/admin/regions";
import { ClassBillingCycleInfoModel } from "@app/states/school/class";
import { getPeriodFormattedDate } from "./helper";
import { StudentInformation } from ".";
import { CommonSectionDescModal } from "./modals/CommonSectionDescModal";
import { StandardDualSectionDescModal } from "./modals/standardDualSectionDescModal";
import { CommonSectionIncModal } from "./modals/CommonSectionIncModal";
import { WijmoGrid } from "@app/components/grid";
import { CellType } from "wijmo/wijmo.grid";
import "./subscription.less";
import { PathConfig } from "@app/config/pathconfig";

const { Grid, Column } = WijmoGrid;

interface SubscriptionProps extends LicenseModel {
    isDual?: boolean;
    isSingle?: boolean;
    cnts: { cnt; newCnt; error; noteerror };
    title: string;
    currentCnt: string;
    change: string;
    newCnt: string;
    isSub?: boolean;
    licenseTypeN?: number;
    subTypeN?: number;
    total?: number;
    relatedCnts?: { cnt; newCnt; error };
    componentType: "digital" | "textbook" | "dual";
    containerForceUpdate?: () => void;
    students: any;
    canEditLicense: any;
    subscriptionType: StudentSubscriptionType;
    regionInfo: RegionInfoExtended;
    classInvoiceInfo?: ClassBillingCycleInfoModel;
    onLicenseChange: (newInfo: {
        newDigitalCount: number;
        newTextbookCount: number;
        textbooknote: string;
        digitalnote: string;
        licensePlusStudentEdited: boolean;
        removedStudents: string[];
        noteType: SubscriptionType;
    }) => void;
    disableFutureLicenseEdit: LicenseDisabled;
}

export interface RegionInfoExtended extends RegionModel {
    // added 'isCurrentCycleInvoiceGenerated' property while fetching regionInfo in "license.tsx"
    schoolClassInvoiceInfo: SchoolClassBillingInfoModel;
}

interface SubscriptionState {
    visible: { topNegative: boolean; bottomNegative: boolean; add: boolean };
    shouldDecreaseLicence: boolean;
    newCurrentCount: any;
    disableLicenceChange: boolean;
    origState: SubscriptionState;
    note: string;
    noteError: boolean;
    originalCount: any;
    studentsL: any[];
}

enum SubscriptionModalType {
    negate,
    add,
    none
}

export class Subscription extends Component<
    SubscriptionProps,
    SubscriptionState
> {
    constructor(props) {
        super(props);
        this.state = {
            shouldDecreaseLicence: false,
            newCurrentCount: props.counts,
            disableLicenceChange: false,
            origState: this.state,
            note: "",
            noteError: false,
            originalCount: JSON.parse(JSON.stringify(props.cnts)),
            visible: { topNegative: false, bottomNegative: false, add: false },
            studentsL: []
        };
    }

    componentDidMount(): void {
        this.fetchStudentsForLStoGSProm();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.students !== this.props.students) {
          this.fetchStudentsForLStoGSProm();
        }
      }

    fetchStudentsForLStoGSProm() {
        const classId = GLUtil.pathParse(PathConfig.Students).classId;
        if (classId) {
            isFutureClass(classId).then(res => {
                const studentsL =
                    this.props.students ?
                        this.props.students.filter(x => x.subscriptionType === this.props.subscriptionType)
                        : [];

                if (this.props.subscriptionType == StudentSubscriptionType.Dual &&
                    this.props.students.some(x => x.subscriptionType === StudentSubscriptionType.TextBook) && res) {
                    const textBookStudents = this.props.students.filter(x => x.subscriptionType === StudentSubscriptionType.TextBook);
                    this.setState({ studentsL: [...studentsL, ...textBookStudents] });
                }
                else {
                    this.setState({ studentsL: studentsL });
                }
            });
        }
    }
    isDualLicenseSection =
        this.props.componentType === "dual" &&
        !(this.props.isDual || this.props.isSingle);

    billTableCols = (): any[] => {
        if (!this.props.classInvoiceInfo) {
            return [];
        }
        const {
            classInvoiceInfo: {
                lastCycle,
                currentCycle,
                nextCycle,
                currentCycleInvoiceDate
            },
            regionInfo,
            disableFutureLicenseEdit: {
                isCurrentCyclePeriodDisabled,
                isFutureCyclePeriodDisabled
            }
        } = this.props;
        if (!regionInfo) {
            return [];
        }
        const regionInfoForDate = {
            regionInfo: {
                billingStartDay: regionInfo.billingStartDay,
                billingPeriod: regionInfo.billingPeriod
            }
        };
        const lastPeriod = getPeriodFormattedDate(
            lastCycle.billingCycleStart,
            lastCycle.billingCycleEnd,
            regionInfoForDate
        );
        const currentPeriod = getPeriodFormattedDate(
            currentCycle.billingCycleStart,
            currentCycle.billingCycleEnd,
            regionInfoForDate
        );
        const nextPeriod = getPeriodFormattedDate(
            nextCycle.billingCycleStart,
            nextCycle.billingCycleEnd,
            regionInfoForDate
        );
        return [
            {
                title: "",
                dataIndex: "order",
                key: "order",
                allowSorting: false
            },
            {
                title: (
                    <>
                        <div className="subscription__bill-tr">
                            {lastPeriod
                                ? lastPeriod
                                : fmtMsg({
                                      id:
                                          SchoolLocale.EditClassLastBillingPeriod
                                  })}
                        </div>
                        <div className="subscription__bill-br">
                            {fmtMsg({ id: SchoolLocale.LicenseClosed })}
                        </div>
                    </>
                ),
                dataIndex: "lastBillingPeriod",
                key: "lastBillingPeriod",
                align: "center",
                className: "subscription__last-bill-column subscription__bill-column"
            },
            {
                title: (
                    <>
                        <div className={isCurrentCyclePeriodDisabled ? 'subscription__bill-currentperioddate' : ''}>{currentPeriod}</div>
                        {
                            !isCurrentCyclePeriodDisabled ? <div>{fmtMsg({ id: SchoolLocale.LicenseOpen })}</div> : <div>-</div>
                        }
                        {
                            !isCurrentCyclePeriodDisabled &&
                                <div>
                                    (
                                        {fmtMsg({
                                            id: SchoolLocale.LicenseCurrentInvoiceDate
                                        })}{" "}
                                        {!currentCycleInvoiceDate ? (
                                            <Skeleton
                                                active
                                                paragraph={{ rows: 1, width: 150 }}
                                                title={false}
                                            ></Skeleton>
                                        ) : (
                                            DateHelper.toLocalDate(currentCycleInvoiceDate)
                                        )}
                                    )
                                </div>
                        }
                    </>
                ),
                dataIndex: "currentBillingPeriod",
                key: "currentBillingPeriod",
                align: "center",
                className: isCurrentCyclePeriodDisabled ? "subscription__bill-column subscription__bill-column-grey" : "subscription__bill-column"
            },
            {
                title: (
                    <>
                        <div className="subscription__bill-tr">
                            {nextPeriod}
                        </div>
                        {
                            !isFutureCyclePeriodDisabled ?
                                <div className="subscription__bill-br">
                                    {fmtMsg({ id: SchoolLocale.LicensePending })}
                                </div> :
                                <div>-</div>
                        }
                    </>
                ),
                dataIndex: "nextBillingPeriod",
                key: "nextBillingPeriod",
                align: "center",
                className: isFutureCyclePeriodDisabled ? "subscription__bill-column subscription__bill-column-grey" : "subscription__bill-column"
            }
        ];
    };

    billingChangesDueToLicenseChange = (): {
        currentStudentCountChange: number;
        currentAdjustmentChange: number;
        nextStudentCountChange: number;
        nextAdjustmentChange: number;
    } => {
        return {
            currentStudentCountChange: 0,
            currentAdjustmentChange: 0,
            nextStudentCountChange: 0,
            nextAdjustmentChange: 0
        };
    };

    gridData = () => {
        if (!this.props.classInvoiceInfo) return [];
        const {
            classInvoiceInfo: { lastCycle, currentCycle, nextCycle }
        } = this.props;
        const {
            currentStudentCountChange,
            currentAdjustmentChange,
            nextStudentCountChange,
            nextAdjustmentChange
        } = this.billingChangesDueToLicenseChange();

        const { disableFutureLicenseEdit } = this.props;

        const adjustments = {
            lastBillingPeriod:
                lastCycle.adjustmentAutomatic + lastCycle.adjustmentManual,
            currentBillingPeriod: disableFutureLicenseEdit.isCurrentCyclePeriodDisabled
                ? "-"
                : currentCycle.adjustmentAutomatic +
                  currentCycle.adjustmentManual +
                  currentAdjustmentChange,
            nextBillingPeriod: disableFutureLicenseEdit.isFutureCyclePeriodDisabled
                ? "-"
                : nextCycle.adjustmentAutomatic +
                  nextCycle.adjustmentManual +
                  nextAdjustmentChange
        };

        const showAdjustments = Object.keys(adjustments).some(
            k => typeof adjustments[k] === "number" && adjustments[k] !== 0
        );

        const data = [
            {
                order: fmtMsg({ id: SchoolLocale.EditClassActualLicenseLabel }),
                lastBillingPeriod: lastCycle.students,
                currentBillingPeriod: disableFutureLicenseEdit.isCurrentCyclePeriodDisabled
                    ? "-"
                    : currentCycle.students + currentStudentCountChange,
                nextBillingPeriod: disableFutureLicenseEdit.isFutureCyclePeriodDisabled
                    ? "-"
                    : nextCycle.students + nextStudentCountChange
            }
        ];

        if (showAdjustments) {
            data.push(
                {
                    order: fmtMsg({ id: SchoolLocale.EditClassAdjustment }),
                    ...adjustments
                }
            );
        }

        // billing row would contain '-' for cells which would not be generated.

        data.push({
            order: fmtMsg({ id: SchoolLocale.EditClassBilling }),
            lastBillingPeriod: lastCycle.billingDoneFor,
            currentBillingPeriod: disableFutureLicenseEdit.isCurrentCyclePeriodDisabled
                ? "-"
                : currentCycle.billingDoneFor +
                    currentStudentCountChange +
                    currentAdjustmentChange,
            nextBillingPeriod: disableFutureLicenseEdit.isFutureCyclePeriodDisabled
                ? "-"
                : nextCycle.billingDoneFor +
                    nextStudentCountChange +
                    nextAdjustmentChange
        });

        return data;
    };

    getModalHeading = (typeOfModal: SubscriptionModalType = null): string => {
        const { subTypeN } = this.props;

        if (this.isDualLicenseSection) {
            if (typeOfModal === SubscriptionModalType.negate) {
                return fmtMsg({ id: SchoolLocale.EditClassDescDualLicence });
            } else {
                return "";
            }
        }

        if (subTypeN === SubscriptionType.Textbook) {
            if (typeOfModal === SubscriptionModalType.negate) {
                return fmtMsg({ id: SchoolLocale.EditClassDescTxtLicence });
            } else {
                return fmtMsg({ id: SchoolLocale.EditClassIncTxtLicence });
            }
        }

        if (subTypeN === SubscriptionType.Digital) {
            if (typeOfModal === SubscriptionModalType.negate) {
                return fmtMsg({ id: SchoolLocale.EditClassDescDigLicence });
            } else {
                return fmtMsg({ id: SchoolLocale.EditClassIncDigLicence });
            }
        }
    };

    toggleModal = (isAdd: boolean = false) => {
        if (isAdd) {
            this.setState({ visible: { ...this.state.visible, add: true } });
        } else {
            if (this.isDualLicenseSection) {
                this.setState({
                    visible: { ...this.state.visible, bottomNegative: true }
                });
            } else {
                this.setState({
                    visible: { ...this.state.visible, topNegative: true }
                });
            }
        }
    };

    formatItem = (s, e) => {
        if (e.panel.cellType === CellType.ColumnHeader && e.col === 1) {
                e.cell.classList.add('subscription__bill-lp')
        }
        if (e.panel.cellType === CellType.ColumnHeader && (e.col === 2 && this.props.disableFutureLicenseEdit.isCurrentCyclePeriodDisabled)) {
            e.cell.classList.add('subscription__bill-lp')
        }
        if (e.panel.cellType === CellType.ColumnHeader && (e.col === 3 && this.props.disableFutureLicenseEdit.isFutureCyclePeriodDisabled)) {
            e.cell.classList.add('subscription__bill-lp')
        }
    }

    render() {
        const {
            cnts,
            title,
            children,
            canEditLicense,
            students,
            subscriptionType,
            counts
        } = this.props;
        const dualSubStudents =
            students ?
            students.filter(
                s => s.subscriptionType == StudentSubscriptionType.Dual
            ) : [];
        const disableDecreaseButton = this.isDualLicenseSection
            ? dualSubStudents &&
              dualSubStudents.length === 0 &&
              cnts.newCnt === 0
            : students && students.length === 0 && cnts.newCnt === 0;
        const accounts = (this.state.studentsL && this.state.studentsL.length > 0
            ? this.state.studentsL.length
            : 0
        ).toString();
        return (
            <>
                <div
                    className={mergeClasses(
                        "subscription",
                        this.isDualLicenseSection && "subscription--dualflex"
                    )}
                >
                    <div>
                        <span>{title}</span>
                    </div>
                    <div>
                        <>
                            <Row
                                gutter={15}
                                type="flex"
                                justify="space-between"
                                align="middle"
                            >
                                <Col>
                                    <Row className="subscription__count">
                                        <Col className="subscription__count-label">
                                            {fmtMsg({
                                                id:
                                                    SchoolLocale.LicenseStudentCnt
                                            })}
                                        </Col>
                                        <Col className="subscription__change-btn-wrap">
                                            <Button
                                                className="subscription__change-btn"
                                                onClick={() =>
                                                    canEditLicense &&
                                                    !disableDecreaseButton &&
                                                    this.toggleModal()
                                                }
                                                disabled={
                                                    !canEditLicense ||
                                                    disableDecreaseButton ||
                                                    this.props.disableFutureLicenseEdit.isLicenseEditDisabled
                                                }
                                            >
                                                <i
                                                    className={mergeClasses(
                                                        "material-icons subscription__count-icon",
                                                        (!canEditLicense ||
                                                            disableDecreaseButton) &&
                                                            "subscription__count-icon--disabled"
                                                    )}
                                                >
                                                    remove
                                                </i>
                                            </Button>
                                            <span className="subscription__count-number">
                                                {cnts.newCnt}
                                            </span>
                                            <Button
                                                className="subscription__change-btn"
                                                onClick={() =>
                                                    !this
                                                        .isDualLicenseSection &&
                                                    canEditLicense &&
                                                    this.toggleModal(true)
                                                }
                                                disabled={
                                                    this.isDualLicenseSection ||
                                                    !canEditLicense ||
                                                    this.props.disableFutureLicenseEdit.isLicenseEditDisabled
                                                }
                                            >
                                                <i
                                                    className={mergeClasses(
                                                        "material-icons subscription__count-icon",
                                                        (this
                                                            .isDualLicenseSection ||
                                                            !canEditLicense) &&
                                                            "subscription__count-icon--disabled"
                                                    )}
                                                >
                                                    add{" "}
                                                </i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <div className="subscription__accounts">
                                        <span className="subscription__accounts-label">
                                            <FormattedMessage
                                                id={
                                                    SchoolLocale.LicenseDigitalAccount
                                                }
                                            ></FormattedMessage>
                                        </span>
                                        <span>{accounts}</span>
                                    </div>
                                </Col>
                            </Row>
                            {!this.isDualLicenseSection && (
                                <>
                                    <Grid
                                        itemsSource={this.gridData()}
                                        pagination={true}
                                        formatItem={this.formatItem}
                                        className="subscription__grid"
                                    >
                                        {
                                            this.billTableCols().map(column => {
                                            return <Column
                                                renderHeader={() => column.title}
                                                binding={column.dataIndex}
                                                key={column.key}
                                                align={column.align}
                                                cssClass={column.className}
                                                allowSorting={column.allowSorting}
                                            />
                                        })}
                                    </Grid>
                                </>
                            )}
                        </>
                        {children}
                    </div>
                </div>
                {this.state.visible.topNegative && (
                    <CommonSectionDescModal
                        onCancel={() =>
                            this.setState({
                                visible: {
                                    ...this.state.visible,
                                    topNegative: false
                                }
                            })
                        }
                        students={this.state.studentsL}
                        onSave={d => {
                            this.props.onLicenseChange(d);
                            this.setState({
                                visible: {
                                    ...this.state.visible,
                                    topNegative: false
                                }
                            });
                        }}
                        isDual={this.props.isDual}
                        licenseCount={counts}
                        visible={this.state.visible.topNegative}
                        title={this.getModalHeading(
                            SubscriptionModalType.negate
                        )}
                        regionInfo={this.props.regionInfo}
                        canEditLicense={this.props.canEditLicense}
                        subType={this.props.subTypeN}
                        isLittleSeed={
                            this.props.licenseTypeN === CurriculumType.LittleSEED
                        }
                        disableFutureLicenseEdit={this.props.disableFutureLicenseEdit}
                    />
                )}
                {this.state.visible.bottomNegative && (
                    <StandardDualSectionDescModal
                        onCancel={() =>
                            this.setState({
                                visible: {
                                    ...this.state.visible,
                                    bottomNegative: false
                                }
                            })
                        }
                        students={dualSubStudents}
                        onSave={d => {
                            this.props.onLicenseChange(d);
                            this.setState({
                                visible: {
                                    ...this.state.visible,
                                    bottomNegative: false
                                }
                            });
                        }}
                        licenseCount={counts}
                        visible={this.state.visible.bottomNegative}
                        title={this.getModalHeading(
                            SubscriptionModalType.negate
                        )}
                        regionInfo={this.props.regionInfo}
                        canEditLicense={this.props.canEditLicense}
                        subType={this.props.subTypeN}
                        disableFutureLicenseEdit={this.props.disableFutureLicenseEdit}
                    />
                )}
                {this.state.visible.add && (
                    <CommonSectionIncModal
                        onCancel={() =>
                            this.setState({
                                visible: { ...this.state.visible, add: false }
                            })
                        }
                        onSave={d => {
                            this.props.onLicenseChange(d);
                            this.setState({
                                visible: { ...this.state.visible, add: false }
                            });
                        }}
                        isDual={this.props.isDual}
                        licenseCount={counts}
                        visible={this.state.visible.add}
                        title={this.getModalHeading(SubscriptionModalType.add)}
                        regionInfo={this.props.regionInfo}
                        canEditLicense={this.props.canEditLicense}
                        subType={this.props.subTypeN}
                        isLittleSeed={
                            this.props.licenseTypeN === CurriculumType.LittleSEED
                        }
                        disableFutureLicenseEdit={this.props.disableFutureLicenseEdit}
                    />
                )}
            </>
        );
    }
}
