import { Container, GLIcon, ListStatesRadios, ListStatesStore, MainTitle } from '@app/components';
import { GLGrid, GLGridSorter } from '@app/components/gl-grid';
import { PathConfig } from '@app/config/pathconfig';
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { SchoolClassModel } from '@app/service/class/index';
import { SchoolModel } from '@app/service/schools';
import { StateType } from '@app/states';
import { getCampusContacts } from "@app/states/school/admins";
import { CampusContacts } from '@app/states/school/adminsModel';
import campus, { getAdmin, getCampuses as getCampusesBy } from '@app/states/school/campus';
import { getItemsBy } from '@app/states/school/class';
import { get as getSchool } from '@app/states/school/school';
import { CampusAdminContacts, fmtMsg, getQuery, GSAdminAction, GSSchoolAction, isGuid, lazyInject, mergeClasses, TYPES } from '@app/util';
import { Col, Divider, Icon, Popover, Row, Table } from 'antd-min';
import { TableProps } from 'antd/lib/table';
import {
    connect,
    GLAction,
    GLGlobal,
    GLUtil,
    InvitationType,
    maskMain,
    PaginationParams,
    PathConfig as CommonPath,
    withRouter,
} from 'gl-commonui';
import cloneDeep from 'lodash/cloneDeep';
import * as React from 'react';
import { Component, ReactNode } from 'react';
import { FormattedMessage } from "react-intl";
import { Link, RouteComponentProps } from "react-router-dom";
import { AllowDragging, AutoSizeMode, FlexGrid } from 'wijmo/wijmo.grid';
import { CampusFeatureMenu } from './components/campus-feature-menu';
import Search from "antd/es/input/Search";
import { Tagging } from '@app/components/tagging/tagging';
import { EntityTagsModel, TagEntity, TagListModel } from '@app/service/tag-manager';
import "./list.less";
import { WijmoGrid } from '@app/components/grid/';
const { Column } = WijmoGrid;
interface SchoolClassesPageProps {
    list: any[];
    total: number;
    activeCnt: number;
    futureCount: number;
    loading: boolean;
    school: SchoolModel;
    campuses: any[];
    model: any;
    campusContacts?: CampusContacts;
    getSchool: (d) => void;
    getItemsBy: (d) => void;
    getCampusesBy: (d) => void;
    getAdmin: (d) => void;
    maskMain: (d) => void;
    getCampusContacts: (d: any) => void;
}
class SchoolClassesTable extends Table<SchoolClassModel> { }

@withRouter
@connect(({ school: { current: school },
    schoolClass: { list, loading, total, activeCnt, futureCount },
    campus: { list: campuses, model },
    admins: { campusContacts } }: StateType) => ({
        school,
        list,
        loading,
        total,
        activeCnt,
        futureCount,
        campuses,
        model,
        campusContacts
    }), {
    getSchool,
    getItemsBy,
    getCampusesBy,
    getAdmin,
    maskMain,
    getCampusContacts
})
export class SchoolClassesPage extends Component<RouteComponentProps<any> & SchoolClassesPageProps, { listState, showGroupPanel }> {
    tableLayout: TableProps<SchoolClassModel> = {
        rowKey: 'id',
        bordered: false,
        loading: true,
        pagination: new PaginationParams(1, 30),
        onChange: (pagination: any) => {
            this.onPagingChange(pagination);
        },
        rowClassName: (record) => record.disabled ? "list-disabled" : "",
        //onRow: () => setQuery('classes', this.tableLayout.pagination, { campusId: this.selectedDefault })
    };
    selectedDefault = ''
    listStateStore: ListStatesStore;
    className = '';
    hasTagViewPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsView);
    hasTagManagerPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsManage);
    private sorter: { sortBy: string, isDescending: boolean }
    //private columnSortingMap: Map<string, string> = new Map<string, string>();
    constructor(props, context) {
        super(props, context)
        this.listStateStore = new ListStatesStore("classes-list-states", this.props.match.params.campusId);
        this.state = {
            listState: this.listStateStore.getState(),
            showGroupPanel: false,
        };
        this.sorter = {
            sortBy: 'name',
            isDescending: false
        };
        this.reloadGrid = this.reloadGrid.bind(this)
    }
    
    componentDidMount() {
        const { getSchool, getCampusesBy, getCampusContacts, maskMain, match: { params: { schoolId, campusId } } } = this.props;
        maskMain(true);
        getSchool({ schoolId });
        getCampusesBy({ schoolId });
        getCampusContacts({ schoolId, id: campusId });
    }
    componentWillReceiveProps(nextProps) {
        const { campuses, getItemsBy, getCampusContacts, getAdmin, maskMain, match: { params: { schoolId, campusId } }, history, model } = this.props;

        if (nextProps.campuses !== campuses && nextProps.campuses.length) {
            if (nextProps.campuses.findIndex(campuse => campuse.id === campusId) == -1) {
                maskMain(false);
                history.push(CommonPath.NotFound);
                return;
            }
            const query = getQuery('classes');
            this.selectedDefault = campusId || query.campusId || nextProps.campuses[0].id;
            (this.tableLayout.pagination as PaginationParams).current = query.current;
            getItemsBy({
                schoolId,
                campusId: this.selectedDefault,
                pagination: this.listStateStore.getUrlQuery(cloneDeep(this.tableLayout.pagination)),
                sorter: this.sorter,
                className: this.className
            });
            getAdmin({ resourceType: InvitationType.campusAdmin, resourceId: this.selectedDefault });
            if (this.selectedDefault !== campusId) {
                getCampusContacts({ schoolId, id: this.selectedDefault });
            }
            this.listStateStore.setStateKey(this.selectedDefault);
            maskMain(false);
        }
    }
    onColumnSorting(sorter: GLGridSorter) {
        this.sorter = sorter ? { sortBy: sorter.columnSortName, isDescending: !sorter.ascending } : null;
        const page = this.tableLayout.pagination as PaginationParams;
        this.props.getItemsBy({
            schoolId: this.props.match.params.schoolId,
            campusId: this.selectedDefault,
            pagination: this.listStateStore.getUrlQuery(cloneDeep(page)),
            sorter: this.sorter,
            className: this.className
        })
    }

    onPagingChange(pagination, fetchAllData = false) {
        const page = this.tableLayout.pagination as PaginationParams;
        if (fetchAllData || this.state.showGroupPanel) {
            page.current = 1;
            page.pageSize = null;
        } else {
            page.current = pagination.current ? pagination.current : pagination;
        }
        this.props.getItemsBy({
            schoolId: this.props.match.params.schoolId,
            campusId: this.selectedDefault,
            pagination: this.listStateStore.getUrlQuery(cloneDeep(page)),
            sorter: this.sorter,
            className: this.className
        })
    }

    reloadGrid() {
        const { getItemsBy, match: { params: { schoolId, campusId } } } = this.props;
        const query = getQuery('classes');
            this.selectedDefault = campusId || query.campusId /*|| nextProps.campuses[0].id*/;
            (this.tableLayout.pagination as PaginationParams).current = query.current;
            getItemsBy({
                schoolId,
                campusId: this.selectedDefault,
                pagination: this.listStateStore.getUrlQuery(cloneDeep(this.tableLayout.pagination)),
                sorter: this.sorter,
                className: this.className
            });
    }

    renderTags = (text: TagListModel[], record: any) : ReactNode => {
        return (
            <Tagging
                selectedTags={record.tags}
                entityId={record.id}
                regionId={this.props.school.regionId}
                entity={TagEntity.SchoolClass}
                isViewOnly={!this.hasTagManagerPermission}
                onSave={this.reloadGrid}
                isGridTagging={true}
            />
        )
    }

    getColumns() {
        const { school: { regionId, id: schoolId } } = this.props;
        const campusId = this.selectedDefault;
        let columns = [
            {
                key: 1,
                title: fmtMsg({id: SchoolLocale.ClassName}),
                dataIndex: "name",
                width: "18%",
                className: 'navigation-col',
                render: (text, record, index) => {
                    const pathStudents = regionId && schoolId && campusId ? GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId: record.id }) : null;
                    if (pathStudents) {
                        return <a href={pathStudents} > <span>{text}</span> </a>;
                    }
                    else {
                        return <span>{text}</span>;
                    }
                }
            }, {
                key: 2,
                title: fmtMsg({id: SchoolLocale.BulkTerm}),
                dataIndex: "term",
                width: "10%"
            }, {
                key: 3,
                title: fmtMsg({id: SchoolLocale.ClassesColumnAgeGrade}),
                dataIndex: "age",
                width: "13%"
            }, {
                key: 4,
                title: fmtMsg({id: SchoolLocale.ClassUnit}),
                dataIndex: "currentUnit",
                width: "13%"
            },
            {
                key: 5,
                title: fmtMsg({id: SchoolLocale.ClassStartUnit}),
                dataIndex: "startUnit",
                width: "15%"
            },
            {
                key: 6,
                title: fmtMsg({id: SchoolLocale.ClassMaxUnit}),
                dataIndex: "maxUnit",
                width: "14%"
            }
            , {
                key: 7,
                title: fmtMsg({id: SchoolLocale.ClassCnt}),
                dataIndex: "studentCount",
                width: "14%",
            }
            , {
                key: 8,
                title: fmtMsg({id: SchoolLocale.ClassCurriculumType}),
                dataIndex: "curriculumType",
                width: "13%"
            }, {
                key: 9,
                title: fmtMsg({id: SchoolLocale.ClassTeacher}),
                dataIndex: "teacher",
                sorter: false,
                width: "15%",
                render: (text, { teacher, subTeacher }, index) => teacher || subTeacher || ''
            }
        ];

        if(this.hasTagViewPermission) {
            columns.push(
                {
                    key: 10,
                    title: fmtMsg({id: GSAdminLocale.SchoolModelTags}),
                    dataIndex: "tags",
                    width: "35%",
                    sorter: false,
                    render: (text, record, index) => {
                        return this.renderTags(text, record);
                    } 
                }
            )
        }

        return columns;
    }

    getComponentColumn() {
        const columns = this.getColumns().map(x => {
            const width = typeof x.width == "string" ? x.width.replace("%", "*") : x.width; 
            return (
            <Column
                key={x.key}
                binding={x.dataIndex}
                header={x.title}
                render={x.render}
                width={width}
                cssClass='class-tags'
            />
            )
        });

        return columns;
    }

    getContactsColumns() {
        const noneMessage = fmtMsg({ id: SchoolLocale.TextNone });
        return [
            {
                title: fmtMsg({ id: SchoolLocale.AdminPopoverType }),
                dataIndex: "type",
                key: "type",
                width: 100,
                render(text, recorder) {
                    if (text == CampusAdminContacts.MainShippingContact) {
                        return fmtMsg({ id: SchoolLocale.AdminPopoverShipping });
                    } else if (text == CampusAdminContacts.MainSupportContact) {
                        return fmtMsg({ id: SchoolLocale.AdminPopoverSupport });
                    } else if (text == CampusAdminContacts.MainContact) {
                        return fmtMsg({ id: SchoolLocale.AdminPopoverContact });
                    }
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.AdminPopoverEmail }),
                dataIndex: "email",
                key: "email",
                width: 250,
                render(text, recorder) {
                    return text ? <div><a href={`mailto:${text}`} title={text}>{text}</a></div> : noneMessage;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.AdminPopoverPhone }),
                dataIndex: "phone",
                key: "phone",
                width: 170,
                render(text, recorder) {
                    return text ? <div><a href={`tel:${text}`} title={text}>{text}</a></div> : noneMessage;
                }
            }
        ];
    }

    renderContactContent = () => {
        const { campusContacts: contacts } = this.props;
        const noneMessage = fmtMsg({ id: SchoolLocale.TextNone });
        const formattedContacts = [];
        Object.entries(contacts).forEach(item => {
            if (item[0] == CampusAdminContacts.MainShippingContact ||
                item[0] == CampusAdminContacts.MainSupportContact ||
                item[0] == CampusAdminContacts.MainContact) {
                formattedContacts.push({ type: item[0], ...item[1] });
            }
        });
        return <Table
            pagination={false}
            dataSource={formattedContacts}
            columns={this.getContactsColumns()}
            scroll={{ x: 520 }}
        />
    }

    openGroupMode = () => {
        this.setState({
            showGroupPanel: true
        });
        this.onPagingChange({ current: 1 }, true);
    }

    triggerSearch = (value) => {
        this.className = value;
        this.onPagingChange({ current: 1 }, true);
    }

    renderContent() {
        const { school, list, loading, campuses, total, activeCnt, futureCount, model, match: { params: { schoolId } }, campusContacts: contacts } = this.props;
        this.tableLayout.dataSource = list;
        this.tableLayout.loading = loading;
        (this.tableLayout.pagination as PaginationParams).total = ListStatesStore.getStateCnt(total, activeCnt, this.state, futureCount);
        const params = { regionId: school.regionId, schoolId, campusId: this.selectedDefault };
        const pathCampusEdit = params.regionId && params.schoolId && params.campusId ? GLUtil.pathStringify(PathConfig.CampusEdit, params) : null;
        const pathClassNew = params.regionId && params.schoolId && params.campusId ? GLUtil.pathStringify(PathConfig.ClassNew, params) : null;
        const pathClassPromote = params.regionId && params.schoolId && params.campusId ? GLUtil.pathStringify(`${PathConfig.ClassNew}?isPromote=true`, params) : null;
        const pathCampusAdmins = params.regionId && params.schoolId && params.campusId ? GLUtil.pathStringify(PathConfig.CampusAdmins, params) : null;
        const pathCampusCart = params.regionId && params.schoolId && params.campusId ? GLUtil.pathStringify(PathConfig.CampusCart, params) : null;
        const pathCampusOrders = params.regionId && params.schoolId && params.campusId ? GLUtil.pathStringify(PathConfig.CampusOrders, params) : null;
        const pathClassBulk = params.regionId && params.schoolId && params.campusId ? GLUtil.pathStringify(PathConfig.ClassBulkEdit, params) : null;
        const pathUnitPlanBulk = params.regionId && params.schoolId && params.campusId ? GLUtil.pathStringify(PathConfig.ClassUnitPlanBulk, params) : null;
        const pathMovePromoteStudents = params.regionId && params.schoolId && params.campusId ? GLUtil.pathStringify(PathConfig.MovePromoteStudents, params) : null;
        const pathCampusChanges = params.regionId && params.schoolId && params.campusId ? GLUtil.pathStringify(PathConfig.CampusChangeHistory, params) : null;
        const pathPointsManager = school.regionId ? GLUtil.pathStringify(PathConfig.RegionRewardPoints, { regionId: school.regionId }) : null;
        const selectedCampus = campuses && campuses.length > 0 && campuses.find(c => c.id == this.selectedDefault);
        // startUnit & maxUnit are negative but display as positive

        this.tableLayout.dataSource.map(t => {
            if (!isNaN(t.startUnit)) {
                t.startUnit = Math.abs(t.startUnit);
            }
            if (!isNaN(t.maxUnit)) {
                t.maxUnit = Math.abs(t.maxUnit);
            }
        });

        return (
            <div className='page-content'>
                <div className='campus'>
                    <div>
                        <div className='campus-select'>
                            <div><span><FormattedMessage id={SchoolLocale.ClassCampusSelect} /> : </span></div>
                            <div>
                                {selectedCampus && selectedCampus.name}
                                {pathCampusEdit &&
                                    <GLAction action={GSSchoolAction.EditCampus}>
                                        <Link to={pathCampusEdit}>
                                            <GLIcon type='edit' labelId={SchoolLocale.IconEdit} />
                                        </Link>
                                    </GLAction>}
                            </div>
                            <div className="classname-row__tags">
                            {this.hasTagViewPermission && params.campusId && (
                                <Tagging
                                    entityId={params.campusId}
                                    regionId={params.regionId}
                                    entity={TagEntity.Campus}
                                    isViewOnly={!this.hasTagManagerPermission}
                                    popoverPlacement="bottomLeft"
                                />
                                )}
                            </div> 
                            <div className="classname-row__menu">
                            <CampusFeatureMenu {...{ school, selectedCampus, pathCampusCart, pathCampusOrders, pathClassNew, pathClassPromote, pathClassBulk, pathUnitPlanBulk, pathCampusChanges, pathMovePromoteStudents, pathCampusEdit, pathCampusAdmins, pathPointsManager }} />
                            </div>
                        </div>
                        <div className='campus-admin admin'>
                            <div><FormattedMessage id={SchoolLocale.AdminsAdmin} /> : </div>
                            {/* here use undefined condition to avoid render None text to UI, when request return, the value would be not undefined */}
                            {model.admin != undefined &&
                                <div>
                                    <span className='name'>{model.admin ? model.admin : GLGlobal.intl.formatMessage({ id: SchoolLocale.TextNone })}</span>
                                    {selectedCampus && !selectedCampus.disabled && pathCampusAdmins &&
                                        <GLAction action={GSSchoolAction.CampusAdmins}>
                                            <Link to={pathCampusAdmins}>
                                                <GLIcon type='edit' labelId={SchoolLocale.CampusIconAdmins} />
                                            </Link>
                                        </GLAction>
                                    }
                                    {contacts && (contacts[CampusAdminContacts.MainShippingContact] ||
                                        contacts[CampusAdminContacts.MainContact] ||
                                        contacts[CampusAdminContacts.MainSupportContact])
                                        ? <Popover
                                            title={fmtMsg({ id: SchoolLocale.AdminPopoverCampusContacts })}
                                            content={this.renderContactContent()}
                                            overlayClassName="contact-popover"
                                            placement="bottom"
                                            getPopupContainer={(node) => node.parentElement}
                                            key="admin-contacts"
                                        >
                                            <Icon type="info-circle" className="contact-info-icon" />
                                        </Popover>
                                        : null
                                    }
                                </div>
                            }
                        </div>
                        <div className='campus-license-count'>
                            <div><span><FormattedMessage id={SchoolLocale.ClassLicenseCount} /> : </span></div>
                            <div>
                                <FormattedMessage id={SchoolLocale.ClassLicenseCountContent} values={{ gsLicenseCount: contacts.gsStudentCounts, lsLicenseCount: contacts.lsStudentCounts }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="campus-list-states">
                    <div><span><FormattedMessage id={SchoolLocale.ClassTitle} /> : </span></div>
                    <ListStatesRadios allCnt={total}
                        activeCnt={activeCnt}
                        openGroupMode={this.openGroupMode}
                        showGroupingButton={!this.state.showGroupPanel}
                        futureCount={futureCount || 0}
                        value={this.state.listState}
                        onChange={(state) => {
                            this.setState({ listState: state });
                            this.listStateStore.setState(state);
                            (this.tableLayout.pagination as PaginationParams).current = 1;
                            this.onPagingChange(new PaginationParams(1, 30));
                        }}>
                    </ListStatesRadios>
                </div>
                {/* <SchoolClassesTable className='responsive-table' {...this.tableLayout}></SchoolClassesTable> */}
                <div className="grd-search-class">
                    <Search placeholder={GLGlobal.intl.formatMessage({ id: SchoolLocale.SearchPlaceholderClassName })} onSearch={this.triggerSearch} />
                </div>
                <GLGrid
                    columnComponents={this.getComponentColumn()}
                    allowDragging={AllowDragging.Columns}
                    allowGrouping={this.state.showGroupPanel}
                    sortInClient={this.state.showGroupPanel}
                    pagingInClient={this.state.showGroupPanel}
                    autoResponsive={false}
                    defaultSorter={{
                        columnName: this.sorter.sortBy,
                        columnSortName: this.sorter.sortBy,
                        ascending: !this.sorter.isDescending
                    }}
                    onColumnSorting={this.onColumnSorting.bind(this)}
                    {...this.tableLayout}
                />
            </div>
        );
    }

    render() {
        const { school: { regionId }, match: { params: { schoolId } } } = this.props;
        const params = { regionId, schoolId, campusId: this.selectedDefault };
        return <Container fullWidth className='school-classes-page'>
            <CampusTitle {...params} campuses={this.props.campuses} />
            {this.renderContent()}
        </Container>
    }
}

const CampusTitle = ({ campuses, campusId }) => {
    let title = '';
    if (campuses && campuses.length > 0 && isGuid(campusId)) {
        title = (campuses.find(campus => campus.id === campusId) || {}).name;
    } else {
        title = GLGlobal.intl.formatMessage({ id: SchoolLocale.ClassCampusSelect });
    }
    return (
        <Container fullWidth className='school-classes-page'>
            <MainTitle plain={title} />
        </Container>
    )
}
