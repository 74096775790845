import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { SecondaryTitle, EditDelBtns } from '../../../components';
import { withRouter, GLGlobal, connect, GLAction } from 'gl-commonui';
import { StateType } from '@app/states';
import { SchoolLocale } from '../../../locales/localeid';
import { get, update, remove, goToStudents } from '../../../states/school/class';
import { ClassForm, mapClassDayTime } from './components';
import { get as getSchool } from '../../../states/school/school';
import { GSSchoolAction } from '../../../util/enum';
import { ClassHelper } from '../../../util';
import { PageTitleDelBtn } from '../../../components/school/school-page-title-btn';
import { get as getCampus } from '../../../states/school/campus';
import { lazyInject, TYPES } from "@app/util";
import { SchoolClassService } from "@app/service/class";

interface SchoolClassEditPageProps {
    regionId: any,
    school: any
    model: any
    campusModel: any
    classRoles: string[]
    get: (d) => void
    update: (d) => void
    remove: (id) => void
    goToStudents: () => void
    getSchool: (d) => void
    getCampus: (d) => void
    hasGSConnectPermission: boolean;
}

@withRouter
@connect(({ school: { current }, campus: { model: campusModel }, schoolClass: { model, classRoles, hasGSConnectPermission } }: StateType) => ({
    school: current,
    model,
    classRoles,
    campusModel,
    hasGSConnectPermission
}), {
    get,
    update,
    remove,
    goToStudents,
    getSchool,
    getCampus
})
export class SchoolClassEditPage extends Component<RouteComponentProps<any> & SchoolClassEditPageProps, any> {
    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: SchoolClassService;

    isEditLicense = false

    constructor(props) {
        super(props);
        this.state = {
            disableSaveButton: true,
            isClassManager: false
        };
    }

    componentWillMount() {
        if (!this.props.match.params.classId) {
            return;
        }
        this.schoolClassService.isClassManager(this.props.match.params.classId).then(response => {
            this.setState({ isClassManager: response });
        });
    }

    componentDidMount() {
        const { schoolId, campusId, classId: id } = this.props.match.params;
        this.props.get({ schoolId, id });
        this.props.getCampus({ campusId, schoolId });
        this.props.getSchool({ id: schoolId });
    }

    enableSaveButton = () => {
        if (!this.state.disableSaveButton) {
            return;
        }

        this.setState({ disableSaveButton: false })
    }

    render() {
        const { regionId, classId, schoolId } = this.props.match.params;
        const { update, remove, goToStudents, school, model, classRoles, campusModel, hasGSConnectPermission } = this.props;
        const isGrapeSEEDConnectSettingVisible = !model.disabled && hasGSConnectPermission;
        const days = GLGlobal.intl.formatMessage({ id: SchoolLocale.ClassWeekdays }).split(',').map(day => day.trim());
        const canDelete = ClassHelper.canEditLicense(classRoles, school.allowSchoolEditLicense);
        const hasSaveClassAction = GLGlobal.isActionValid(GSSchoolAction.SaveClass);
        return (
            <div className='content-layout class-crud class-crud-edit'>
                <div className={!hasSaveClassAction ? "ready-only" : ""}>
                    <SecondaryTitle title={SchoolLocale.ClassEditTitle} >
                        {canDelete &&
                            <GLAction action={GSSchoolAction.DeleteClass}>
                                <PageTitleDelBtn type="delete" className="delete" labelId={SchoolLocale.BtnDelete} deleteTitleId={SchoolLocale.ClassDelete} delete={remove} />
                            </GLAction>
                        }
                    </SecondaryTitle>
                    <ClassForm isEdit={true} school={school}
                        isGrapeSEEDConnectVisible={isGrapeSEEDConnectSettingVisible}
                        model={mapClassDayTime(model, days)}
                        regionId={regionId}
                        classId={classId}
                        schoolId={schoolId}
                        isClassManager={this.state.isClassManager}
                        annualPrepComplete={campusModel.annualPrepComplete}
                        classRoles={classRoles}
                        campusDisabled={campusModel.disabled}
                        enableSaveButton={this.enableSaveButton}
                        handleSubmit={update}>
                        {hasSaveClassAction && <EditDelBtns isDisabled={this.state.disableSaveButton} delete={remove} onCancel={goToStudents} localeId={SchoolLocale.ClassDelete} submitAction={GSSchoolAction.SaveClass} deleteAction={GSSchoolAction.DeleteClass} />}
                        {!hasSaveClassAction && <EditDelBtns submitTitle={SchoolLocale.ClassEditBackBtn} delete={remove} localeId={SchoolLocale.ClassDelete} submitAction={GSSchoolAction.EditClass} deleteAction={GSSchoolAction.DeleteClass} />}
                    </ClassForm>
                </div>
            </div>
        )
    }
}
