import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Dropdown, Menu, Icon, Badge } from 'antd-min';
import { alignPop, GLAction, GLGlobal } from 'gl-commonui';
import { GLIcon } from '@app/components';
import { GSSchoolAction, GSAdminAction, fmtMsg, IconFont } from '@app/util';
import { SchoolLocale, GSAdminLocale, MovePromoteStudentsLocale, RewardPointsLocale } from '@app/locales/localeid';
import './campus-feature-menu.less';

export const CampusFeatureMenu = ({ school, selectedCampus, pathCampusCart, pathCampusOrders, pathClassNew, pathClassPromote, pathClassBulk, pathUnitPlanBulk, pathCampusChanges, pathMovePromoteStudents, pathCampusEdit, pathCampusAdmins, pathPointsManager }) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const hasCampusCartMenu =  selectedCampus && !selectedCampus.disabled && pathCampusCart &&school.allowMaterialRequest && GLGlobal.isActionValid(GSSchoolAction.CampusCart);
    const hasCampusOrderMenu = selectedCampus && pathCampusOrders && school.allowMaterialRequest && GLGlobal.isActionValid(GSSchoolAction.CampusOrders);
    const hasFirstDivider = hasCampusCartMenu || hasCampusOrderMenu;
    const hasAddClassMenu = selectedCampus && !selectedCampus.disabled && pathClassNew && GLGlobal.isActionValid(GSSchoolAction.AddClass) && !(selectedCampus.annualPrepComplete && !GLGlobal.isActionValid(GSSchoolAction.IgnoreCampusAnnualPrepComplete));
    const hasClassPromoteMenu = selectedCampus && !selectedCampus.disabled && pathClassPromote && school.allowPromoteClass && GLGlobal.isActionValid(GSSchoolAction.AddClass) && !(selectedCampus.annualPrepComplete && !GLGlobal.isActionValid(GSSchoolAction.IgnoreCampusAnnualPrepComplete));
    const hasClassBulkEditMenu = selectedCampus && !selectedCampus.disabled && pathClassBulk && GLGlobal.isActionValid(GSSchoolAction.BulkEditClass);
    const hasUnitPlanBulkEditMenu = selectedCampus && !selectedCampus.disabled && pathUnitPlanBulk && GLGlobal.isActionValid(GSSchoolAction.EditUnitPlan);
    const hasMovePromoteStudentMenu = selectedCampus && !selectedCampus.disabled && pathMovePromoteStudents && GLGlobal.isActionValid(GSSchoolAction.MovePromoteStudents);
    const isMovePromoteStudentMenuDisabled = selectedCampus && !selectedCampus.disabled && pathMovePromoteStudents && selectedCampus.annualPrepComplete;
    const hasCampusChangeMenu = selectedCampus && pathCampusChanges && GLGlobal.isActionValid(GSAdminAction.ListSchoolChangeHistory);
    const hasSecondDivider = hasAddClassMenu || hasClassPromoteMenu || hasClassBulkEditMenu || hasUnitPlanBulkEditMenu || hasMovePromoteStudentMenu;
    const hasCampusEditMenu = selectedCampus && pathCampusEdit && GLGlobal.isActionValid(GSSchoolAction.EditCampus);
    const hasCampusAdminMenu = selectedCampus && !selectedCampus.disabled && pathCampusAdmins && GLGlobal.isActionValid(GSSchoolAction.CampusAdmins);
    const hasManagePointsMenu = pathPointsManager && GLGlobal.isActionValid(GSAdminAction.ManagePointsView);
    const menus = (
        <Menu className="campus-feature-menu">
            {hasCampusEditMenu &&
                <Menu.Item>
                    <Link to={pathCampusEdit}>
                        <Icon type="edit" />
                        <FormattedMessage id={SchoolLocale.EditCampus} />
                    </Link>
                </Menu.Item>}
                {hasCampusEditMenu && <Menu.Divider />}
            {hasCampusAdminMenu &&
                <Menu.Item>
                    <Link to={pathCampusAdmins}>
                        <IconFont type="glscoach" />
                        <FormattedMessage id={SchoolLocale.AdminsAdmin} />
                    </Link>
                </Menu.Item>}
                {hasCampusAdminMenu && <Menu.Divider />}
            {hasAddClassMenu &&
            <Menu.Item>
                <Link to={pathClassNew}>
                    <Icon type="plus-circle-o" />
                    <FormattedMessage id={SchoolLocale.ClassAdd} />
                </Link>
            </Menu.Item>}
            {hasClassPromoteMenu &&
            <Menu.Item>
                <Link to={pathClassPromote}>
                    <Icon type="select" />
                    <FormattedMessage id={SchoolLocale.ClassPromote} />
                </Link>
            </Menu.Item>}
            {hasClassBulkEditMenu &&
            <Menu.Item>
                <Link to={pathClassBulk}>
                    {/* <Icon type="select" /> */}
                    <Icon type="form" />
                    <FormattedMessage id={SchoolLocale.ClassBulkEdit} />
                </Link>
                </Menu.Item>}
            {hasUnitPlanBulkEditMenu &&
            <Menu.Item>
                <Link to={pathUnitPlanBulk}>
                    {/* <Icon type="select" /> */}
                    <IconFont type="glsunitplan" />
                    <FormattedMessage id={SchoolLocale.UnitPlanBulkTitle} />
                </Link>
            </Menu.Item>}
            {hasMovePromoteStudentMenu && 
            <Menu.Item disabled={isMovePromoteStudentMenuDisabled} className={isMovePromoteStudentMenuDisabled && 'disabled-menuitem'}>
                <Link to={pathMovePromoteStudents}>
                    <Icon type="select" />
                    <FormattedMessage id={MovePromoteStudentsLocale.CampusFeatureTitle} />
                </Link>
            </Menu.Item>}
            {hasSecondDivider && <Menu.Divider />}
            {hasCampusCartMenu &&
            <Menu.Item>
                <Link to={pathCampusCart}>
                    <GLIcon type="shopping-cart" />
                    <FormattedMessage id={SchoolLocale.CampusIconCart} />
                </Link>
            </Menu.Item>}
            {hasCampusOrderMenu &&
            <Menu.Item>
                <Link to={pathCampusOrders}>
                    <GLIcon type="icon-Campus-Order-History" />
                    <FormattedMessage id={SchoolLocale.CampusIconOrder} />
                </Link>
            </Menu.Item>}
            {hasFirstDivider && <Menu.Divider />}
            {hasCampusChangeMenu &&
                <Menu.Item>
                    <Link to={pathCampusChanges}>
                        <Icon type="switcher" />
                        <FormattedMessage id={SchoolLocale.CIMSSchoolLogTitle} />
                    </Link>
                </Menu.Item>}
            {hasManagePointsMenu &&
                <Menu.Item>
                    <Link to={pathPointsManager}>
                    <Icon type="form" />
                    <FormattedMessage id={RewardPointsLocale.EntryText} />
                    </Link>
                </Menu.Item>}
        </Menu>
    );
    return (
        <div className='campus-feature-menu-icon'>
            <Dropdown overlay={menus} trigger={['click']} onVisibleChange={setMenuVisible} {...alignPop()}>
    <span><Icon type="unordered-list" /> {fmtMsg({id: GSAdminLocale.CampusManage})} {menuVisible ? <Icon type="up" className="anticon-arrow" /> : <Icon type="down" className="anticon-arrow" />}</span>
            </Dropdown>
        </div>
    )
}
