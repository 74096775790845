import { SchoolLocale } from "@app/locales/localeid";
import { CurriculumType, SchoolBulkClassModel } from "@app/service/class";
import { SchoolModel } from "@app/service/schools";
import { CONSTS, DateHelper, deepClone, fmtMsg, GSSchoolAction, guid, LicenseTypes, LicenseTypeValueNameMap } from "@app/util";
import { GLGlobal, GLUtil, LanguageDateFormat } from "gl-commonui";
import { cloneDeep, toNumber } from "lodash";
import moment from "moment";
import { Column, DataMap } from "wijmo/wijmo.grid";

export enum BulkAction {
    None,
    New,
    Promote,
    Promoted,
    Duplicate,
    Duplicated,
    Close,
}
export const formatClasses = (data: SchoolBulkClassModel[]) => {
    data.forEach((d) => {
        d.action = BulkAction.None;
        if (d.isPromoted) {
            d.action = BulkAction.Promoted;
            d.exist = true;
        }
        if (d.hasNextPromoted) {
            d.action = BulkAction.Promote;
            d.exist = true;
        }

        d.className = d.name;
        d.startDate = DateHelper.toLocalDateLiteral(d.startDate);
        d.endDate = DateHelper.toLocalDateLiteral(d.endDate);
        d.closeDate = DateHelper.toLocalDateLiteral(d.closeDate);
        d.keepTeachers = false;
        d.keepStudents = false;
        d.studentAgeGrade = d.age;
        d.startUnit = Math.abs(d.startUnit);
        d.maxUnit = Math.abs(d.maxUnit);
        d.keepClassTimes = false;
        d.classTimes = d.classTime;
        d.tsiMinutesPerWeek = d.tsiMinutesPerWeek || 0;
        d.tsiLessonsPerWeek = d.tsiLessonsPerWeek || 3;
        d.tsiLessonsPerYear = d.tsiLessonsPerYear || 0;
        d.promptClassID = (d as any).promptClassId;
        d.duplicateClassID = null;
        d.teachers.forEach((teacher) => (teacher.isMainTeacher = !teacher.substitute));
        d.students = d.students.sort((pre, cur) => pre.englishName.localeCompare(cur.englishName));

        if (d.closeDate) {
            d.action = BulkAction.Close;
        }
    });
    return data;
};

export const getActionDataMap = (isPromotionAllowed: boolean, isAnnualPrepComplete: boolean): DataMap => {
    const itemsSource = new Map([
        [BulkAction.None, { id: BulkAction.None, name: fmtMsg(SchoolLocale.BulkNone) }],
        [BulkAction.New, { id: BulkAction.New, name: fmtMsg(SchoolLocale.BulkNew) }],
        [BulkAction.Promote, { id: BulkAction.Promote, name: fmtMsg(SchoolLocale.BulkPromote) }],
        [BulkAction.Promoted, { id: BulkAction.Promoted, name: fmtMsg(SchoolLocale.BulkPromoted) }],
        [BulkAction.Duplicate, { id: BulkAction.Duplicate, name: fmtMsg(SchoolLocale.BulkDuplicate) }],
        [BulkAction.Duplicated, { id: BulkAction.Duplicated, name: fmtMsg(SchoolLocale.BulkDuplicated) }],
        [BulkAction.Close, { id: BulkAction.Close, name: fmtMsg(SchoolLocale.BulkClose) }],
    ]);
    const dataMap = new DataMap(Array.from(itemsSource.values()), "id", "name");
    dataMap.getDisplayValues = (dataItem) => {
        let actions = [BulkAction.None, BulkAction.Promote, BulkAction.Duplicate, BulkAction.Close];
        if (!isPromotionAllowed) {
            actions = [BulkAction.None, BulkAction.Duplicate, BulkAction.Close];
        }
        if (dataItem.promptClassID) {
            actions = [BulkAction.None, BulkAction.Duplicate];
        }
        if (dataItem.closeDate) {
            actions = [BulkAction.None, BulkAction.Close];
        }
        if (isAnnualPrepComplete) {
            actions = actions.filter((action) => ![BulkAction.Promote, BulkAction.Duplicate, BulkAction.Close].includes(action));
        }
        return actions.map((action) => itemsSource.get(action).name);
    };
    return dataMap;
};
const mergedLicenses = (school, campusLicenseList) => {
    const licenses = school.grapeSEEDLicenses.filter(x => x == 1 || x == 2 || x == 4);
    const mergedLicenses = [...licenses, ...campusLicenseList].sort();
    return  mergedLicenses;
}

export const getLicenseTypeValue = (school: SchoolModel, campusLicenseList : number[]): DataMap => {
    const itemsSource = new Map([
        [LicenseTypes.Classic, { id: LicenseTypes.Classic, name: fmtMsg({ id: CONSTS.LicenseType.get(LicenseTypes.Classic) }) }],
        [LicenseTypes.Connect, { id: LicenseTypes.Connect, name: fmtMsg({ id: CONSTS.LicenseType.get(LicenseTypes.Connect) }) }],
        [LicenseTypes.Nexus, { id: LicenseTypes.Nexus, name: fmtMsg({ id: CONSTS.LicenseType.get(LicenseTypes.Nexus) }) }],
        [LicenseTypes.ClassicConnectHybrid, { id: LicenseTypes.ClassicConnectHybrid, name: fmtMsg({ id: CONSTS.LicenseType.get(LicenseTypes.ClassicConnectHybrid) }) }],
        [LicenseTypes.ClassicNexusHybrid, { id: LicenseTypes.ClassicNexusHybrid, name: fmtMsg({ id: CONSTS.LicenseType.get(LicenseTypes.ClassicNexusHybrid) }) }],
        [LicenseTypes.ConnectNexusHybrid, { id: LicenseTypes.ConnectNexusHybrid, name: fmtMsg({ id: CONSTS.LicenseType.get(LicenseTypes.ConnectNexusHybrid) }) }],
    ]);

    let mergedLicense = [];
    mergedLicense = mergedLicenses(school, campusLicenseList);
    const dataMap = new DataMap(Array.from(itemsSource.values()), "id", "name");

    dataMap.getDisplayValues = (dataItem) => {
        let licenseTypes = [LicenseTypes.Classic, LicenseTypes.Connect, LicenseTypes.Nexus, LicenseTypes.ClassicConnectHybrid, LicenseTypes.ClassicNexusHybrid, LicenseTypes.ConnectNexusHybrid];
        if (dataItem.curriculumType === CurriculumType.GrapeSEED) {
            licenseTypes = mergedLicense.filter(gsl => licenseTypes.includes(gsl));
        }
        else if (dataItem.curriculumType === CurriculumType.LittleSEED) {
            licenseTypes = school.littleSEEDLicenses.filter(lsl => licenseTypes.includes(lsl));
        }
        
        return licenseTypes.map((lt) => itemsSource.get(lt).name);
    };

    return dataMap;
}

export const getCurriculumTypeDataMap = (lsMaxUnit: number, maxUnit: number): DataMap => {
    var curriculumTypes = [];

    if (maxUnit && maxUnit != 0) {
        curriculumTypes.push(CurriculumType.GrapeSEED);
    }

    if (lsMaxUnit != 0) {
        curriculumTypes.push(CurriculumType.LittleSEED);
    }

    var curriculumTypeDataMap = new DataMap(
        curriculumTypes.map((curriculumType) => ({ id: curriculumType, name: CurriculumType[curriculumType] })),
        "id",
        "name",
    );
    return curriculumTypeDataMap;
};

export const getUnitColumnDataMap = (isStartUnit: boolean, school: SchoolModel): DataMap => {
    const getUnitCount = (curriculumType?) =>
    curriculumType !== undefined ? (curriculumType === CurriculumType.GrapeSEED ? school.maxUnit : school.lsMaxUnit * -1) : 96;
    const getUnits = (curriculumType?) =>
        new Array(getUnitCount(curriculumType)).fill(1).map((unit, step) => ({ id: step + unit, name: (step + unit).toString() }));
    var unitDataMap = new DataMap(getUnits(), "id", "name");
    unitDataMap.getDisplayValues = (dataItem) => {
        if (isNewClass(dataItem) && dataItem.action === BulkAction.Promoted) {
            const maxUnit = school.maxUnit;
            const getRangeUnits = (units) => (maxUnit === dataItem.startUnit ? [maxUnit] : units);
            if (isStartUnit) {
                return getRangeUnits(
                    dataItem.fromLittleSEEDToGrapeSEED ? [dataItem.lastMaxUnitPrompt] : [dataItem.lastMaxUnitPrompt, dataItem.lastMaxUnitPrompt + 1],
                );
            } else {
                return getRangeUnits(
                    new Array(maxUnit - dataItem.lastMaxUnitPrompt).fill(0).map((_, index) => dataItem.lastMaxUnitPrompt + index + 1),
                );
            }
        }

        return getUnits(dataItem.curriculumType).map((d) => d.name);
    };
    return unitDataMap;
};

export const isNewClass = (rowData: SchoolBulkClassModel): boolean => {
    const isExist = !!rowData.exist;
    return [BulkAction.Promoted, BulkAction.Duplicated, BulkAction.New].includes(rowData.action) && !isExist;
};
export const isFutureClass = (data: SchoolBulkClassModel): boolean => {
    return new Date(data.startDate) > new Date() && !isNewClass(data);
};
export const isNormalClass = (rowData: SchoolBulkClassModel): boolean => {
    return new Date(rowData.startDate) <= new Date() && !isNewClass(rowData);
};
export const isGrapeSeedClass = (rowData: SchoolBulkClassModel): boolean => {
    return rowData.curriculumType === CurriculumType.GrapeSEED
};

export function getDateFormatTemplate(): string {
    return LanguageDateFormat[GLGlobal.intl.locale].replace(/D/g, "d").replace(/Y/g, "y");
}

export function getNextDate(): Date {
    return moment()
        .add(1, "day")
        .toDate();
}

export const isOnlyLittleSEED = (school: SchoolModel) => {
    return !school.maxUnit && school.lsMaxUnit;
};
export function canEditStartMaxUnit(school: SchoolModel): CanEditStartMaxUnitReturnType {
    const getEditPermisson = (cant: boolean) => {
        if (cant) {
            return {
                editStartUnit: GLGlobal.isActionValid(GSSchoolAction.EditClassStartUnit),
                editMaxUnit: GLGlobal.isActionValid(GSSchoolAction.EditClassMaxUnit),
            };
        }
        return {
            editStartUnit: false,
            editMaxUnit: false,
        };
    };

    return getEditPermisson(school.allowSchoolEditUnit || GLGlobal.isActionValid(GSSchoolAction.ClassIgnoreRegionEditUnit));
}

const nextYear = (date: Date | string): Date => {
    // remove [.add(1, "day")] logical for GL-8661 ([Bulk Class Editor] A day is skipped when the start date is set to a promoted class)
    return moment(date)
        .add(1, "year")
        //.add(1, "day")
        .toDate();
};

const tomorrow = (date: Date | string): Date => {
    return moment(date)
        .add(1, "day")
        .toDate();
};

const increaseField = (field: number | string) => {
    const ageGradeRegex = /^\d+(,\d+)*$/;
    const fieldToNumber = toNumber(field);
    if (!isNaN(fieldToNumber)) {
        return `${fieldToNumber + 1}`;
    }
    if (typeof field === "string") {
        if (ageGradeRegex.test(field)) {
            const intArray = field.split(",").map(a => parseInt(a.trim()) + 1);
            return intArray.join(",");
        }
        else {
            return "";
        }
    }
    return field;
};

export function generatePromotedClass(bulkClass: SchoolBulkClassModel, school: SchoolModel, enforceDateEnabled: boolean): SchoolBulkClassModel {
    const { maxUnit, littleSEEDLicenses, grapeSEEDLicenses } = school;
    const { curriculumType, term, studentAgeGrade, id } = bulkClass;
    const startDate = enforceDateEnabled ? bulkClass.startDate : null;
    const endDate = enforceDateEnabled ? bulkClass.endDate : null;
    const promptClassID = guid();
    const promptUnit = (increase = 1) => {
        if (curriculumType === CurriculumType.LittleSEED) {
            return 1;
        }
        return bulkClass.maxUnit >= maxUnit ? maxUnit : bulkClass.maxUnit + increase;
    };

    bulkClass.promptClassID = promptClassID;
    return {
        ...cloneDeep(bulkClass),
        id: promptClassID,
        action: BulkAction.Promoted,
        term: null,
        startDate: getStartDate(term, endDate, startDate),
        endDate: getEndDate(term, endDate),
        curriculumType: isOnlyLittleSEED(school) ? CurriculumType.LittleSEED : CurriculumType.GrapeSEED,
        studentAgeGrade: increaseField(studentAgeGrade),
        startUnit: promptUnit(),
        maxUnit: promptUnit(),
        lastMaxUnitPrompt: promptUnit(0),
        fromLittleSEEDToGrapeSEED: curriculumType === CurriculumType.LittleSEED,
        keepClassTimes: true,
        keepTeachers: false,
        sourceClassId: id,
        dirty: true,
        exist: false,
        hasStartUnitPlan: false,
        licenseType: isOnlyLittleSEED(school) ? littleSEEDLicenses[0] : grapeSEEDLicenses[0],
        licenses: 0
    };
}

export function generateDuplicateClass(bulkClass: SchoolBulkClassModel, school: SchoolModel, enforceDateEnabled: boolean): SchoolBulkClassModel {
    const { className, id, term } = bulkClass;
    const startDate = enforceDateEnabled ? bulkClass.startDate : null;
    const endDate = enforceDateEnabled ? bulkClass.endDate : null;
    const duplicateClassID = guid();
    bulkClass.duplicateClassID = duplicateClassID;
    return {
        ...deepClone(bulkClass),
        id: duplicateClassID,
        action: BulkAction.Duplicated,
        term,
        startDate: getStartDate(term, endDate, startDate),
        endDate: getEndDate(term, endDate),
        className: `${className} 2`,
        keepClassTimes: true,
        sourceClassId: id,
        dirty: true,
        exist: false,
        hasStartUnitPlan: false
    };
}

export function setRowAsDirty(column: Column, dataItem: SchoolBulkClassModel, originalDataSource: { [key: string]: SchoolBulkClassModel }) {
    const key = column.binding;
    const notIncludes = (key: string) => !["revert", "action"].includes(key);
    const notWatchingNew = !isNewClass(dataItem);
    const notIncludeInners = (key: string) => !["teachers", "students", "duplicateClassID", "promptClassID", "promptClassId"].includes(key);
    const isDate = (key: string) => ["startDate", "endDate", "closeDate"].includes(key);
    if (notIncludes(key) && notWatchingNew) {
        const compare = (pre, cur, isDateType) => {
            if (!GLUtil.isNullOrUndefined(pre) && !GLUtil.isNullOrUndefined(cur)) {
                if (isDateType && pre && cur) {
                    return new Date(pre).getTime() !== new Date(cur).getTime();
                }
                return pre !== cur;
            } else {
                return (!pre && cur) || (pre && !cur);
            }
        };
        const origin = originalDataSource[dataItem.id];
        if (compare(origin[key], dataItem[key], isDate(key))) {
            dataItem.dirty = true;
        } else {
            let hasOtherChanged = false;
            Object.getOwnPropertyNames(origin)
                .filter((key) => notIncludes(key) && notIncludeInners(key))
                .forEach((key) => {
                    if (compare(origin[key], dataItem[key], isDate(key))) {
                        hasOtherChanged = true;
                    }
                });
            dataItem.dirty = hasOtherChanged;
        }
    }
}

export function copyTSIREP(row: SchoolBulkClassModel): Partial<SchoolBulkClassModel> {
    return {
        tsiMinutesPerWeek: row.tsiMinutesPerWeek,
        tsiLessonsPerWeek: row.tsiLessonsPerWeek,
        tsiLessonsPerYear: row.tsiLessonsPerYear,
    };
}

export function unorderedMap<T = any>(collection: T[], field: keyof T): { [key: string]: T } {
    const unorderedData = {};
    collection.forEach((item) => {
        const key = item[field] as any;
        unorderedData[key] = item;
    });
    return unorderedData;
}

export interface CanEditStartMaxUnitReturnType {
    editStartUnit: boolean;
    editMaxUnit: boolean;
}

const getStartDate = (term: string | number, endDate: string, startDate: string) => {
    if (term) {
        if (endDate) {
            return DateHelper.toLocalString(tomorrow(endDate));
        }
        else if(startDate) {
            return DateHelper.toLocalString(nextYear(startDate));
        }
        else {
            return null;
        }
    }
    else {
        return null;
    }
}

const getEndDate = (term: string | number, endDate: string) => {
    if (term && endDate) {
        return DateHelper.toLocalString(nextYear(endDate));
    }
    else {
        return null;
    }
}
