import {
    GLLocale,
    GLGlobal,
    MessageHelper,
    NotificationType
} from "gl-commonui";
import { SchoolLocale, GSAdminLocale } from "@app/locales/localeid";
import { subscriptionTypeUsage, fmtMsg, DateHelper } from "@app/util";
import moment from "moment";

export function getUsageText(usage) {
    let id;
    switch (usage) {
        case subscriptionTypeUsage.Standard:
            id = SchoolLocale.LicenseTypeUsageStandard;
            break;
        case subscriptionTypeUsage.Dual:
            id = SchoolLocale.LicenseTypeUsageDual;
            break;
        case subscriptionTypeUsage.Single:
            id = SchoolLocale.LicenseTypeUsageSingle;
            break;
        default:
            id = SchoolLocale.TextNone;
            break;
    }
    return fmtMsg(id);
}

export function cntCpr(
    subNewCnt,
    licenseNewCnt,
    type,
    comparerLocale?,
    throwErr?
) {
    if (subNewCnt < licenseNewCnt) {
        const msg = fmtMsg => {
            const { LicenseNewCnt, LicenseNewOtherTypeCnt } = SchoolLocale;
            const stuCnt = fmtMsg({ id: LicenseNewCnt }).replace(":", "");
            const licenseCnt = fmtMsg(
                { id: LicenseNewOtherTypeCnt },
                { type: fmtMsg({ id: type }) }
            )
                .replace(":", "")
                .toLowerCase();
            return fmtMsg(
                {
                    id: comparerLocale
                        ? comparerLocale
                        : GLLocale.FormCprGreaterOrEquals
                },
                { name: stuCnt, value: licenseCnt }
            );
        };
        //message.warn(msg);
        if (throwErr) {
            const error = msg(GLGlobal.intl.formatMessage);
            MessageHelper.Message(NotificationType.Warning, error);
            throw error;
        } else {
            return msg;
        }
    }
}
export function newDigitalCntCpr(pre, cur, throwErr?) {
    if (pre > cur) {
        const msg = fmtMsg => {
            const { LicenseNewCnt, LicenseDigitalAccountCnt } = SchoolLocale;
            const stuCnt = fmtMsg({ id: LicenseNewCnt }).replace(":", "");
            const accountCnt = fmtMsg({ id: LicenseDigitalAccountCnt });
            return fmtMsg(
                { id: GLLocale.FormCprGreaterOrEquals },
                { name: stuCnt, value: accountCnt }
            );
        };
        //message.warn(msg);
        if (throwErr) {
            const error = msg(GLGlobal.intl.formatMessage);
            MessageHelper.Message(NotificationType.Warning, error);
            throw error;
        } else {
            return msg;
        }
    }
}

export function updateLicenseWithMaterialBy(
    schoolId,
    classId,
    updateLicenseWithMaterial
) {
    return () => updateLicenseWithMaterial({ schoolId, classId });
}

export function NaNToZero(data) {
    return Number.isNaN(data) ? 0 : data;
}
export function formatWithSymbol(data) {
    return data >= 0 ? `+${data}` : data;
}
// If billing startday and billingperiod is 1 then returns the name of month. else formatted date range.
export const getPeriodFormattedDate = (
    startDate: string | Date,
    endDate: string | Date,
    props: { regionInfo: { billingStartDay: number; billingPeriod: number } }
) => {
    const MonthMap = {
        1: GSAdminLocale.MonthCompleteJanuary,
        2: GSAdminLocale.MonthCompleteFebruary,
        3: GSAdminLocale.MonthCompleteMarch,
        4: GSAdminLocale.MonthCompleteApril,
        5: GSAdminLocale.MonthCompleteMay,
        6: GSAdminLocale.MonthCompleteJune,
        7: GSAdminLocale.MonthCompleteJuly,
        8: GSAdminLocale.MonthCompleteAugust,
        9: GSAdminLocale.MonthCompleteSeptember,
        10: GSAdminLocale.MonthCompleteOctober,
        11: GSAdminLocale.MonthCompleteNovember,
        12: GSAdminLocale.MonthCompleteDecember
    };

    if (!props || !props.regionInfo || !startDate) {
        return "";
    }
    const {
        regionInfo: { billingStartDay, billingPeriod }
    } = props;
    if (billingStartDay === 1 && billingPeriod === 1) {
        return fmtMsg({id: MonthMap[moment(startDate, null, "en", null).format("M")]});
    }
    return (
        DateHelper.toLocalString(startDate) +
        (endDate ? " ~ " : "") +
        DateHelper.toLocalString(endDate)
    );
};
