import * as React from "react";
import { Link } from "react-router-dom";
import { GLLocale, GLUtil, RoleName } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
import { Draggable } from "..";
import { StudentInformation } from ".";
import "./student.less";
import { CommonSectionDescModal } from "./modals/CommonSectionDescModal";
import { StandardDualSectionDescModal } from "./modals/standardDualSectionDescModal";
import { CurriculumType, SubscriptionType } from "@app/service/class/model";
import { StudentSubscriptionType } from "@app/util/enum";
import { ContextHelper, fmtMsg, isFutureClass } from "@app/util";
import { SchoolLocale } from "@app/locales/localeid";
import { Modal } from "gl-commonui/lib/antd-min";
import { useEffect } from "react";

enum SubscriptionModalType {
    negate,
    add,
    none
}

export const Student = ({ id: studentId, name, params, index, classRoles, canEditLicense, students,
    componentType, isDual, isSingle, onLicenseChange, onRemoveStudent, subTypeN, regionInfo, subscriptionType, licenseTypeN, counts, disableFutureLicenseEdit }) => {
    const [visible, setVisible] = React.useState({ bottomNegative: false, topNegative: false})
    const isDualLicenseSection = componentType === "dual" && !(isDual || isSingle);
    const isTeacher = ContextHelper.isUserMatchRole(RoleName.teacher, classRoles);
    const [studentFiltered, setStudentFiltered] = React.useState<any[]>([]);

    const dualSubStudents =
            students ?
            students.filter(
                s => s.subscriptionType == StudentSubscriptionType.Dual
            ) : [];
                
    useEffect(() => {
        const studentsLocal = Array.isArray(students) ? students : [];
        const classId = GLUtil.pathParse(PathConfig.Students).classId;
        const filteredStudents = subscriptionType === StudentSubscriptionType.Dual
            ? studentsLocal.filter(s => s.subscriptionType === StudentSubscriptionType.Dual)
            : studentsLocal.filter(s => s.subscriptionType === subscriptionType);

        if (classId) {
            isFutureClass(classId).then(res => {
                if (subscriptionType === StudentSubscriptionType.Dual && res
                    && studentsLocal.some(x => x.subscriptionType === StudentSubscriptionType.TextBook)) {
                    const textBookStudents = students.filter(x => x.subscriptionType === StudentSubscriptionType.TextBook);
                    setStudentFiltered([...filteredStudents, ...textBookStudents]);
                }
                else {
                    setStudentFiltered(filteredStudents);
                }
            });
        }
    }, [students]);

    const getModalHeading = (typeOfModal: SubscriptionModalType = null): string => {
        if (isDualLicenseSection) {
            if (typeOfModal === SubscriptionModalType.negate) {
                return fmtMsg({ id: SchoolLocale.EditClassDescDualLicence });
            } else {
                return "";
            }
        }

        if (subTypeN === SubscriptionType.Textbook) {
            if (typeOfModal === SubscriptionModalType.negate) {
                return fmtMsg({ id: SchoolLocale.EditClassDescTxtLicence });
            } else {
                return fmtMsg({ id: SchoolLocale.EditClassIncTxtLicence });
            }
        }

        if (subTypeN === SubscriptionType.Digital) {
            if (typeOfModal === SubscriptionModalType.negate) {
                return fmtMsg({ id: SchoolLocale.EditClassDescDigLicence });
            } else {
                return fmtMsg({ id: SchoolLocale.EditClassIncDigLicence });
            }
        }
    };

    const toggleModal = () => {
        if (canEditLicense){
            if (isDualLicenseSection) {
                setVisible({...visible, bottomNegative: true});
            } else {
                setVisible({...visible, topNegative: true});
            }
        }
        else if (!canEditLicense && isTeacher){
            Modal.confirm({
                title: fmtMsg({ id: SchoolLocale.StudentRegistrationRemoveConfirmationTitle }),
                okText: fmtMsg({ id: GLLocale.Ok }),
                cancelText: fmtMsg({ id: GLLocale.Cancel }),
                onOk: () => {
                    onRemoveStudent(studentId)
                }
            })
        }
    };

    return (
        <>
        <Draggable isDragDisabled={!canEditLicense} draggableId={studentId} index={index}>
            {(provided, snapshot) => (
                <div  {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}>
                    <StudentInformation studentId={studentId} isDelete={false} />
                    <span title={name}><Link to={params.regionId ? GLUtil.pathStringify(PathConfig.StudentReport, { ...params, studentId }) : '/'}>{name}</Link></span>
                    {(canEditLicense || isTeacher) && <span className='decrease_license' onClick={() => toggleModal()}>X</span>}
                    {/* {GLGlobal.isActionValid(GSSchoolAction.RemoveStudents) && canEditLicense &&
                        <StudentInformation studentId={studentId} isDelete={true} onOK={deleteStudent} />
                    } */}
                </div>
            )}
        </Draggable >
        {visible.topNegative && (
            <CommonSectionDescModal
                onCancel={() => setVisible({...visible, topNegative: false})}
                students={studentFiltered}
                currentStudentId={studentId}
                onSave={d => {
                    onLicenseChange(d);
                    setVisible({...visible, topNegative: false});
                }}
                isDual={isDual}
                licenseCount={counts}
                visible={visible.topNegative}
                title={getModalHeading(
                    SubscriptionModalType.negate
                )}
                regionInfo={regionInfo}
                canEditLicense={canEditLicense}
                subType={subTypeN}
                isLittleSeed={
                    licenseTypeN === CurriculumType.LittleSEED
                }
                disableFutureLicenseEdit={disableFutureLicenseEdit}
            />
        )}
        {visible.bottomNegative && (
            <StandardDualSectionDescModal
                onCancel={() => setVisible({...visible, bottomNegative: false}) }
                students={dualSubStudents}
                currentStudentId={studentId}
                onSave={d => {
                    onLicenseChange(d);
                    setVisible({...visible, bottomNegative: false})
                }}
                licenseCount={counts}
                visible={visible.bottomNegative}
                title={getModalHeading(
                    SubscriptionModalType.negate
                )}
                regionInfo={regionInfo}
                canEditLicense={canEditLicense}
                subType={subTypeN}
                disableFutureLicenseEdit={disableFutureLicenseEdit}
            />
        )}
        </>
    )
}
