import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "gl-commonui";
import {UnitPlan} from "@app/page/school/class/components/unitplan";

@withRouter
export class UnitPlanPage extends Component<RouteComponentProps<any>> {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            match: {
                params: { schoolId, campusId, classId, regionId }
            },
        } = this.props;
        return (
            <UnitPlan regionId={regionId} schoolId={schoolId} campusId={campusId} classId={classId} />
        );
    }
}
