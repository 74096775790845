import * as React from "react";
import { SubscribedStudentsProps, Student } from ".";
import { Droppable } from "..";
import { CurriculumType } from "@app/service/class";
import { fmtMsg, isFutureClass, StudentSubscriptionType } from "@app/util";
import { SchoolLocale } from "@app/locales/localeid";
import { GLUtil } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
import { useEffect } from "react";

export const Students = ({
    total,
    students,
    subscriptionType,
    componentType,
    isDual, isSingle,
    match: { params },
    licenseTypeN,
    classRoles,
    canEditLicense,
    regionId,
    onLicenseChange,
    onRemoveStudent,
    subTypeN,
    regionInfo,
    counts,
    disableFutureLicenseEdit
}: SubscribedStudentsProps) => {
    const [studentFiltered, setStudentFiltered] = React.useState<any[]>([]);

    useEffect(() => {
        const studentsLocal = Array.isArray(students) ? students : [];
        const classId = GLUtil.pathParse(PathConfig.Students).classId;

        const filteredStudents = subscriptionType === StudentSubscriptionType.Dual
            ? studentsLocal.filter(s => s.subscriptionType === StudentSubscriptionType.Dual)
            : studentsLocal.filter(s => s.subscriptionType === subscriptionType);
        
        if (classId) {
            isFutureClass(classId).then(res => {
                if (subscriptionType === StudentSubscriptionType.Dual && res
                    && studentsLocal.some(x => x.subscriptionType === StudentSubscriptionType.TextBook)) {
                        const textBookStudents = students.filter(x => x.subscriptionType === StudentSubscriptionType.TextBook);
                        setStudentFiltered([...filteredStudents, ...textBookStudents]);
                }
                else {
                    setStudentFiltered(filteredStudents);
                }
            });
        }
    }, [students]);

    return (
        <>
            <div className='students-container'>
                <Droppable droppableId={componentType + "-license-info"} type="PERSON">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef}
                            {...provided.droppableProps}>
                            <div className='students' id={componentType + "-license-info"}>
                                {studentFiltered && studentFiltered.map((student, index) => <Student key={student.id} index={index} {...student}
                                params={{ regionId, ...params }} classRoles={classRoles} canEditLicense={canEditLicense} students={students} componentType={componentType}
                                isDual={isDual} isSingle={isSingle} onLicenseChange={onLicenseChange} onRemoveStudent={onRemoveStudent} subTypeN={subTypeN} regionInfo={regionInfo}
                                subscriptionType={subscriptionType} licenseTypeN={licenseTypeN} counts={counts} disableFutureLicenseEdit={disableFutureLicenseEdit}/>)}
                            </div>
                            <div className={!(isDual || isSingle || licenseTypeN == CurriculumType.LittleSEED || !canEditLicense) ? 'show-student-drop-area' : 'hide-student-drop-area'}
                            >{fmtMsg({ id: SchoolLocale.EditClassDropStudentHere })}</div>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </>
    )
}
