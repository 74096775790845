import * as React from "react";
import { Component } from "react";
import { SubmitBtns, GLIcon } from "@app/components";
import {
    GLGlobal,
    GLForm,
    GLFormComponentProps,
    FormHelper,
    FormItemsLayout,
    alignPop,
    connect,
    GLLocale,
    ComparisonOperator,
    LanguageDateFormat,
    RoleName,
    MessageHelper,
    NotificationType
} from "gl-commonui";
import { StateType } from "@app/states";
import { SchoolLocale, GSAdminLocale } from "@app/locales/localeid";
import {
    Input,
    Checkbox,
    Row,
    Col,
    Select,
    DatePicker,
    InputNumber,
    Table,
    Icon,
    Modal,
    Radio,
    Card,
    Divider
} from "antd-min";
import { SchoolModel } from "@app/service/schools/models";
import {
    DaysOfWeek,
    CurriculumType,
    SchoolClassModel,
    SchoolClassTimeModel,
    SchoolClassService, SchoolClassTeacher
} from "@app/service/class";
import { FormattedMessage } from "react-intl";
import * as moment from "moment";
import {
    onSubmit,
    extendForm,
    textareaValider,
    guid,
    getTableProps,
    GSSchoolAction,
    SchoolClassTimeType,
    ClassHelper,
    SchoolClassTimeTypeText,
    lazyInject,
    TYPES,
    fmtMsg,
    rangeDateValider,
    CommonHelper,
    userIsInRoles,
    LicenseTypeValueNameMap,
    LicenseTypes,
    GSAdminAction,
    LicenseTypeNameValueMap,
} from "@app/util";
import Form from "antd/lib/form/Form";
import { getPromotion, getAccessibleCampus } from "@app/states/school/class";
import GLTimePicker from "@app/components/school/gl-timepicker/index";
import * as bowser from "bowser";
import { CampusModel, ICampusService } from "@app/service/school/campus";
import { IUserService } from "@app/service/users";
import TextArea from "antd/lib/input/TextArea";
import { ClassInternalNotes } from "@app/page/school/class/components/class-internal-notes";
import { WijmoGrid } from "@app/components/grid";
import "./form-view.less";
import { ITermManagerService, TermModel } from "@app/service/term-manager";
import { ISchoolService } from "@app/service/schools";
import { isUndefined } from "lodash";
import { ITagManagerService, TagEntity, TagListModel } from "@app/service/tag-manager";

const { Grid, Column } = WijmoGrid;

interface ClassFormProps {
    handleSubmit: (e) => void;
    isEdit?: boolean;
    isPromoteClass?: boolean;
    regionId?: string;
    school?: SchoolModel;
    campus?: CampusModel;
    model?: SchoolClassModel;
    classRoles?: string[];
    campusDisabled?: boolean;
    list?: SchoolClassModel[];
    getPromotion?: (d) => void;
    callback?: (d) => void;
    campusList?: CampusModel[];
    getAccessibleCampus?: (d) => void;
    enableSaveButton?: () => void;
    isClassManager?: boolean;
    annualPrepComplete?: boolean;
    isGrapeSEEDConnectVisible: boolean;
    classId?: string;
    schoolId?: string;
}

interface ClassTimeProps {
    isGrapeSEEDConnectVisible: boolean;
    licenseType: number;
}

interface ClassFormStates {
    isSelectedClass?: boolean;
    showModal?: any;
    day?: any;
    isGrapeSEED?: any;
    schoolClass?: SchoolClassModel;
    schoolClassTime?: any;
    isLessthan?: boolean;
    selectedStartUnit?: number;
    classTeachers?: SchoolClassTeacher[];
    editClassTime?: any;
    isSelectedTermWithEnforceDate?: boolean;
    existingTerms?: TermModel[]
    masterGrapeSeedLicenses?: number[],
    masterLittleSeedLicenses?: number[],
    selectedLicenseType?: number;
    prevSelectedLicenseType?: number; // only used for validation while creating new class
    tags?: TagListModel[];
    campusList?: CampusModel[];
    selectedTags?: TagListModel[];
    campusGrapeSeedLicenseList? : any[];
}

@GLForm.create()
@connect(
    ({ schoolClass: { list, campusList } }: StateType) => ({
        list,
        campusList
    }),
    {
        getPromotion,
        getAccessibleCampus
    }
)

export class ClassForm extends Component<
ClassFormProps & GLFormComponentProps,
ClassFormStates
> {
    @lazyInject(TYPES.ISchoolClassService)
    schoolClass: SchoolClassService;

    @lazyInject(TYPES.IUserService)
    userService: IUserService;

    @lazyInject(TYPES.ITermManagerService)
    termManagerService: ITermManagerService;

    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService;

    @lazyInject(TYPES.ITagManagerService)
    tagManagerService: ITagManagerService;

    @lazyInject(TYPES.ICampusService)
    campusService: ICampusService;

    name;
    term;
    age;
    curriculumType;
    startDate;
    selectedSourceCampusId?: string;
    selectedSourceSchoolClassId?: string;
    selectedSourceSchoolClassTerm?: string;
    selectedDestinationCampusId?: string;
    campusClassList?: SchoolClassModel[];
    selectedMainTeacher: string[] = [];
    selectedSubstituteTeachers: string[] = [];
    grapeSeedLicenseOptions = [];

    private promoteUnitPlans: any[];
    private classTimes: any[];

    static defaultProps = {
        isGrapeSEEDConnectVisible: false
    };

    constructor(context, props) {
        super(context, props);
        this.state = {
            isSelectedClass: false,
            selectedStartUnit: null,
            isSelectedTermWithEnforceDate: false,
            masterGrapeSeedLicenses: [],
            masterLittleSeedLicenses: [],
            tags: [],
            selectedTags: [],
            campusGrapeSeedLicenseList: [],
        };
        this.promoteUnitPlans = [];
        const { schoolId, campusId } = this.props.model;

        this.selectedSourceCampusId = campusId;
        this.selectedDestinationCampusId = campusId;
        this.props.getAccessibleCampus({ schoolId, disabled: false });
        if (this.props.isPromoteClass) this.loadClassList();
    }

    mergeLicenses = (campusLicenseList, localGSLicenses?: number[]) => {
        const {isEdit} = this.props;
        const licenses = isEdit ? localGSLicenses.filter(x => x == 1 || x == 2 || x == 4) : this.state.masterGrapeSeedLicenses.filter(x => x == 1 || x == 2 || x == 4);
        const mergedLicenses = [...licenses, ...campusLicenseList].sort();
        this.setState({masterGrapeSeedLicenses: mergedLicenses})
        this.grapeSeedLicenseOptions = [];
        mergedLicenses && this.grapeSeedLicenseOptions.push(CommonHelper.getLicenseTypeOptions(mergedLicenses));
        this.setState({campusGrapeSeedLicenseList: this.grapeSeedLicenseOptions})
    }

    fetchCampusLicenseTypes = async (schoolId: string, campusId: string, localGSLicenses?: number[]) => {
        await this.campusService.getCampusLicenseTypes(schoolId, campusId).then((response) => {
            if (response) {
                this.mergeLicenses(response, localGSLicenses)
            }
        }).catch((error) => {
            console.error('An error occurred while fetching data:', error);
        });
    }

    componentDidMount() {
        const { regionId, classId, isEdit, schoolId } = this.props;
        const schoolClassId = isEdit ? classId : undefined;
        let campusId = isEdit ? this.props.school.campusId: this.props.model.campusId;
        let localGSLicenses = [];

        this.termManagerService.getTermsForClass(regionId, schoolClassId).then(terms => {
            const { model } = this.props;

        this.schoolService.getSchoolLicenseTypes(schoolId).then(res => {
            localGSLicenses.push(...res.grapeSEEDLicenses)
            this.setState({ masterGrapeSeedLicenses: res.grapeSEEDLicenses })
            this.setState({ masterLittleSeedLicenses: res.littleSEEDLicenses })
                
            this.fetchCampusLicenseTypes(schoolId, campusId, localGSLicenses);
            this.tagManagerService.getTagsList(regionId, TagEntity.SchoolClass)
            .then(res => this.setState({tags: res}))

            if(classId) {
                this.tagManagerService.getEntityTags([classId], regionId).then(res => this.setState({selectedTags: res[0].tags}))
            }

            // default 'License Type' is 'Classic'
            if (!isEdit) {
                model.licenseType = LicenseTypes.Classic;
            }

                // disable StartDate end EndDate field in case model's term with enforceDate = true
                let isSelectedEnforceDate = false;
                if (model.termId) {
                    const selectedTerm = terms.find(t => t.id === model.termId);
                    isSelectedEnforceDate = selectedTerm && selectedTerm.enforceDate;
                }

                this.setState((prevState, props) => ({
                        ...prevState,
                        existingTerms: terms,
                        isSelectedTermWithEnforceDate: isSelectedEnforceDate
                }))
            })
        }) 
    }

    componentWillReceiveProps(nextProps) {
        nextProps.model.curriculumType !== this.props.model.curriculumType &&
            this.setState({
                isGrapeSEED: nextProps.isPromoteClass ? true : isGrapeSEED(nextProps.model.curriculumType)
            });
    }

    showModal(day?) {
        this.setState({ showModal: true, day });
    }

    hideModal() {
        this.setState({ showModal: false, day: {}, editClassTime: undefined });
    }

    renderOption(item, index) {
        return (
            <Select.Option key={index} value={item.id} title={item.name}>
                {item.name}
            </Select.Option>
        );
    }

    async callCampusLicenseType(campusId: string){
        await this.campusService.getCampusLicenseTypes(this.props.schoolId, campusId).then((response) => {
            if (response) {
                this.mergeLicenses(response)
            }
        }).catch(() => {

        });
    }

    onSourceCampusSelected(value) {
        const { form } = this.props;
        let fieldValues = form.getFieldsValue(["isPromotion"]);
        form.resetFields();
        form.setFieldsValue(fieldValues);
        this.selectedSourceCampusId = value;
        this.loadClassList();
        this.onFormChange();
    }
    onDestinationCampusSelected(value) {
        this.selectedDestinationCampusId = value;
        this.callCampusLicenseType(value);
        this.props.form.setFieldsValue({ licenseType: null })
        this.setState({ selectedLicenseType: null })
        this.onFormChange();
    }

    loadClassList() {
        this.selectedSourceSchoolClassId = null;
        this.selectedSourceSchoolClassTerm = null;
        this.campusClassList = [];
        if (this.selectedSourceCampusId) {
            this.schoolClass
                .getPromotion({
                    schoolId: this.props.model.schoolId,
                    campusId: this.selectedSourceCampusId
                })
                .then(data => {
                    this.campusClassList = data;
                    if (this.campusClassList && this.campusClassList.length) {
                        this.selectedSourceSchoolClassId = this.campusClassList[0].id;
                        this.selectedSourceSchoolClassTerm = this.campusClassList[0].term;
                        this.onSelected(this.selectedSourceSchoolClassId);
                    } else {
                        this.setState({ classTeachers: [] });
                    }
                    this.setState({});
                })
                .catch(er => {
                    this.setState({});
                });
        } else {
            this.setState({});
        }
    }

    // on class selected
    onSelected(value) {
        if (value) {
            this.getTeachers(value);
            this.selectedSourceSchoolClassId = value;
            this.campusClassList &&
                this.campusClassList.forEach((v, k) => {
                    if (v.id == value) {
                        const {
                            model: { schoolId },
                            form,
                            isPromoteClass
                        } = this.props;
                        const actions = [
                            this.schoolClass.get({ schoolId, id: value }),
                            this.schoolClass.getUnitPlans(schoolId, value)
                        ];
                        Promise.all(actions).then(res => {
                            const [data, { unitPlan }] = res;
                            this.promoteUnitPlans = unitPlan;
                            this.selectedSourceSchoolClassTerm = data.term;
                            let fieldValues = form.getFieldsValue([
                                "isPromotion",
                                "selectionSchoolClassId"
                            ]);
                            form.resetFields();
                            form.setFieldsValue(fieldValues);
                            form.setFieldsValue({ licenseType: this.state.masterGrapeSeedLicenses[0] })
                            this.setState({ selectedLicenseType: this.state.masterGrapeSeedLicenses[0] })
                            let tempData = data;
                            if (data && data.schoolClassTime) {
                                const days = GLGlobal.intl
                                    .formatMessage({
                                        id: SchoolLocale.ClassWeekdays
                                    })
                                    .split(",")
                                    .map(day => day.trim());
                                tempData = mapClassDayTime(data, days);
                            }
                            this.setState({
                                isSelectedClass: true,
                                isGrapeSEED: isPromoteClass ? true : isGrapeSEED(data.curriculumType),
                                schoolClass: tempData,
                                schoolClassTime: tempData.schoolClassTime
                            });
                            this.setClassField(tempData);
                        });
                    }
                });
        } else {
            this.selectedSourceSchoolClassId = null;
            this.selectedSourceSchoolClassTerm = null;
        }
        this.onFormChange();
    }

    onSelectedDate(date, datestring) {
        this.setState({
            isLessthan:
                moment(date).format("YYYY-MM-DD") <
                moment().format("YYYY-MM-DD")
        });
        this.onFormChange();
    }

    onSelectedTerm(termId) {
        if (!termId) {
            this.setState({
                isSelectedTermWithEnforceDate: false
            });
            this.onFormChange();
            return;
        }

        this.term = termId;

        const { form } = this.props;
        const { setFieldsValue, getFieldsValue } = form;
        const selectedTerm = this.state.existingTerms.find(t => t.id === termId);
        const isApplyEnforceDate = selectedTerm.enforceDate ? true : false;

        if (selectedTerm.startDate) {
            setFieldsValue({
                startDate: moment.utc(selectedTerm.startDate)
            })
            this.validClassField(
                selectedTerm.startDate,
                "startDate"
            );
        }
        if (selectedTerm.endDate)
            setFieldsValue({
                endDate: moment.utc(selectedTerm.endDate)
            })

        this.setState({
            isSelectedTermWithEnforceDate: isApplyEnforceDate
        });

        this.onFormChange();
    }

    termFilter = (input, option) => {
        const termName = option.props.children as string;
        return termName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    onSelectMainTeacher = (checkedTeacher) => {
        this.selectedMainTeacher = checkedTeacher;
    }

    onSelectSubstituteTeachers = (checkedTeachers) => {
        this.selectedSubstituteTeachers = checkedTeachers;
    }

    handleSubmitClass = (data) => {
        const { handleSubmit } = this.props;
        data.mainTeachers = this.selectedMainTeacher;
        data.substituteTeachers = this.selectedSubstituteTeachers;
        if (!Number.isInteger(data.licenseType)) {
            data.licenseType = LicenseTypeNameValueMap[data.licenseType]
        }
        handleSubmit(data);
    }

    onSubmit(e) {
        const { isPromoteClass } = this.props;
        if (isPromoteClass && this.state.isLessthan) {
            Modal.confirm({
                title: fmtMsg(SchoolLocale.ClassNewStartDateTip),
                okText: fmtMsg(GLLocale.Ok),
                cancelText: fmtMsg(GLLocale.Cancel),
                onOk: () => {
                    onSubmit(this, this.handleSubmitClass, format)(e);
                }
            });
        } else {
            onSubmit(this, this.handleSubmitClass, format)(e);
        }
    }
    setClassField(model: SchoolClassModel) {
        this.age = model.age;
        this.curriculumType = model.curriculumType;
    }
    getDefaultTerm4PromptClass(model: SchoolClassModel) {
        return null;
        // const sourceTerm = model.term;
        // if (sourceTerm === undefined) return undefined;
        // if (sourceTerm === null || sourceTerm.trim() === "") {
        //    return (new Date(model.startDate)).getFullYear() + 1;
        // }
        // else {
        //    return Number.isInteger(Number(sourceTerm)) ? `${Number(sourceTerm) + 1}` : sourceTerm;
        // }
    }
    validClassField(e, field) {
        const { callback, form } = this.props;
        switch (field) {
            case "name":
                this.name = e.target.value;
                break;
            case "term":
                this.term = e.target.value;
                break;
            case "age":
                this.age = e.target.value;
                break;
            case "curriculumType":
                this.curriculumType = e;
                break;
            case "startDate":
                this.startDate = e;
                break;
        }
        if (
            this.name &&
            this.age &&
            this.curriculumType != null &&
            this.startDate
        ) {
            callback && callback(false);
        } else {
            callback && callback(true);
        }
    }

    onFormChange = () => {
        this.props.enableSaveButton && this.props.enableSaveButton();
    }

    validateLicenseType(rule, value, callback) {
        const { model: propsModel, form, isEdit, isPromoteClass } = this.props

        const model = isPromoteClass ? { ...propsModel, ...this.state.schoolClass } : propsModel;
        let mergedSchoolClassTime = (this.state && this.state.schoolClassTime) || propsModel.schoolClassTime;

        if (isPromoteClass || !isEdit) {
            propsModel.schoolClassTime = mergedSchoolClassTime;
        }

        if (isEdit || isPromoteClass) {
            const originalLicenseType = model.licenseType;
            const selectedLicenseType = value;

            const isInitalLicenseTypeOfConnect = this.isLicenseTypeConnect(originalLicenseType);
            const isUpdatedLicenseTypeConnect = this.isLicenseTypeConnect(selectedLicenseType);    
            
            if (isInitalLicenseTypeOfConnect) {
                // if originally license type of class is of Connect type and changed license type is also of Connect type
                if (isInitalLicenseTypeOfConnect && isUpdatedLicenseTypeConnect) {
                    this.setState({ selectedLicenseType: selectedLicenseType })
                    callback();
                    return;
                }

                const hasConnectClassTime = mergedSchoolClassTime && mergedSchoolClassTime.some(sct => sct.type === SchoolClassTimeType.GrapeSEEDConnect);
                if (hasConnectClassTime) {
                    MessageHelper.Message(
                        NotificationType.Warning,
                        fmtMsg({ id: SchoolLocale.ClassValidConnectClassTime })
                    );
                    form.setFieldsValue({ licenseType: originalLicenseType });
                    this.setState({ selectedLicenseType: originalLicenseType })
                    return;
                } else {
                    form.setFieldsValue({ licenseType: selectedLicenseType });
                    this.setState({ selectedLicenseType: selectedLicenseType })
                    callback();
                    return;
                }
            } 
            else {
                const hasConnectClassTime = mergedSchoolClassTime && mergedSchoolClassTime.some(sct => sct.type === SchoolClassTimeType.GrapeSEEDConnect);
                if (hasConnectClassTime) {
                    if (isUpdatedLicenseTypeConnect) {
                        form.setFieldsValue({ licenseType: selectedLicenseType });
                        this.setState({ selectedLicenseType: selectedLicenseType })
                        return;
                    }
                    MessageHelper.Message(
                        NotificationType.Warning,
                        fmtMsg({ id: SchoolLocale.ClassValidNonConnectClassTime })
                    );
                    mergedSchoolClassTime = mergedSchoolClassTime && mergedSchoolClassTime.filter(sct => sct.type !== SchoolClassTimeType.GrapeSEEDConnect);
                    form.setFieldsValue({ licenseType: originalLicenseType });
                    this.setState({ selectedLicenseType: originalLicenseType, schoolClassTime: mergedSchoolClassTime })
                    return;
                }
                this.setState({ selectedLicenseType: selectedLicenseType })
                callback();
                return;
            }
        }

        if (!isPromoteClass || !isEdit) {
            const selectedLicenseType = value;
            const isUpdatedLicenseTypeConnect = this.isLicenseTypeConnect(selectedLicenseType);

            if (isUpdatedLicenseTypeConnect) {
                this.setState({ selectedLicenseType: selectedLicenseType, prevSelectedLicenseType: selectedLicenseType })
                callback();
                return;
            }

            const hasConnectClassTime = mergedSchoolClassTime && mergedSchoolClassTime.some(sct => sct.type === SchoolClassTimeType.GrapeSEEDConnect);
            if (hasConnectClassTime) {
                MessageHelper.Message(
                    NotificationType.Warning,
                    fmtMsg({ id: SchoolLocale.ClassValidConnectClassTime })
                );
                form.setFieldsValue({ licenseType: this.state.prevSelectedLicenseType });
                this.setState({ selectedLicenseType: this.state.prevSelectedLicenseType }) 
                return;
            } else {
                form.setFieldsValue({ licenseType: selectedLicenseType });
                this.setState({ selectedLicenseType: selectedLicenseType })
                callback();
                return;
            }
        }
    }

    isLicenseTypeConnect = (licenseType) => {
        return licenseType === LicenseTypes.Connect || licenseType === LicenseTypes.ClassicConnectHybrid || licenseType === LicenseTypes.ConnectNexusHybrid;
    }

    getLicenseType() {
        const {isEdit, isPromoteClass, model: propsModel} = this.props;
        const model = isPromoteClass ? { ...propsModel, ...this.state.schoolClass }: propsModel;

        if (isEdit || isPromoteClass) {
            if (isUndefined(this.state.selectedLicenseType)) {
                return model.licenseType
            } else {
                this.state.selectedLicenseType;
            }
        }

        return this.state.selectedLicenseType;
    }

    getTeachers = (classId) => {
        if (!classId) {
            this.setState({ classTeachers: [] })
            return;
        }

        this.schoolClass.getTeachersBy(null, {
            id: classId,
            schoolId: this.props.model.schoolId
        }).then(data => {
            if (data && data.length) {
                this.userService.getUsersByPost({ ids: data.map(u => u.id), disabled: false }).then(users => {
                    const teachers: SchoolClassTeacher[] = data.reduce((acc, item) => {
                        const selectedItem = users.data.find(ud => ud.id === item.id);
                        if (selectedItem) {
                            acc.push({
                                id: selectedItem.id,
                                name: selectedItem.name,
                                substitute: !item.isPrimary
                            });
                        }

                        return acc;
                    }, []);

                    this.setState({ classTeachers: teachers });
                });
            } else {
                this.setState({ classTeachers: [] })
            }
        }).catch(err => {
            this.setState({ classTeachers: [] })
        });
    }

    futureClass = () => {
        const { model: propsModel } = this.props;
        let isFuture = false;
        const startDateClass = moment(propsModel.startDate).format("YYYY-MM-DD");
        const today = moment().format("YYYY-MM-DD");
        isFuture = moment(startDateClass).isAfter(today);
        return isFuture;
    }

    renderTeachersFormToPromote() {
        if (!this.state || !this.state.classTeachers || !this.state.classTeachers.length) {
            return "";
        }

        const substituteTeachers = [];
        const mainTeachers = [];
        this.state.classTeachers.map(t => {
            const checkBoxItem = {
                label: t.name,
                value: t.id
            }
            if (t.substitute) {
                substituteTeachers.push(checkBoxItem);
            } else {
                mainTeachers.push(checkBoxItem);
            }
        });
        return (
            <Card title={fmtMsg(SchoolLocale.ClassPromoteSelectTeacher)} size="small" type="inner" className="form-box">
                <Row>
                    {mainTeachers && mainTeachers.length > 0 &&
                        <>
                            <Col><label>{fmtMsg(SchoolLocale.ClassMainTeacher)}</label></Col>
                            <Col className="mt-15 mb-15">
                                <Checkbox.Group options={mainTeachers} onChange={this.onSelectMainTeacher} />
                            </Col>
                        </>
                    }
                    {substituteTeachers && substituteTeachers.length > 0 &&
                        <>
                            <Col>
                                <label>{fmtMsg(SchoolLocale.ClassSubstituteTeachers)}</label>
                            </Col>
                            <Col>
                                <Checkbox.Group options={substituteTeachers} onChange={this.onSelectSubstituteTeachers} />
                            </Col>
                        </>
                    }
                </Row>
            </Card>
        )
    }

    render() {
        const {
            isEdit,
            isPromoteClass,
            form,
            school,
            model: propsModel,
            children,
            classRoles,
            campusDisabled,
            campusList,
            annualPrepComplete
        } = this.props;
        const model = isPromoteClass
            ? { ...propsModel, ...this.state.schoolClass }
            : propsModel;
        const mergedSchoolClassTime =
            (this.state && this.state.schoolClassTime) ||
            propsModel.schoolClassTime;
        if (isPromoteClass || !isEdit) {
            propsModel.schoolClassTime = mergedSchoolClassTime;
        }
        const renderFormItem = FormHelper.renderFormItem;
        const options = {
            formItemProps: { label: null },
            decoratorOptions: { valuePropName: "checked" }
        };
        const schoolclassOptions = [];
        this.campusClassList &&
            this.campusClassList.forEach((item, index) => {
                if (!item.disabled) {
                    schoolclassOptions.push(this.renderOption(item, index));
                }
            });
        const campusOptions = [];
        campusList &&
            campusList.forEach((item, index) => {
                if (!item.disabled) {
                    campusOptions.push(this.renderOption(item, index));
                }
            });


        const littleSeedLicenseOptions = [];
        this.state.masterLittleSeedLicenses && littleSeedLicenseOptions.push(CommonHelper.getLicenseTypeOptions(this.state.masterLittleSeedLicenses));

        const isCurriculumTypeSelected = (form) => {
            const value = form.getFieldValue("curriculumType")
            return isUndefined(value) ? false : true;
        }

        const isLicenseTypePresentInMasterList = (licenseType) => {
            return this.state.masterGrapeSeedLicenses.some(gs => gs == licenseType);
        }

        const startUnit =
            getGrapeSEED(this) != null
                ? model.startUnit
                    ? model.startUnit
                    : getGrapeSEED(this)
                        ? 1
                        : -1
                : null;
        const maxUnit =
            getGrapeSEED(this) != null
                ? model.maxUnit
                    ? model.maxUnit
                    : getGrapeSEED(this)
                        ? 1
                        : -1
                : null;
        const classDisabled =
            campusDisabled ||
            !ClassHelper.canEditLicense(
                classRoles,
                school.allowSchoolEditLicense
            ) || (ClassHelper.canEditRequiredFieldAnnualPrepComplete(annualPrepComplete) && this.futureClass());
        const dateValider = msg =>
            rangeDateValider(form, "startDate", "endDate", msg);
        const isPromoteUnit =
            isPromoteClass &&
            this.state.schoolClass &&
            this.state.schoolClass.curriculumType == "0";
        const isPromoteLS2GSUnit =
            isPromoteClass &&
            this.state.schoolClass &&
            this.state.schoolClass.curriculumType == "1" &&
            this.state.isGrapeSEED;
        const minUnit4StartUnit = isPromoteUnit ? model.maxUnit : null;
        const maxUnit4StartUnit = isPromoteUnit
            ? Math.min(minUnit4StartUnit + 1, school.maxUnit)
            : null;
        const mergedMaxUnit4StartUnit = isPromoteUnit
            ? (this.state && this.state.selectedStartUnit) || maxUnit4StartUnit
            : null;
        const minUnit4EndUnit = isPromoteLS2GSUnit ? 1 : minUnit4StartUnit;
        const setPromoteUnitPlan = (isPromoteUnit, unit, needSetState?) => {
            if (isPromoteUnit && unit == this.state.schoolClass.maxUnit) {
                const maxUnitPlan = this.promoteUnitPlans.find(
                    up => up.unit == unit
                );
                propsModel.unitPlanStartDate =
                    maxUnitPlan && maxUnitPlan.startDate
                        ? maxUnitPlan.startDate
                        : null;
                needSetState && this.setState({ selectedStartUnit: unit });
            } else {
                propsModel.unitPlanStartDate = null;
                needSetState && this.setState({ selectedStartUnit: null });
            }
        };
        const gsMaxUnit = school.maxUnit ? school.maxUnit : 0;
        const lsMaxUnit = school.lsMaxUnit ? school.lsMaxUnit : 0;

        setPromoteUnitPlan(isPromoteUnit, mergedMaxUnit4StartUnit);

        const isInternalUser = userIsInRoles(RoleName.systemAdmin, RoleName.globalHead,
            RoleName.trainingAdmin, RoleName.trainingManager,
            RoleName.regionAdmin, RoleName.accountManager, RoleName.trainer);

        const hasTagManagerPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsManage);
        const tags = this.state.tags;

        const renderLicenseType = () => {
            return (
                <>
                {!isEdit &&
                renderFormItem(
                    form,
                    SchoolLocale.ClassCurriculumType,
                    "curriculumType",
                    <LicenseTypeSelect
                        isEdit={isPromoteUnit || (ClassHelper.canEditRequiredFieldAnnualPrepComplete(annualPrepComplete) && this.futureClass())} //the control use this props to disabled component?
                        onChange={d => {
                            form.resetFields([
                                "startUnit",
                                "maxUnit"
                            ]);
                            form.setFieldsValue({ licenseType: isGrapeSEED(d) ? this.state.masterGrapeSeedLicenses[0] : this.state.masterLittleSeedLicenses[0] });
                            if (
                                this.props.isPromoteClass &&
                                this.state.schoolClass
                                    .curriculumType != d
                            ) {
                                this.setState({
                                    schoolClass: {
                                        ...this.state.schoolClass,
                                        startUnit: null,
                                        maxUnit: null,
                                        licenseType: isGrapeSEED(d) ? this.state.masterGrapeSeedLicenses[0] : this.state.masterLittleSeedLicenses[0],
                                    }
                                });
                            }
                            this.setState({
                                isGrapeSEED: isGrapeSEED(d)
                            });
                            this.validClassField(d, "curriculumType");
                        }}
                        isGSHidden={gsMaxUnit == 0}
                        isLSHidden={lsMaxUnit == 0 || isPromoteClass}
                    />,
                    isPromoteClass ? (gsMaxUnit == 0 ? null : CurriculumType.GrapeSEED) : model.curriculumType,
                    true
                )}
                {isEdit &&
                renderFormItem(
                    form,
                    SchoolLocale.ClassCurriculumType,
                    "curriculumType",
                    <LicenseTypeSelect
                        isEdit={model.curriculumType !== undefined || (ClassHelper.canEditRequiredFieldAnnualPrepComplete(annualPrepComplete) && this.futureClass())}
                        onChange={d => {
                            form.resetFields([
                                "startUnit",
                                "maxUnit"
                            ]);
                            this.setState({
                                isGrapeSEED: isGrapeSEED(d)
                            });
                            this.onFormChange();
                        }}
                        isGSHidden={gsMaxUnit == 0}
                        isLSHidden={lsMaxUnit == 0}
                    />,
                    model.curriculumType,
                    true
                )}
                </>
            )};

        return (
            <>
                <GLForm
                    onChange={this.onFormChange}
                    form={form}
                    onSubmit={e => {
                        e.preventDefault();
                        this.onSubmit(e);
                    }}
                >
                    {isPromoteClass && (
                        <>
                            <Row
                                type="flex"
                                gutter={50}
                                className="promote-class-wrapper"
                            >
                                <Col xs={24} md={12}>
                                    <Card
                                        title={fmtMsg({
                                            id: SchoolLocale.ClassNewSource
                                        })}
                                        size="small"
                                        type="inner"
                                        className="promote-class-wrapper"
                                    >
                                        {renderFormItem(
                                            form,
                                            SchoolLocale.ClassNewSourceCampusSelect,
                                            "selectionSourceCampusId",
                                            <Select {...alignPop()} onChange={this.onSourceCampusSelected.bind(this)}>
                                                {campusOptions}
                                            </Select>,
                                            campusList && campusList.length
                                                ? this.selectedSourceCampusId
                                                : null,
                                            true,
                                            [
                                                {
                                                    required: true,
                                                    message: GLGlobal.intl.formatMessage(
                                                        {
                                                            id:
                                                                SchoolLocale.ClassNewSelectSourceCampusTip
                                                        }
                                                    )
                                                }
                                            ]                                            
                                        )}
                                        {renderFormItem(
                                            form,
                                            SchoolLocale.ClassNewSourceClassSelect,
                                            "selectionSchoolClassId",
                                            <Select
                                                {...alignPop()}
                                                onChange={this.onSelected.bind(
                                                    this
                                                )}
                                            >
                                                {schoolclassOptions}
                                            </Select>,
                                            this.campusClassList &&
                                                this.campusClassList.length
                                                ? this
                                                    .selectedSourceSchoolClassId
                                                : null,
                                            true,
                                            [
                                                {
                                                    required: true,
                                                    message: GLGlobal.intl.formatMessage(
                                                        {
                                                            id:
                                                                SchoolLocale.ClassNewSelectSourceClassTip
                                                        }
                                                    )
                                                }
                                            ]
                                        )}
                                    </Card>

                                    {this.renderTeachersFormToPromote()}

                                </Col>
                                <Col xs={24} md={12}>
                                    <Card
                                        title={fmtMsg({
                                            id: SchoolLocale.ClassNewDestination
                                        })}
                                        size="small"
                                        type="inner"
                                    >
                                        {renderFormItem(
                                            form,
                                            SchoolLocale.ClassNewDestinationCampusSelect,
                                            "destinationCampusId",
                                            <Select
                                                {...alignPop()}
                                                onChange={this.onDestinationCampusSelected.bind(
                                                    this
                                                )}
                                            >
                                                {campusOptions}
                                            </Select>,
                                            campusList && campusList.length
                                                ? this
                                                    .selectedDestinationCampusId
                                                : null,
                                            true,
                                            [
                                                {
                                                    required: true,
                                                    message: GLGlobal.intl.formatMessage(
                                                        {
                                                            id:
                                                                SchoolLocale.ClassNewSelectDestinationCampusTip
                                                        }
                                                    )
                                                }
                                            ]
                                        )}
                                        {renderFormItem(
                                            form,
                                            SchoolLocale.ClassNewDestinationClassSelect,
                                            "name",
                                            <Input
                                                size="small"
                                                style={{
                                                    width: "100%",
                                                    height: 32
                                                }}
                                                onChange={e =>
                                                    this.validClassField(
                                                        e,
                                                        "name"
                                                    )
                                                }
                                                disabled={ClassHelper.canEditRequiredFieldAnnualPrepComplete(annualPrepComplete) && this.futureClass()}
                                            />,
                                            null,
                                            true
                                        )}
                                    </Card>
                                </Col>
                            </Row>

                            {!isEdit && (
                                <div className="hidden-section">
                                    {renderFormItem(
                                        {
                                            ...form,
                                            ...{
                                                formItemProps: { label: null }
                                            }
                                        },
                                        SchoolLocale.ClassNewStudentCount,
                                        "studentCount",
                                        <Input type="hidden" />,
                                        model.studentCount,
                                        false
                                    )}
                                    {renderFormItem(
                                        {
                                            ...form,
                                            ...{
                                                formItemProps: { label: null }
                                            }
                                        },
                                        SchoolLocale.ClassNewPromoteExisting,
                                        "isPromotion",
                                        <Input type="hidden" />,
                                        isPromoteClass,
                                        false
                                    )}
                                </div>
                            )}
                        </>
                    )}
                    {!isPromoteClass && (
                        <FormItemsLayout colTotal={2}>
                            {renderFormItem(
                                form,
                                SchoolLocale.ClassName,
                                "name",
                                <Input
                                    onChange={e =>
                                        this.validClassField(e, "name")
                                    }
                                    disabled={ClassHelper.canEditRequiredFieldAnnualPrepComplete(annualPrepComplete) && this.futureClass()}
                                />,
                                isEdit ? model.name : null,
                                true
                            )}
                            {renderLicenseType()}
                        </FormItemsLayout>
                    )}
                    {isEdit ?
                        <FormItemsLayout colTotal={2}>
                            {renderFormItem(
                                form,
                                SchoolLocale.ClassLicenseType,
                                "licenseType",
                                <Select
                                    size="large"
                                    onChange={this.onFormChange}
                                >
                                    { this.state.isGrapeSEED ? this.state.campusGrapeSeedLicenseList : littleSeedLicenseOptions }
                                </Select>,
                                isLicenseTypePresentInMasterList(model.licenseType) ? model.licenseType : LicenseTypeValueNameMap[model.licenseType],
                                true,
                                [{ validator: this.validateLicenseType.bind(this) }]
                            )}
                            {( hasTagManagerPermission && renderFormItem(
                                form,
                                GSAdminLocale.SchoolModelTags,
                                "tags",
                                <Select
                                    size="large"
                                    {...alignPop()}
                                    onChange={this.onFormChange}
                                    mode="multiple"
                                    maxTagCount={2}
                                    optionFilterProp="children"
                                >
                                    {tags.map(tag => (
                                        <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                    ))}
                                </Select>,
                                this.state.selectedTags.map((x) => x.id) || [],
                                false,
                            []
                            )
                            )}
                        </FormItemsLayout>
                        :
                        !isPromoteClass && <FormItemsLayout colTotal={2}>
                            {renderFormItem(
                                form,
                                SchoolLocale.ClassLicenseType,
                                "licenseType",
                                <Select
                                    size="large"
                                    onChange={ e => { 
                                        form.setFieldsValue({ licenseType: e })
                                        this.setState({ selectedLicenseType: form.getFieldValue("licenseType") })
                                    }}
                                >
                                    { isCurriculumTypeSelected(form) ? this.state.isGrapeSEED ? this.state.campusGrapeSeedLicenseList : littleSeedLicenseOptions : "" }
                                </Select>,
                                "",
                                true,
                                [{ validator: this.validateLicenseType.bind(this) }]
                            )}
                            {!isEdit && !isPromoteClass && hasTagManagerPermission && ( renderFormItem(
                                form,
                                GSAdminLocale.SchoolModelTags,
                                "tags",
                                <Select
                                    size="large"
                                    {...alignPop()}
                                    onChange={this.onFormChange}
                                    mode="multiple"
                                    maxTagCount={2}
                                    optionFilterProp="children"
                                >
                                    {tags.map(tag => (
                                        <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                    ))}
                                </Select>,
                                this.state.selectedTags.map((x) => x.id) || [],
                                false,
                                []
                            )
                            )}
                        </FormItemsLayout>
                    }
                    <FormItemsLayout colTotal={2}>
                        {isPromoteClass && renderLicenseType()}
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassAge,
                            "age",
                            <Input
                                onChange={e => this.validClassField(e, "age")}
                                disabled={ClassHelper.canEditRequiredFieldAnnualPrepComplete(annualPrepComplete) && this.futureClass()}
                            />,
                            model.age,
                            true,
                            [
                                FormHelper.ruleForCompareLength(
                                    SchoolLocale.ClassAge,
                                    ComparisonOperator.LessOrEqualsThan,
                                    30
                                )
                            ]
                        )}
                        {!isPromoteClass && renderFormItem(
                                form,
                                SchoolLocale.ClassTerm,
                                "term",
                                <Select className="sc-term" showSearch={true} filterOption={this.termFilter} onChange={e => this.onSelectedTerm(e)} allowClear={true}>
                                    {
                                        this.state.existingTerms && this.state.existingTerms.map(t => (
                                            <Select.Option value={t.id} key={t.id}>{t.name}</Select.Option>
                                        ))
                                    }
                                </Select>,
                                isEdit ? model.termId : null,
                                false,
                                []
                            )}
                    </FormItemsLayout>
                    {isPromoteClass && (
                        <FormItemsLayout colTotal={2}>
                            {renderFormItem(
                                form,
                                SchoolLocale.ClassTerm,
                                "term",
                                <Select className="sc-term" showSearch={true} filterOption={this.termFilter} onChange={e => this.onSelectedTerm(e)} allowClear={true}>
                                    {
                                        this.state.existingTerms && this.state.existingTerms.map(t => (
                                            <Select.Option value={t.id} key={t.id}>{t.name}</Select.Option>
                                        ))
                                    }
                                </Select>,
                                this.getDefaultTerm4PromptClass(model),
                                false,
                                []
                            )}
                            {renderFormItem(
                                form,
                                SchoolLocale.ClassLicenseType,
                                "licenseType",
                                <Select
                                    size="large"
                                    onChange={ e => { 
                                        form.setFieldsValue({ licenseType: e })
                                        this.setState({ selectedLicenseType: form.getFieldValue("licenseType") })
                                    }}
                                >
                                    { this.state.isGrapeSEED ? this.state.campusGrapeSeedLicenseList : littleSeedLicenseOptions }
                                </Select>,
                                this.state.isGrapeSEED ? LicenseTypeValueNameMap[this.state.campusGrapeSeedLicenseList[0]] : LicenseTypeValueNameMap[this.state.masterLittleSeedLicenses[0]],
                                true,
                                [{ validator: this.validateLicenseType.bind(this) }],
                            )}
                        </FormItemsLayout>
                    )}
                    <FormItemsLayout colTotal={2}>
                        {!isEdit &&
                            renderFormItem(
                                form,
                                SchoolLocale.ClassNewStartDate,
                                "startDate",
                                <DatePicker
                                    {...alignPop({ type: "DatePicker" })}
                                    format={
                                        LanguageDateFormat[GLGlobal.intl.locale]
                                    }
                                    onChange={(date, datestring) => {
                                        this.onSelectedDate(date, datestring);
                                        this.validClassField(
                                            datestring,
                                            "startDate"
                                        );
                                    }}
                                    disabled={ClassHelper.canEditRequiredFieldAnnualPrepComplete(annualPrepComplete) && this.futureClass() || this.state.isSelectedTermWithEnforceDate}
                                />,
                                null,
                                true,
                                dateValider(
                                    fmtMsg({
                                        id:
                                            GSAdminLocale.NotificationCreateEarlierDate
                                    })
                                )
                            )}
                        {isEdit &&
                            renderFormItem(
                                form,
                                SchoolLocale.ClassNewStartDate,
                                "startDate",
                                <DatePicker
                                    {...alignPop({ type: "DatePicker" })}
                                    format={
                                        LanguageDateFormat[GLGlobal.intl.locale]
                                    }
                                    onChange={(date, datestring) =>
                                        this.onSelectedDate(date, datestring)
                                    }
                                    disabled={(ClassHelper.canEditRequiredFieldAnnualPrepComplete(annualPrepComplete) && this.futureClass()) || this.state.isSelectedTermWithEnforceDate}
                                />,
                                model.startDate
                                    ? moment(model.startDate)
                                    : null,
                                true,
                                dateValider(
                                    fmtMsg({
                                        id:
                                            GSAdminLocale.NotificationCreateLaterDate
                                    })
                                )
                            )}
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassNewEndDate,
                            "endDate",
                            <DatePicker
                                {...alignPop({ type: "DatePicker" })}
                                format={
                                    LanguageDateFormat[GLGlobal.intl.locale]
                                }
                                onChange={this.onFormChange}
                                disabled={this.state.isSelectedTermWithEnforceDate}
                            />,
                            isEdit && model.endDate
                                ? moment(model.endDate)
                                : null,
                            false,
                            dateValider(
                                fmtMsg({
                                    id:
                                        GSAdminLocale.NotificationCreateLaterDate
                                })
                            )
                        )}
                    </FormItemsLayout>
                    <FormItemsLayout colTotal={2}>
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassStartUnit,
                            "startUnit",
                            <UnitsSelect
                                isGrapeSEED={getGrapeSEED(this)}
                                disabled={
                                    !ClassHelper.canEditUnit(
                                        GSSchoolAction.EditClassStartUnit,
                                        classRoles,
                                        school.allowSchoolEditUnit,
                                        isEdit
                                    ) || (ClassHelper.canEditRequiredFieldAnnualPrepComplete(annualPrepComplete) && this.futureClass())
                                }
                                isPromote={isPromoteUnit || isPromoteLS2GSUnit}
                                minUnit4Promote={
                                    isPromoteLS2GSUnit ? 1 : minUnit4StartUnit
                                }
                                maxUnit4Promote={
                                    isPromoteLS2GSUnit ? 1 : maxUnit4StartUnit
                                }
                                onChange={unit => {
                                    const { isPromoteClass } = this.props;
                                    const isPromoteUnit =
                                        isPromoteClass &&
                                        this.state.schoolClass &&
                                        this.state.schoolClass.curriculumType ==
                                        "0";
                                    setPromoteUnitPlan(
                                        isPromoteUnit,
                                        unit,
                                        true
                                    );
                                    this.onFormChange();
                                }}
                            />,
                            isPromoteUnit ? maxUnit4StartUnit : (isPromoteLS2GSUnit ? 1 : startUnit),
                            true,
                            unitValider(
                                form,
                                SchoolLocale.ClassUnitValid,
                                getGrapeSEED(this) ? school.maxUnitsPerYear : 0
                            )
                        )}
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassMaxUnit,
                            "maxUnit",
                            <UnitsSelect
                                isGrapeSEED={getGrapeSEED(this)}
                                disabled={
                                    !ClassHelper.canEditUnit(
                                        GSSchoolAction.EditClassMaxUnit,
                                        classRoles,
                                        school.allowSchoolEditUnit,
                                        isEdit
                                    ) || (ClassHelper.canEditRequiredFieldAnnualPrepComplete(annualPrepComplete) && this.futureClass())
                                }
                                isPromote={isPromoteUnit || isPromoteLS2GSUnit}
                                minUnit4Promote={minUnit4EndUnit}
                                onChange={this.onFormChange}
                            />,
                            isPromoteUnit ? maxUnit4StartUnit : (isPromoteLS2GSUnit ? 1 : maxUnit),
                            true,
                            unitValider(
                                form,
                                SchoolLocale.ClassUnitValidMax,
                                getGrapeSEED(this) ? school.maxUnitsPerYear : 0
                            )
                        )}
                    </FormItemsLayout>
                    {/* <FormItemsLayout colTotal={2}>
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassLessonsPerWeek,
                            "lessonsPerWeek",
                            <InputNumber size="large" min={1} max={7} precision={0} />,
                            model.lessonsPerWeek,
                            true
                        )}
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassLessonsPerYear,
                            "lessonsPerYear",
                            <InputNumber size="large" min={1} max={999} precision={0} />,
                            model.lessonsPerYear
                        )}
                    </FormItemsLayout> */}

                    {/* {renderFormItem(form, SchoolLocale.ClassDaysofWeek, 'daysOfWeek', <DaysOfWeekChecks />, daysOfWeekFormat(model.daysOfWeek))} */}
                    {/* <ClassTimes form={form} dayTimes={this.state && this.state.dayTimes || model.dayTimes} showModal={() => this.showModal()} edit={(day) => { this.showModal(day) }} remove={(day) => { model.dayTimes = removeDays(day, model.dayTimes); this.setState({ dayTimes: model.dayTimes }) }} /> */}
                    <ClassTimes
                        form={form}
                        schoolClassTime={mergedSchoolClassTime}
                        showModal={() => this.showModal()}
                        remove={classTime => {
                            propsModel.schoolClassTime = removeClassTime(
                                classTime,
                                mergedSchoolClassTime
                            );
                            syncTsiRepDuration(
                                classTime,
                                propsModel.schoolClassTime,
                                model,
                                form
                            );
                            this.setState({
                                schoolClassTime: propsModel.schoolClassTime
                            });
                            this.onFormChange();
                        }}
                        editClassTime={(classTime) => {
                            this.setState({editClassTime: classTime});
                            this.showModal();
                        }}
                    />
                    <Divider orientation="left">
                        {fmtMsg(SchoolLocale.ClassTimeTypeTsi)}
                    </Divider>
                    <FormItemsLayout colTotal={3}>
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassTimeTsiRepDuration,
                            "tsiTimePerWeek",
                            <InputNumber
                                size="large"
                                min={1}
                                max={10080}
                                precision={0}
                                onChange={this.onFormChange}
                            />,
                            model.tsiTimePerWeek == 0
                                ? null
                                : model.tsiTimePerWeek
                        )}
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassTimeTsiRepDurationLessonPerWeek,
                            "tsiLessonsPerWeek",
                            <InputNumber
                                size="large"
                                min={1}
                                max={7}
                                precision={0}
                                onChange={this.onFormChange}
                                disabled={ClassHelper.canEditRequiredFieldAnnualPrepComplete(annualPrepComplete) && this.futureClass()}
                            />,
                            model.tsiLessonsPerWeek == 0
                                ? null
                                : model.tsiLessonsPerWeek,
                            true
                        )}
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassTimeTsiRepDurationLessonPerYear,
                            "tsiLessonsPerYear",
                            <InputNumber
                                size="large"
                                min={1}
                                max={999}
                                precision={0}
                                onChange={this.onFormChange}
                            />,
                            model.tsiLessonsPerYear == 0
                                ? null
                                : model.tsiLessonsPerYear
                        )}
                    </FormItemsLayout>

                    {renderFormItem(form, SchoolLocale.ClassNotesForSchool, "schoolNotes", <TextArea />, model.schoolNotes, false, [])}

                    {(isInternalUser && !this.props.isEdit || isInternalUser && this.props.isEdit && this.props.isClassManager) &&
                        <ClassInternalNotes form={form} defaultNotes={model.notes} />}

                    {renderFormItem(
                        { ...form, ...options },
                        SchoolLocale.ClassDisabled,
                        "disabled",
                        <Checkbox
                            onChange={e => {
                                model.disabled = e.target.checked;
                            }}
                            disabled={classDisabled}
                        >
                            <FormattedMessage id={SchoolLocale.ClassDisabled} />
                        </Checkbox>,
                        model.disabled
                    )}
                    {children}
                </GLForm>
                {this.state && this.state.showModal && (
                    <ClassTimeModal
                        isGrapeSEEDConnectVisible={this.props.isGrapeSEEDConnectVisible}
                        schoolClassTime={mergedSchoolClassTime}
                        editClassTime={this.state.editClassTime}
                        licenseType={this.getLicenseType()}
                        handleSubmit={classTimes => {
                            if (mergedSchoolClassTime.find(el => el.id === classTimes.id)) {
                                const replaceEditClassTime = processEditClassTime(classTimes);
                                mergedSchoolClassTime.forEach((el, index) => {
                                    if (el.id === replaceEditClassTime.id) {
                                        mergedSchoolClassTime[index] = replaceEditClassTime;
                                    }
                                })
                                propsModel.schoolClassTime = mergedSchoolClassTime;
                                syncTsiRepDuration(
                                    null,
                                    propsModel.schoolClassTime,
                                    model,
                                    form
                                );
                                this.setState({
                                    schoolClassTime: propsModel.schoolClassTime
                                });
                            } else {
                                const addedClassTimes = getAddedClassTime(
                                    classTimes
                                );
                                propsModel.schoolClassTime = mergedSchoolClassTime
                                    .concat(addedClassTimes)
                                    .sort(classTimeComparator);
                                syncTsiRepDuration(
                                    null,
                                    addedClassTimes,
                                    propsModel,
                                    form
                                );
                                this.setState({
                                    schoolClassTime: propsModel.schoolClassTime
                                });
                            }
                            this.hideModal();
                            this.onFormChange();
                        }}
                        onCancel={() => this.hideModal()}
                    />
                )}
            </>
        );
    }
}
function getGrapeSEED(component) {
    return component.state && component.state.isGrapeSEED;
}
export const mapClassDayTime = (model: SchoolClassModel, days: string[]) => {
    const { schoolClassTime } = model;
    model.schoolClassTime = [];
    if (schoolClassTime) {
        model.schoolClassTime = schoolClassTime
            .map(classTime => {
                const dayIndex = classTime.daysOfWeek - 1;
                classTime.daysOfWeekText = days[dayIndex];
                classTime.start &&
                    (classTime.start = moment(classTime.start, "HH:mm").format(
                        "HH:mm"
                    ));
                classTime.end &&
                    (classTime.end = moment(classTime.end, "HH:mm").format(
                        "HH:mm"
                    ));
                const needConvertText = isNaN(parseInt(classTime.type, 10));
                if (needConvertText) {
                    classTime.typeText = classTime.type;
                    classTime.type = SchoolClassTimeType[classTime.type];
                }
                return classTime;
            })
            .sort(classTimeComparator);
    }
    return model;
};
const catDays = (day, dayTimes: any[]) => {
    const dayTime = dayTimes.filter(d => d.id === day.id);
    if (dayTime.length > 0) {
        dayTime[0].time = day.time;
        return dayTimes;
    } else {
        dayTimes.push(day);
        return dayTimes.sort((pre, cur) => pre.id - cur.id);
    }
};
const getAddedClassTime = classTime => {
    const days = GLGlobal.intl
        .formatMessage({ id: SchoolLocale.ClassWeekdays })
        .split(",")
        .map(day => day.trim());
    return classTime.daysOfWeek.map((time, index) => {
        const dayIndex = parseInt(time, 10) - 1;
        return {
            id: guid(),
            type: classTime.type,
            typeText: SchoolClassTimeType[classTime.type],
            start: classTime.start ? classTime.start : null,
            end: classTime.end ? classTime.end : null,
            duration: classTime.duration,
            daysOfWeek: time,
            daysOfWeekText: days[dayIndex]
        };
    });
};
const processEditClassTime = classTime => {
    const days = GLGlobal.intl
        .formatMessage({ id: SchoolLocale.ClassWeekdays })
        .split(",")
        .map(day => day.trim());
    const dayIndex = parseInt(classTime.daysOfWeek, 10) - 1;
    return {
        id: classTime.id,
        type: classTime.type,
        typeText: SchoolClassTimeType[classTime.type],
        start: classTime.start ? classTime.start : null,
        end: classTime.end ? classTime.end : null,
        duration: classTime.duration,
        daysOfWeek: classTime.daysOfWeek.toString(),
        daysOfWeekText: days[dayIndex]
    };
};
const classTimeComparator = (
    classTimeA: SchoolClassTimeModel,
    classTimeB: SchoolClassTimeModel
) => {
    const daysOfWeek = classTimeA.daysOfWeek - classTimeB.daysOfWeek;
    const classTimeType = classTimeA.typeText && -classTimeA.typeText.localeCompare(
        classTimeB.typeText
    );
    const classTimeAStart = classTimeA.start ? classTimeA.start : "";
    const classTimeBStart = classTimeB.start ? classTimeB.start : "";
    const start = classTimeAStart.localeCompare(classTimeBStart);
    const classTimeAEnd = classTimeA.end ? classTimeA.end : "";
    const classTimeBEnd = classTimeB.end ? classTimeB.end : "";
    const end = classTimeAEnd.localeCompare(classTimeBEnd);
    return daysOfWeek == 0
        ? classTimeType == 0
            ? start == 0
                ? end
                : start
            : classTimeType
        : daysOfWeek;
};
const syncTsiRepDuration = (
    classTime: SchoolClassTimeModel,
    classTimes: SchoolClassTimeModel[],
    classModel: SchoolClassModel,
    form?
) => {
    const tsiTimePerWeekChanged = classTime
        ? classTime.typeText != SchoolClassTimeType[SchoolClassTimeType.Other] // indicate that removing tsi classtime
        : classTimes.filter(
            classTime =>
                classTime.typeText !=
                SchoolClassTimeType[SchoolClassTimeType.Other] // indicate adding tsi classtime
        ).length > 0;
    const changedFieldsValue = {};
    const mergedClassTimes = classTime
        ? classTimes
        : classModel.schoolClassTime;
    if (tsiTimePerWeekChanged) {
        classModel.tsiTimePerWeek = mergedClassTimes
        .filter(
            classTime =>
                classTime.typeText !=
                SchoolClassTimeType[SchoolClassTimeType.Other]
        )
        .reduce((pre, cur) => pre + cur.duration, 0);
        changedFieldsValue["tsiTimePerWeek"] =
            classModel.tsiTimePerWeek == 0
                ? null
                : classModel.tsiTimePerWeek > 10080
                    ? 10080
                    : classModel.tsiTimePerWeek;

        const computedTsiLessonsPerWeek = mergedClassTimes.filter(
            classTime =>
                classTime.typeText !=
                SchoolClassTimeType[SchoolClassTimeType.Other]
        ).length;
        classModel.tsiLessonsPerWeek = computedTsiLessonsPerWeek > 7 ? 7 : computedTsiLessonsPerWeek;
        changedFieldsValue["tsiLessonsPerWeek"] =
            classModel.tsiLessonsPerWeek == 0
                ? null
                : classModel.tsiLessonsPerWeek;
    }

    Object.getOwnPropertyNames(changedFieldsValue).length > 0 &&
        form &&
        form.setFieldsValue(changedFieldsValue);
};
const removeDays = (day, dayTimes: any[]) => {
    dayTimes.splice(
        dayTimes.findIndex(({ id }) => id === day.id),
        1
    );
    return dayTimes;
};
const removeClassTime = (
    classTime: SchoolClassTimeModel,
    classTimes: SchoolClassTimeModel[]
) => {
    classTimes.splice(
        classTimes.findIndex(({ id }) => id === classTime.id),
        1
    );
    return classTimes;
};

const nestLoop = (cnt, cb, step = 1) => {
    // This check is applied to prevent error - RangeError: Maximum call stack size exceeded
    // This is due to value of cnt is coming as NaN after save on edit class and this function goes into infinte loop.
    if (typeof cnt !== "number") {
        return [];
    }
    return cnt !== 0 ? nestLoop(cnt - step, cb, step).concat(cb(cnt)) : [];
}

const classTimeForm = (time: string, cnt = 0) =>
    time
        ? time.includes(",") || cnt === 0
            ? time.split(",")[0]
            : nestLoop(cnt, () => time).join()
        : null;

const integerValider = () => [
    { type: "integer", transform: v => Number(v) || v }
];

const daysOfWeekFormat = daysOfWeek =>
    daysOfWeek instanceof Array
        ? daysOfWeek.join()
        : daysOfWeek
            ? daysOfWeek.split(",")
            : [];

function format(values: SchoolClassModel, model) {
    values.schoolClassTime = model.schoolClassTime;
    values.unitPlanStartDate = model.unitPlanStartDate;
}

class DaysOfWeekChecks extends Component {
    render() {
        const days = GLGlobal.intl
            .formatMessage({ id: SchoolLocale.ClassWeekdays })
            .split(",")
            .map(day => day.trim());
        return (
            <Checkbox.Group {...this.props}>
                <Row>
                    {Object.getOwnPropertyNames(DaysOfWeek).map(
                        (day, index) => (
                            <Col key={index} span={6}>
                                <Checkbox value={DaysOfWeek[day]}>
                                    {days[index]}
                                </Checkbox>
                            </Col>
                        )
                    )}
                </Row>
            </Checkbox.Group>
        );
    }
}
class DaysOfWeekRadio extends Component {
    render() {
        const days = GLGlobal.intl
            .formatMessage({ id: SchoolLocale.ClassWeekdays })
            .split(",")
            .map(day => day.trim());
        return (
            <Radio.Group {...this.props}>
                <Row>
                    {Object.getOwnPropertyNames(DaysOfWeek).map(
                        (day, index) => (
                            <Col key={index} span={6}>
                                <Radio value={DaysOfWeek[day]}>
                                    {days[index]}
                                </Radio>
                            </Col>
                        )
                    )}
                </Row>
            </Radio.Group>
        );
    }
}
class ClassTimeType extends Component<ClassTimeProps> {

    isConnectLicenseType(licenseType) {
        return licenseType === LicenseTypes.Connect || licenseType === LicenseTypes.ClassicConnectHybrid || licenseType === LicenseTypes.ConnectNexusHybrid;
    }

    render() {
        return (
            <Radio.Group {...this.props}>
                {Object.getOwnPropertyNames(SchoolClassTimeType)
                    .filter(
                        type =>
                            !isNaN(parseInt(type, 10)) && parseInt(type) != 0
                    )
                    .sort(
                        (typeA, typeB) =>
                            -SchoolClassTimeType[typeA].localeCompare(
                                SchoolClassTimeType[typeB]
                            )
                    )
                    .map((type: any, index) => { 
                        if (parseInt(type, 10) === SchoolClassTimeType.GrapeSEEDConnect && !this.isConnectLicenseType(this.props.licenseType)) {
                            return <> </>;
                        }
                        return (<Radio key={index} value={parseInt(type, 10)}>
                            {GLGlobal.intl.formatMessage({
                                id:
                                    SchoolClassTimeTypeText[
                                    SchoolClassTimeType[type]
                                    ]
                            })}
                        </Radio>);
                    })}
            </Radio.Group>
        );
    }
}
class ClassTimeTable extends Table<{ id; day; time }> { }

class ClassTimes extends Component<{
    form;
    schoolClassTime;
    showModal;
    remove;
    editClassTime;
}> {

    recordDeleted?: boolean = false;
    recordEdited?: boolean = false;
    gridRef?: any = React.createRef();

    componentDidUpdate(prevProps, prevState) {
        if (this.recordDeleted) {
            this.gridRef.grid.collectionView.refresh();
            this.recordDeleted = false;
        }
        if (this.recordEdited) {
            this.gridRef.grid.collectionView.refresh();
            this.recordDeleted = false;
        }
    }

    onRemove(classTime, that) {
        that.recordDeleted = true;
        that.props.remove(classTime);
    }

    onEdit(classTime, that) {
        that.props.editClassTime(classTime);
        that.recordEdited = true;
    }

    render() {
        const { schoolClassTime, showModal, remove } = this.props;
        const hasSaveClassAction = GLGlobal.isActionValid(
            GSSchoolAction.SaveClass
        );
        schoolClassTime && schoolClassTime.map(item => {
            if (item.type == SchoolClassTimeType.GrapeSEEDConnect) {
                item.typeText = fmtMsg(SchoolLocale.ClassTimeTypeGrapeSEEDConnect);
            }
        });
        return (
            <div className="day-times">
                <div className="day-times-title">
                    <div>
                        <FormattedMessage id={SchoolLocale.ClassClassTime} />
                    </div>
                    <div
                        className={!hasSaveClassAction ? "button-disabled" : ""}
                    >
                        {
                            <a
                                href="javascript:void(0)"
                                onClick={e => {
                                    e.preventDefault();
                                    showModal();
                                }}
                            >
                                <Icon type="plus-circle-o" />
                                <FormattedMessage
                                    id={SchoolLocale.ClassAddClassTime}
                                />
                            </a>
                        }
                    </div>
                </div>
                <Grid
                    itemsSource={schoolClassTime}
                    ref={(ref) => this.gridRef = ref}
                    className="day-times__grid"
                >
                    {
                        getColumns(this.onRemove, this, this.onEdit).map(column => {
                            return <Column
                                header={column.title}
                                binding={column.dataIndex}
                                render={column.render}
                                key={column.key}
                                align={column.align}
                            >
                            </Column>
                        })
                    }
                </Grid>
            </div>
        );
    }
}
function getColumns(remove, that, edit) {
    const fmtMsg = GLGlobal.intl.formatMessage;
    return [
        {
            title: fmtMsg({ id: SchoolLocale.ClassDaysofWeek }),
            dataIndex: "daysOfWeekText",
            key: "daysOfWeekText",
            width: "25%"
        },
        {
            title: fmtMsg({ id: SchoolLocale.ClassTimeType }),
            dataIndex: "typeText",
            key: "typeText",
            width: "15%"
        },
        {
            title: fmtMsg({ id: SchoolLocale.ClassTimeStart }),
            dataIndex: "start",
            key: "start",
            width: "15%"
        },
        {
            title: fmtMsg({ id: SchoolLocale.ClassTimeEnd }),
            dataIndex: "end",
            key: "end",
            width: "15%"
        },
        {
            title: fmtMsg({ id: SchoolLocale.ClassTimeDuration }),
            dataIndex: "duration",
            key: "duration",
            width: "25%",
            align: "center"
        },
        {
            title: "",
            width: "5%",
            key: "icon",
            render: (text, record, index) => {
                const fmtMsg = id => GLGlobal.intl.formatMessage({ id });
                return (
                    <div className="table-item-actions">
                        <GLIcon
                            type="edit"
                            labelId={SchoolLocale.IconEdit}
                            onClick={() => {
                                edit(record, that);
                            }}
                        />
                        <GLIcon
                            type="delete"
                            labelId={SchoolLocale.IconDelete}
                            onClick={() => {
                                Modal.confirm({
                                    title: fmtMsg(SchoolLocale.TextSureDel),
                                    okText: fmtMsg(GLLocale.Ok),
                                    cancelText: fmtMsg(GLLocale.Cancel),
                                    onOk: () => {
                                        remove(record, that);
                                    }
                                });
                            }}
                        />
                    </div>
                );
            }
        }
    ];
}

interface ClassTimeModalProps {
    schoolClassTime: SchoolClassTimeModel[];
    handleSubmit;
    onCancel;
    isGrapeSEEDConnectVisible: boolean;
    editClassTime;
    licenseType: number;
}

@GLForm.create()
class ClassTimeModal extends Component<
ClassTimeModalProps & GLFormComponentProps
> {
    editClassForm?: boolean = false;
    validateClassTimes(rule, value, callback) {
        const { form } = this.props;
        if (this.checkClassTimes()) {
            let targetField = {};
            const fieldName = rule.field == "start" ? "end" : "start";
            targetField[fieldName] = {
                value: form.getFieldValue(fieldName),
                errors: null
            };
            form.setFields(targetField);
            this.handleCheckDuplicateClassTime();
        } else {
            const error = GLGlobal.intl.formatMessage({
                id:
                    rule.field == "start"
                        ? SchoolLocale.ClassTimeErrorStart
                        : SchoolLocale.ClassTimeErrorEnd
            });
            const message = error;
            callback(message);
            return;
        }
        callback();
    }
    checkClassTimes(
        startTime = this.props.form.getFieldValue("start"),
        endTime = this.props.form.getFieldValue("end"),
        startTimeChanged?
    ) {
        if (!startTime || !endTime) {
            return true;
        }
        const { form } = this.props;
        const start = moment(startTime.format("HH:mm:00"), "HH:mm");
        const end = moment(endTime.format("HH:mm:00"), "HH:mm");
        return end.isAfter(start);
    }
    handleClassTimeStartChanged(time: moment.Moment, timeString: string) {
        const endTime = this.props.form.getFieldValue("end");
        this.handleClassTimeChanged(time, endTime);
        this.handleCheckDuplicateClassTime()
    }
    handleClassTimeEndChanged(time: moment.Moment, timeString: string) {
        const startTime = this.props.form.getFieldValue("start");
        this.handleClassTimeChanged(startTime, time);
    }
    handleClassTimeChanged(startTime: moment.Moment, endTime: moment.Moment) {
        if (!startTime || !endTime) {
            return;
        }
        const start = moment(startTime.format("HH:mm:00"), "HH:mm");
        const end = moment(endTime.format("HH:mm:00"), "HH:mm");
        if (end.isAfter(start)) {
            const duration = end.diff(start, "m");
            this.props.form.setFieldsValue({ duration: duration });
        }
    }
    openPickerOnGetFocusHandler(e) {
        if (
            (bowser.gecko || bowser.msie) &&
            e.nativeEvent &&
            ((e.nativeEvent.relatedTarget == null &&
                e.nativeEvent.explicitOriginalTarget &&
                e.nativeEvent.explicitOriginalTarget.tagName == "INPUT") ||
                (e.nativeEvent.relatedTarget != null &&
                    e.nativeEvent.relatedTarget.tagName == "DIV"))
        ) {
            return false;
        }

        if (
            (bowser.gecko || bowser.msie) &&
            e.nativeEvent &&
            e.nativeEvent.relatedTarget &&
            e.nativeEvent.relatedTarget.tagName == "INPUT" &&
            e.nativeEvent.relatedTarget.type == "text"
        ) {
            if (
                e.nativeEvent.explicitOriginalTarget &&
                e.nativeEvent.explicitOriginalTarget.type == "text"
            ) {
                return false;
            }
        }
        return true;
    }
    handleCheckDuplicateClassTime = () => {
        const { form, schoolClassTime } = this.props;
        let duplicate = false;
        const vForm = form.getFieldsValue();
        if (vForm.daysOfWeek && moment(vForm.start).format("HH:mm") && moment(vForm.end).format("HH:mm") && this.editClassForm) {
            const start = moment(vForm.start).format("HH:mm");
            const end = moment(vForm.end).format("HH:mm");
            const timeStart = start.split(":");
            const timeStartValue = parseInt(timeStart[0], 10) * 60 + parseInt(timeStart[1], 10);
            const timeEnd = end.split(":");
            const timeEndValue = parseInt(timeEnd[0], 10) * 60 + parseInt(timeEnd[1], 10);
            schoolClassTime.forEach((classTime, index) => {
                if ((vForm.daysOfWeek.includes(JSON.stringify(classTime.daysOfWeek)) || vForm.daysOfWeek.includes(classTime.daysOfWeek)) && vForm.type === classTime.type ) {
                    if (!classTime.start || !classTime.end) return;
                    const startCurrent = classTime.start;
                    const timeStartCurrent = startCurrent.split(":");
                    const timeStartCurrentValue = parseInt(timeStartCurrent[0], 10) * 60 + parseInt(timeStartCurrent[1], 10);
                    const endCurrent = classTime.end;
                    const timeEndCurrent = endCurrent.split(":");
                    const timeEndCurrentValue = parseInt(timeEndCurrent[0], 10) * 60 + parseInt(timeEndCurrent[1], 10);
                    if ((timeStartValue > timeStartCurrentValue && timeStartValue < timeEndCurrentValue)
                        || (timeEndValue > timeStartCurrentValue && timeEndValue < timeEndCurrentValue)
                        || (timeStartValue <= timeStartCurrentValue && timeEndValue >= timeEndCurrentValue)) {
                        duplicate = true;
                    }
                }
            })
        }
        return duplicate;
    }
    render() {
        const { form, handleSubmit, onCancel, schoolClassTime, editClassTime, licenseType } = this.props;
        const renderFormItem = FormHelper.renderFormItem;
        const fmtMsg = GLGlobal.intl.formatMessage;
        const duplicateText = GLGlobal.intl.formatMessage({ id: SchoolLocale.ClassTimeDuplicate });
        const titleModal = editClassTime ? fmtMsg({ id: SchoolLocale.ClassEditClassTime }) : fmtMsg({ id: SchoolLocale.ClassAddClassTime });
        const dayEdit = editClassTime ? editClassTime.daysOfWeek.toString() : null;
        const startEdit = editClassTime ? editClassTime.start ? moment(editClassTime.start, "HH:mm") : null : null;
        const endEdit = editClassTime ? editClassTime.end ? moment(editClassTime.end, "HH:mm") : null : null;
        const durationEdit = editClassTime ? editClassTime.duration : null;
        const typeEdit = editClassTime ? editClassTime.type : SchoolClassTimeType.TSI;
        const submitTitleText = editClassTime ? SchoolLocale.BtnSave : SchoolLocale.TextAdd;
        const isClassTimeRequired = (form.getFieldValue("type") || typeEdit) === SchoolClassTimeType.GrapeSEEDConnect;
        return (
            <Modal
                title={titleModal}
                closable={false}
                footer={null}
                visible={true}
                className="class-modal"
            >
                <GLForm
                    form={form}
                    onSubmit={
                        onSubmit(this, handleSubmit, values => {
                            values.id = editClassTime? editClassTime.id : schoolClassTime.length + 1;
                            values.start = values.start
                                ? moment(values.start).format("HH:mm")
                                : null;
                            values.end = values.end
                                ? moment(values.end).format("HH:mm")
                                : null;
                        })
                    }
                    onChange={(e) => {
                        this.editClassForm = true;
                        this.handleCheckDuplicateClassTime();
                    }}
                >
                    {renderFormItem(
                        form,
                        SchoolLocale.ClassDaysofWeek,
                        "daysOfWeek",
                        editClassTime ? <DaysOfWeekRadio/> : <DaysOfWeekChecks />,
                        dayEdit,
                        false,
                        [
                            {
                                required: true,
                                message: `${fmtMsg(
                                    { id: GLLocale.FormPrefixPleaseSelect },
                                    {
                                        name: fmtMsg({
                                            id: SchoolLocale.ClassDaysofWeek
                                        }).toLowerCase()
                                    }
                                )}`
                            }
                        ]
                    )}
                    <FormItemsLayout colTotal={3}>
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassTimeStart,
                            "start",
                            <GLTimePicker
                                size="large"
                                {...alignPop()}
                                format={"H:mm"}
                                onChange={this.handleClassTimeStartChanged.bind(this)}
                                openPickerOnGetFocus={this.openPickerOnGetFocusHandler}
                            />,
                            startEdit,
                            isClassTimeRequired,
                            [
                                {
                                    required: isClassTimeRequired,
                                    message: `${fmtMsg(
                                        { id: GLLocale.FormPrefixPleaseSelect },
                                        {
                                            name: fmtMsg({
                                                id: SchoolLocale.ClassTimeStart
                                            }).toLowerCase()
                                        }
                                    )}`
                                },
                                { validator: this.validateClassTimes.bind(this) }
                            ]
                        )}
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassTimeEnd,
                            "end",
                            <GLTimePicker
                                size="large"
                                {...alignPop()}
                                format={"H:mm"}
                                onChange={this.handleClassTimeEndChanged.bind(this)}
                                openPickerOnGetFocus={this.openPickerOnGetFocusHandler}
                            />,
                            endEdit,
                            isClassTimeRequired,
                            [
                                {
                                    required: isClassTimeRequired,
                                    message: `${fmtMsg(
                                        { id: GLLocale.FormPrefixPleaseSelect },
                                        {
                                            name: fmtMsg({
                                                id: SchoolLocale.ClassTimeEnd
                                            }).toLowerCase()
                                        }
                                    )}`
                                },
                                { validator: this.validateClassTimes.bind(this) }
                            ]
                        )}
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassTimeDuration,
                            "duration",
                            <InputNumber
                                size="large"
                                min={1}
                                max={1440}
                                precision={0}
                                maxLength={3}
                            />,
                            durationEdit,
                            true
                        )}
                    </FormItemsLayout>
                    {renderFormItem(
                        form,
                        SchoolLocale.ClassTimeType,
                        "type",
                        <ClassTimeType isGrapeSEEDConnectVisible={this.props.isGrapeSEEDConnectVisible} licenseType={licenseType} />,
                        typeEdit
                    )}
                    {this.handleCheckDuplicateClassTime() &&
                        <p className="class-time-duplicate">{duplicateText}</p>
                    }
                    <Form.Item>
                        <SubmitBtns
                            isDisabled={this.handleCheckDuplicateClassTime()}
                            submitTitle={submitTitleText}
                            onCancel={onCancel}
                        />
                    </Form.Item>
                </GLForm>
            </Modal>
        );
    }
}
function isEditTime({ id }) {
    return !!id;
}
function unSelected(isEditTime, dayTimes: any[]) {
    return ({ day }) => {
        if (!isEditTime) {
            const id = DaysOfWeek[day];
            return !dayTimes.some(time => time.id === id);
        }
        return true;
    };
}

// class ClassTimeTable extends Table<{ id, day, time }> { }
// class ClassTimes extends Component<{ form, classTime }> {
//     render() {
//         const { form, classTime } = this.props;
//         const days: any[] = form.getFieldValue('daysOfWeek').map(d => Number(d) === 0 ? 7 : Number(d)).sort();
//         const dayTexts = GLGlobal.intl.formatMessage({ id: SchoolLocale.ClassWeekdays }).split(',');
//         const classTimes = classTime ? classTime.split(',') : [];
//         const dataSource = days.map((day, index) => ({ id: guid(), day: dayTexts[day - 1], time: moment(classTimes[index] || null, 'HH:mm') }))
//         return (
//             <ClassTimeTable className='table-formitems' {...getTableProps({ dataSource, loading: false, columns: getColumns(form) })} ></ClassTimeTable >
//         )
//     }
// }
// function getColumns(form) {
//     const fmtMsg = GLGlobal.intl.formatMessage;
//     const renderFormItem = FormHelper.renderFormItem;
//     return [
//         {
//             title: fmtMsg({ id: SchoolLocale.ClassDaysofWeek }),
//             dataIndex: "day",
//             width: "50%"
//         }, {
//             title: fmtMsg({ id: SchoolLocale.ClassClassTime }),
//             dataIndex: "time",
//             width: "50%",
//             render: (text, record, index) => {
//                 return renderFormItem({ ...form, formItemProps: { label: null } }, SchoolLocale.ClassClassTime, `classTimes[${record.id}]`, <TimePicker {...alignPop()} format={'HH:mm'} />, null, true)
//             }
//         }
//     ];
// }
function unitValider(
    { getFieldsValue, resetFields, getFieldsError, setFieldsValue },
    ClassUnitValid,
    maxUnitsPerYear
) {
    const unCheckMaxUnitsPerYear = GLGlobal.isActionValid(
        GSSchoolAction.UncheckMaxUnitPerYear
    );
    return [
        {
            validator: (rule, value, callback) => {
                const { startUnit, maxUnit } = getFieldsValue([
                    "startUnit",
                    "maxUnit"
                ]);
                if (Math.abs(startUnit) > Math.abs(maxUnit)) {
                    callback(
                        GLGlobal.intl.formatMessage({ id: ClassUnitValid })
                    );
                } else if (
                    !unCheckMaxUnitsPerYear &&
                    maxUnitsPerYear &&
                    Math.abs(maxUnit) - Math.abs(startUnit) >= maxUnitsPerYear
                ) {
                    callback(
                        GLGlobal.intl.formatMessage(
                            { id: SchoolLocale.ClassUnitValidOverflowCommon },
                            { value: maxUnitsPerYear }
                        )
                    );
                } else {
                    const {
                        startUnit: startErr,
                        maxUnit: maxErr
                    } = getFieldsError(["startUnit", "maxUnit"]);
                    if (startErr || maxErr) {
                        resetFields(["startUnit", "maxUnit"]);
                        setFieldsValue({ startUnit, maxUnit });
                    }
                }
                callback();
            }
        }
    ];
}
function getOptions(maxUnit, step = 1) {
    return nestLoop(
        maxUnit,
        cnt => (
            <Select.Option key={cnt} value={cnt}>
                {Math.abs(cnt)}
            </Select.Option>
        ),
        step
    );
}
function isGrapeSEED(curriculumType, cb?) {
    return Number.isInteger(curriculumType)
        ? curriculumType === CurriculumType.GrapeSEED
        : cb
            ? cb()
            : true;
}
@connect(({ school: { current: { maxUnit, lsMaxUnit } } }: StateType) => ({
    maxUnit,
    lsMaxUnit
}))
class UnitsSelect extends Component<{
    onChange?;
    maxUnit?;
    lsMaxUnit?;
    isGrapeSEED?;
    disabled?;
    isPromote?;
    minUnit4Promote?;
    maxUnit4Promote?;
}> {
    options;
    componentWillReceiveProps({
        onChange,
        maxUnit,
        lsMaxUnit,
        isGrapeSEED,
        isPromote,
        minUnit4Promote,
        maxUnit4Promote
    }) {
        const {
            maxUnit: preMaxUnit,
            isGrapeSEED: preIsGrapeSEED,
            lsMaxUnit: preLSMaxUnit
        } = this.props;
        !isPromote &&
            (maxUnit !== preMaxUnit ||
                lsMaxUnit !== preLSMaxUnit ||
                isGrapeSEED != preIsGrapeSEED) &&
            isGrapeSEED != null &&
            (this.options = getOptions(
                isGrapeSEED ? maxUnit : lsMaxUnit,
                isGrapeSEED ? 1 : -1
            ));
        isPromote &&
            minUnit4Promote &&
            (maxUnit4Promote || maxUnit) &&
            (this.options = [...Array(maxUnit4Promote || maxUnit).keys()]
                .filter(unit => unit >= minUnit4Promote - 1)
                .map((key, index) => {
                    const unitKey = key + 1;
                    return CommonHelper.getOption(
                        unitKey,
                        isGrapeSEED ? unitKey : -unitKey,
                        unitKey
                    );
                }));
    }

    render() {
        return (
            <Select
                {...this.props}
                disabled={this.props.disabled}
                onChange={this.props.onChange}
                size="large"
                {...alignPop()}
            >
                {this.options}
            </Select>
        );
    }
}

class LicenseTypeSelect extends Component<{
    isEdit;
    onChange;
    isGSHidden?: boolean;
    isLSHidden?: boolean;
}> {
    render() {
        return (
            <Select
                {...this.props}
                disabled={this.props.isEdit}
                onChange={this.props.onChange}
                size="large"
                {...alignPop()}
            >
                {!this.props.isGSHidden && (
                    <Select.Option value={CurriculumType.GrapeSEED}>
                        {CurriculumType[CurriculumType.GrapeSEED]}
                    </Select.Option>
                )}
                {!this.props.isLSHidden && (
                    <Select.Option value={CurriculumType.LittleSEED}>
                        {CurriculumType[CurriculumType.LittleSEED]}
                    </Select.Option>
                )}
            </Select>
        );
    }
}
