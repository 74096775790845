import { Action, Container, GridCheckbox, GridInputDate, GridInputNumber, GridValidators, SecondaryTitle, WijmoGrid, GridReadOnlyCheckbox } from "@app/components";
import { GridRef } from "@app/components/grid/grid";
import { useService, WijmoColumnValidatorFn } from "@app/hooks";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { CurriculumType, SchoolBulkClassModel, SchoolClassStudent, SchoolClassTeacher } from "@app/service/class";
import { CampusModel } from "@app/service/school/campus/model";
import { SchoolModel } from "@app/service/schools";
import { updateBulkEditClasses } from "@app/states/school/classes";
import { ClassHelper, DateHelper, fmtMsg, GSSchoolAction, guid, LicenseTypes, TYPES } from "@app/util";
import { Button, Col, Icon, List, Modal, Popover, Row, Checkbox } from "antd-min";
import { connect, GLGlobal, GLLocale, MatIcon, StateType, withRouter, HeaderMenuArrowNavigatorIcon, GLUtil } from "gl-commonui";
import { cloneDeep } from "lodash";
import React, { FC, ReactNode, useEffect, useRef, useState, useReducer } from "react";
import { RouteComponentProps } from "react-router";
import { CollectionView, DataType, toggleClass } from "wijmo/wijmo";
import {
    CellRange,
    CellRangeEventArgs,
    CellType,
    DataMap,
    FlexGrid,
    FormatItemEventArgs,
    HeadersVisibility,
    KeyAction,
    SelectionMode,
} from "wijmo/wijmo.grid";
import { GridCellTemplateType } from "wijmo/wijmo.interop.grid";
import { FlexGridCellTemplate, ICellTemplateContext } from "wijmo/wijmo.react.grid";
import { initBulkEditor } from "../../../states/school/class";
import {
    BulkAction,
    canEditStartMaxUnit,
    CanEditStartMaxUnitReturnType,
    copyTSIREP,
    formatClasses,
    generateDuplicateClass,
    generatePromotedClass,
    getActionDataMap,
    getDateFormatTemplate,
    getCurriculumTypeDataMap,
    getNextDate,
    getUnitColumnDataMap,
    isFutureClass,
    isNewClass,
    isNormalClass,
    isOnlyLittleSEED,
    setRowAsDirty,
    unorderedMap,
    isGrapeSeedClass,
    getLicenseTypeValue,
} from "./bulkedit-utill";
import "./bulkedit.less";
import { PathConfig } from "@app/config/pathconfig";
import { ITermManagerService, TermModel } from "@app/service/term-manager";
import moment from "moment";
import { ICampusService } from "@app/service/school/campus";

const useForceUpdate = () => {
    const [, setIt] = useState(false);
    return () => setIt(it => !it);
};

const { Grid, Column } = WijmoGrid;

let unorderedDataSource: { [key: string]: SchoolBulkClassModel } = {};

/** Due to an issue wijmo grid callbacks, has to create this global var.
 * Raised issue to Wijmo team we have to remove it(and use isAnnualPrepComplete which declared in the component) once they fix the issue.
 */
let isAnnualPrepCompleteGlobal = false;
let cannotEditLicense = true;
let canEditUnit: CanEditStartMaxUnitReturnType = {
    editMaxUnit: false,
    editStartUnit: false,
};

interface ClassBulkEditProps extends RouteComponentProps<any> {
    school: SchoolModel;
    campus: CampusModel;
    bulkClasses: any[];
    updateBulkEditClasses: (d: any) => void;
    initBulkEditor?: (params: object) => void;
}
const ClassBulkEdit: FC<ClassBulkEditProps> = (props) => {
    const termManagerService = useService<ITermManagerService>(TYPES.ITermManagerService);
    const campusService = useService<ICampusService>(TYPES.ICampusService);
    const { match, school, campus, bulkClasses, initBulkEditor, updateBulkEditClasses } = props;
    const { params } = match;
    const { lsMaxUnit, maxUnit, allowPromoteClass: isPromotionAllowed, allowSchoolEditLicense, maxUnitsPerYear } = school || {};
    const forceUpdate = useForceUpdate();
    const gridRef = useRef<GridRef>(null);
    const grid = gridRef.current && gridRef.current.grid;
    const [isValid, setIsValid] = useState<boolean>(false);

    const [dataSource, setDataSource] = useState<SchoolBulkClassModel[]>();
    const [isOuterUser, setIsOuterUser] = useState<boolean>(false);
    const [campusLicenses, setCampusLicenses] = useState<number[]>([]);

    const isAnnualPrepComplete = isOuterUser && campus && campus.annualPrepComplete;
    const dateFormat = getDateFormatTemplate();
    const nextDate = getNextDate();

    const { regionId, campusId } = params;
    const existingTerms = useRef([])
    const termDataMap = new DataMap(existingTerms.current, 'id', 'name');

    termDataMap.getDisplayValues = function (dataItem) {
        return existingTerms.current.filter((term: TermModel) => !term.disabled || term.id === dataItem.term).map((term: TermModel) => term.name);
    };

    useEffect(() => {
        initBulkEditor(params);
        termManagerService.getTermsForCampus(regionId, campusId).then((terms) => {
            existingTerms.current = terms;
        });
    }, []);

    useEffect(() => {
        campusService.getCampusLicenseTypes(school.id, campusId).then((data) => {
            setCampusLicenses(data);
        });
    }, []);

    useEffect(() => {
        const newDataSource = formatClasses(bulkClasses);
        unorderedDataSource = Object.assign(unorderedDataSource, cloneDeep(unorderedMap(newDataSource, "id")));
        setDataSource(newDataSource);
        if (grid) {
            newDataSource.forEach(d => {
                grid.collectionView.sourceCollection.push(d);
            });
            grid.collectionView.collectionChanged.addHandler(_ => forceUpdate())
            grid.collectionView.refresh();
        }

        // setUnorderedDataSource(newUnorderedDataSource);
    }, [bulkClasses]);

    useEffect(() => {
        const isInternalUser = GLGlobal.isActionValid(GSSchoolAction.IgnoreCampusAnnualPrepComplete);
        setIsOuterUser(!isInternalUser);

        cannotEditLicense = !ClassHelper.canEditLicense([], allowSchoolEditLicense, true);

        canEditUnit = canEditStartMaxUnit(school);
    }, [school]);

    useEffect(() => {
        isAnnualPrepCompleteGlobal = isOuterUser && campus && campus.annualPrepComplete;
    }, [campus, isOuterUser]);

    const addNewClassHandler = (): void => {
        grid.collectionView.sourceCollection.push(newItemCreator());
        grid.collectionView.refresh();
    };

    const newItemCreator = (): Partial<SchoolBulkClassModel> => {
        const { littleSEEDLicenses, grapeSEEDLicenses } = school;
        const newClassCount = grid.collectionView.items.filter((aClass: SchoolBulkClassModel) => aClass.action === BulkAction.New).length + 1;
        return {
            id: guid(),
            action: BulkAction.New,
            className: `New class ${newClassCount}`,
            term: null,
            startDate: null,
            endDate: null,
            keepTeachers: false,
            keepStudents: false,
            licenses: 0,
            curriculumType: isOnlyLittleSEED(school) ? CurriculumType.LittleSEED : CurriculumType.GrapeSEED,
            startUnit: 1,
            maxUnit: 1,
            keepClassTimes: false,
            tsiLessonsPerWeek: 3,
            dirty: true,
            licenseType: isOnlyLittleSEED(school) ? littleSEEDLicenses[0] : grapeSEEDLicenses[0],
        };
    };

    const renderTeacherStudentPopover = (
        item: SchoolBulkClassModel,
        key: "students" | "teachers",
        titleLocaleId: string,
        render: (item: SchoolClassTeacher | SchoolClassStudent) => ReactNode,
    ): ReactNode => {
        const source = item[key];
        if (!source || !source.length) {
            return null;
        }
        return (
            <Popover
                content={<List bordered size="small" dataSource={source as any[]} renderItem={(item) => <List.Item>{render(item)}</List.Item>} />}
                title={fmtMsg({ id: titleLocaleId })}
                overlayClassName="bulkedit__popover"
                placement="bottom"
            >
                <a href="javascript:void(0)" className="bulkedit__cellblock bulkedit__infopop">
                    <Icon type="info-circle" />
                </a>
            </Popover>
        );
    };

    const handleEdits = (flex: FlexGrid, event: CellRangeEventArgs, dataItem: SchoolBulkClassModel): void => {
        const { action, exist, promptClassID, keepStudents } = dataItem;
        const { binding } = flex.columns[event.col];
        const isNew = isNewClass(dataItem);
        const isFuture = isFutureClass(dataItem);
        const isNormal = isNormalClass(dataItem);
        const isGrapeSEED = isGrapeSeedClass(dataItem);
        const termObj = existingTerms.current.find(t => t.id === dataItem.term);
        const cancelEdit = () => {
            event.cancel = true;
        };

        if (action === BulkAction.Close && !(binding === "action" || binding === "closeDate" || binding === "revert")) {
            return cancelEdit();
        }
        switch (binding) {
            case "action":
                if (isNew || (isFuture && isAnnualPrepCompleteGlobal)) {
                    return cancelEdit();
                }
                if (!exist && action === BulkAction.Promote && promptClassID) {
                    return cancelEdit();
                }
                break;
            case "className":
                if (isFuture && isAnnualPrepCompleteGlobal) return cancelEdit();
                break;
            case "startDate":
                if (isNormal) return cancelEdit();
                if (isFuture && isAnnualPrepCompleteGlobal) return cancelEdit();
                if (termObj && termObj.enforceDate) {
                    return cancelEdit();
                }
                break;
            case "endDate":
                if (isNormal) return cancelEdit();
                if (termObj && termObj.enforceDate) {
                    return cancelEdit();
                }
                break;
            case "closeDate":
                if (action !== BulkAction.Close) {
                    return cancelEdit();
                }
                break;
            case "keepTeachers":
                if (isFuture || isNormal) {
                    return cancelEdit();
                }
                if (isNew && ![BulkAction.Promoted, BulkAction.Duplicated].includes(action)) {
                    return cancelEdit();
                }
                if (exist && action === BulkAction.Promoted) {
                    return cancelEdit();
                }

                break;
            case "keepStudents":
                if (isFuture || isNormal) {
                    return cancelEdit();
                }
                if (isNew && action !== BulkAction.Promoted) {
                    return cancelEdit();
                }
                if (exist && action === BulkAction.Promoted) {
                    return cancelEdit();
                }
                break;
            case "licenses":
                if (cannotEditLicense) {
                    return cancelEdit();
                }
                if (isFuture && isAnnualPrepCompleteGlobal) {
                    return cancelEdit();
                }
                if (isNormal) {
                    return cancelEdit();
                }
                if (isNew && keepStudents) {
                    return cancelEdit();
                }
                break;
            case "curriculumType":
                if (isFuture) {
                    return cancelEdit();
                }
                if (isNormal) {
                    return cancelEdit();
                }
                if (isNew && action == BulkAction.Promoted) {
                    return cancelEdit();
                }
                if (isFuture && isAnnualPrepCompleteGlobal) return cancelEdit();
                break;
            case "studentAgeGrade":
                if (isFuture && isAnnualPrepCompleteGlobal) return cancelEdit();
                break;
            case "startUnit":
                if (isNew) {
                    return null;
                }
                if (!canEditUnit.editStartUnit) {
                    return cancelEdit();
                }
                if (isFuture && isAnnualPrepCompleteGlobal) return cancelEdit();
                break;
            case "maxUnit":
                if (isNew) {
                    return null;
                }
                if (!canEditUnit.editMaxUnit) {
                    return cancelEdit();
                }
                if (isFuture && isAnnualPrepCompleteGlobal) return cancelEdit();
                break;
            case "keepClassTimes":
                if (isFuture || isNormal) {
                    return cancelEdit();
                }
                if (isNew && ![BulkAction.Promoted, BulkAction.Duplicated].includes(action)) {
                    return cancelEdit();
                }
                break;
            case "tsiLessonsPerWeek":
                if (isFuture && isAnnualPrepCompleteGlobal) return cancelEdit();
                break;
            default:
                break;
        }
    };
    const cellEditEnded = (flex: FlexGrid, event: CellRangeEventArgs, dataItem: SchoolBulkClassModel): void => {
        const column = flex.columns[event.col];
        const sourceCollection = flex.collectionView.sourceCollection as SchoolBulkClassModel[];
        switch (column.binding) {
            case "action":
                onActionChanged(flex, event, dataItem);
                break;
            case "keepStudents":
                onKeepStudentChanged(flex, sourceCollection, dataItem);
                break;
            case "keepClassTimes":
                onKeepClassTimesChanged(flex, sourceCollection, dataItem);
                break;
            case "term":
                onTermChanged(flex, sourceCollection, dataItem);
                break;

            default:
                break;
        }
        setRowAsDirty(column, dataItem, unorderedDataSource);
    };

    const cellEditEnding = (flex: FlexGrid, event: CellRangeEventArgs, dataItem: SchoolBulkClassModel): void => {
        const column = flex.columns[event.col];
        switch (column.binding) {
            case "curriculumType":
                onCurriculumTypeChanged(flex, dataItem);
                break;

            default:
                break;
        }
        setRowAsDirty(column, dataItem, unorderedDataSource);
    }

    const onActionChanged = (flex: FlexGrid, event: CellRangeEventArgs, dataItem: SchoolBulkClassModel) => {
        const { action, closeDate } = dataItem;
        const selectedTerm = existingTerms.current.find(t => t.id === dataItem.term);
        const sourceCollection = flex.collectionView.sourceCollection as SchoolBulkClassModel[];
        if (action === BulkAction.None && closeDate) {
            dataItem.closeDate = null;
        }
        if (action === BulkAction.Promote && !dataItem.promptClassID && !isAnnualPrepCompleteGlobal) {
            const promotedClass = generatePromotedClass(dataItem, school, selectedTerm && selectedTerm.enforceDate ? true : false);
            sourceCollection.splice(event.row + 1, 0, promotedClass);
            flex.collectionView.refresh();
            // validate row if inserted in between
            // last row is auto validated from 'useWijmoValidators'
            if (sourceCollection.length !== event.row + 1) {
                gridRef.current.validation.validateRow(event.row + 1);
            }
            flex.select(new CellRange(event.row + 1, event.col));
        }
        if (action === BulkAction.Duplicate && !dataItem.duplicateClassID && !isAnnualPrepCompleteGlobal) {
            const duplicatedClass = generateDuplicateClass(dataItem, school, selectedTerm && selectedTerm.enforceDate ? true : false);
            sourceCollection.splice(event.row + 1, 0, duplicatedClass);
            flex.collectionView.refresh();
            // validate row if inserted in between
            // last row is auto validated from 'useWijmoValidators'
            if (sourceCollection.length !== event.row + 1) {
                gridRef.current.validation.validateRow(event.row + 1);
            }
            flex.select(new CellRange(event.row + 1, event.col));
        }
    };

    const onKeepStudentChanged = (flex: FlexGrid, sourceCollection: SchoolBulkClassModel[], dataItem: SchoolBulkClassModel) => {
        if (BulkAction.Promoted === dataItem.action) {
            if (dataItem.keepStudents) {
                const previousRowData = sourceCollection.find((d) => d.promptClassID === dataItem.id);
                dataItem.licenses = previousRowData.licenses;
            }
            else {
                dataItem.licenses = 0;
            }
        }
    };

    const onCurriculumTypeChanged = (flex: FlexGrid, dataItem: SchoolBulkClassModel) => {
        dataItem.startUnit = 1;
        dataItem.maxUnit = 1;
        dataItem.licenseType = null;
    };

    const onKeepClassTimesChanged = (flex: FlexGrid, sourceCollection: SchoolBulkClassModel[], dataItem: SchoolBulkClassModel) => {
        if ([BulkAction.Promoted, BulkAction.Duplicated].includes(dataItem.action)) {
            if (dataItem.keepClassTimes) {
                const previousRowData = sourceCollection.find(
                    (d) => (dataItem.action === BulkAction.Promoted ? d.promptClassID : d.duplicateClassID) === dataItem.id,
                );
                Object.assign(dataItem, {
                    classTimes: previousRowData.classTimes,
                    ...copyTSIREP(previousRowData),
                });
            } else {
                dataItem.classTimes = "";
            }
        }
    };

    const onTermChanged = (flex: FlexGrid, sourceCollection: SchoolBulkClassModel[], dataItem: SchoolBulkClassModel) => {
        const selectedTerm = existingTerms.current.find(t => t.id === dataItem.term);
        if (!selectedTerm) return;
        const index = sourceCollection.indexOf(dataItem)
        if (selectedTerm.startDate) {
            dataItem.startDate = selectedTerm.startDate;
        }
        if (selectedTerm.endDate) {
            dataItem.endDate = selectedTerm.endDate;
        }
        gridRef.current.validation.validateRow(index);
    }

    const onDeleteRow = (context: ICellTemplateContext) => {
        const item = context.item as SchoolBulkClassModel;
        const flex = context.row.grid;
        const rowIndex = context.row.index;
        const collectionView = context.row.grid.collectionView as CollectionView;
        const sourceCollection = collectionView.sourceCollection as SchoolBulkClassModel[];

        const reset = (rowData: Partial<SchoolBulkClassModel>, specialItem?: SchoolBulkClassModel, forceAll?: boolean) => {
            rowData.dirty = specialItem ? specialItem.dirty : false;
            const setValue = (resetItem) => (key) => {
                const isActionInExistClass = key === "action" && rowData.exist;
                if (!isActionInExistClass || forceAll) {
                    resetItem[key] = rowData[key];
                }
            };
            Object.getOwnPropertyNames(rowData).forEach(setValue(specialItem ? specialItem : item));
            gridRef.current.validation.validateRow(context.row.index);
            flex.invalidate(true);
        };
        const revertOld = () => {
            const origin = unorderedDataSource[item.id];
            const relatedClose = origin.closeDate ? { closeDate: null } : {};
            reset({
                ...origin,
                ...relatedClose,
                action: item.action,
                promptClassID: item.promptClassID,
                duplicateClassID: item.duplicateClassID,
            });
        };
        const revertNew = () => {
            collectionView.removeAt(rowIndex);
            const newItem = sourceCollection.find((d) => (item.action === BulkAction.Promoted ? d.promptClassID : d.duplicateClassID) === item.id);
            const origin = unorderedDataSource[newItem.id];
            const relatedId =
                item.action === BulkAction.Promoted ? { promptClassID: origin.promptClassID } : { duplicateClassID: origin.duplicateClassID };
            let relatedClose = origin.closeDate ? { closeDate: origin.closeDate } : {};
            let action = origin.action;
            if (newItem.promptClassID && newItem.duplicateClassID) {
                if (item.action === BulkAction.Promoted) {
                    action = BulkAction.Duplicate;
                } else if (origin.promptClassID) {
                    action = BulkAction.None;
                } else {
                    action = newItem.action;
                }
                relatedClose = {};
            }
            if (item.action === BulkAction.Duplicated && newItem.action !== BulkAction.Duplicate) {
                action = newItem.action;
            }
            if (origin.exist && origin.action === BulkAction.Promote) {
                action = origin.action;
            }
            reset(
                {
                    id: origin.id,
                    action: action,
                    ...relatedId,
                    ...relatedClose,
                },
                newItem,
            );
        };
        switch (item.action) {

            case BulkAction.New:
                collectionView.removeAt(rowIndex);
                break;
            case BulkAction.Promote:
            case BulkAction.Duplicate:
                revertOld();
                break;
            case BulkAction.Promoted:
            case BulkAction.Duplicated:
                item.exist ? revertOld() : revertNew();
                break;
            case BulkAction.None:
                reset({
                    ...unorderedDataSource[sourceCollection[rowIndex].id],
                    duplicateClassID: item.duplicateClassID,
                });
                break;
            case BulkAction.Close:
                reset(
                    {
                        ...unorderedDataSource[sourceCollection[rowIndex].id],
                        duplicateClassID: item.duplicateClassID,
                    },
                    undefined,
                    true,
                );
                break;
            default:
                break;
        }
    };

    const classTermUniqueValidator = (value: string, dataItem: SchoolBulkClassModel, grid: FlexGrid): ReturnType<WijmoColumnValidatorFn> => {
        const data = grid.collectionView.sourceCollection as SchoolBulkClassModel[];
        const hasDuplicate = data.find((row) => {
            return row !== dataItem && row.className === dataItem.className && row.term === dataItem.term;
        });
        if (hasDuplicate) {
            return fmtMsg(SchoolLocale.BulkUnique);
        }
    };

    const isLicenseTypeConnect = (licenseType) => {
        return licenseType === LicenseTypes.Connect || licenseType === LicenseTypes.ClassicConnectHybrid || licenseType === LicenseTypes.ConnectNexusHybrid;
    }

    const licenseTypeValidator = (value: string, dataItem: SchoolBulkClassModel, grid: FlexGrid): ReturnType<WijmoColumnValidatorFn> => {
        if (!value) {
            return fmtMsg(SchoolLocale.BulkClassLicenseTypeException);
        }

        const gridData = grid.collectionView.sourceCollection as SchoolBulkClassModel[];
        if (dataItem.action === BulkAction.Duplicated || dataItem.action === BulkAction.Promoted) {
            const sourceClass = gridData.filter(f => f.id == dataItem.sourceClassId)[0];

            // Non-Connect related license type classes can be Duplicated with/ without any class times to class with any license type
            if (!isLicenseTypeConnect(sourceClass && sourceClass.licenseType)) {
                return null;
            }

            // Classes with Connect related license types and having class times can be Duplicated to classes with ONLY Connect related license type
            if (dataItem.keepClassTimes && dataItem.classTimes != null) {
                if (!isLicenseTypeConnect(Number(value))) {
                    return fmtMsg(SchoolLocale.BulkClassTimeException);
                }
            }
        }
        return null;
    }

    const unitPerYearValidator = (value: string, dataItem: SchoolBulkClassModel): ReturnType<WijmoColumnValidatorFn> => {
        const isGrapeSEED = dataItem.curriculumType === CurriculumType.GrapeSEED;
        const checkMaxUnitPerYear = !GLGlobal.isActionValid(GSSchoolAction.UncheckMaxUnitPerYear);
        if (isGrapeSEED && checkMaxUnitPerYear && maxUnitsPerYear && Math.abs(dataItem.maxUnit) - Math.abs(dataItem.startUnit) >= maxUnitsPerYear) {
            return fmtMsg(SchoolLocale.ClassUnitValidOverflowCommon, {
                value: maxUnitsPerYear,
            });
        }
        return null;
    };

    const closeDateValidator = (value: Date, dataItem: SchoolBulkClassModel, flex: FlexGrid): ReturnType<WijmoColumnValidatorFn> => {
        if (dataItem.action === BulkAction.Close && !dataItem.closeDate) {
            return GridValidators.required(SchoolLocale.BulkCloseDate)(value, dataItem, flex);
        }
        return null;
    };

    const save = (): void => {
        if (isValid) {
            submit();
        }
    };
    const saveAndSubmit = (): void => {
        if (isValid) {
            Modal.confirm({
                title: fmtMsg({ id: SchoolLocale.BulkSubmitConfirm }),
                okText: fmtMsg({ id: GLLocale.Ok }),
                cancelText: fmtMsg({ id: GLLocale.Cancel }),
                onOk: () => {
                    submit(true, true);
                },
            });
        }
    };

    const submit = (annualPrepComplete?: boolean, isSubmit?: boolean) => {
        const sourceCollection = gridRef.current.grid.collectionView.sourceCollection as SchoolBulkClassModel[];
        const data = {
            classes: sourceCollection.map((rowData) => {
                const d = { ...rowData };
                d.name = d.className;
                d.age = d.studentAgeGrade;
                d.startDate = DateHelper.toUTCDateLiteral(d.startDate);
                d.endDate = DateHelper.toUTCDateLiteral(d.endDate);
                d.closeDate = DateHelper.toUTCDateLiteral(d.closeDate);
                if ([BulkAction.Promote, BulkAction.Duplicate].includes(d.action) || d.exist) {
                    d.action = BulkAction.None;
                }
                if (d.curriculumType === CurriculumType.LittleSEED) {
                    d.startUnit = -d.startUnit;
                    d.maxUnit = -d.maxUnit;
                }
                d.tsiMinutesPerWeek = d.tsiMinutesPerWeek || null;
                d.tsiLessonsPerWeek = d.tsiLessonsPerWeek || null;
                d.tsiLessonsPerYear = d.tsiLessonsPerYear || null;

                delete d.teachers;
                delete d.students;

                return d;
            }),
            annualPrepComplete: annualPrepComplete || isAnnualPrepComplete,
            isSubmit
        };

        updateBulkEditClasses({ data, route: params, query: { offset: moment().utcOffset() }});
    };

    const highlightDuplicatePropmotedClass = (flex: FlexGrid, args: FormatItemEventArgs): void => {
        if (args.panel.cellType === CellType.Cell || args.panel.cellType === CellType.RowHeader) {
            const dataItem = flex.rows[args.row].dataItem as SchoolBulkClassModel;
            toggleClass(
                args.cell,
                "bulkedit__highlight-row",
                dataItem && (dataItem.action === BulkAction.Promoted || dataItem.action === BulkAction.Duplicated),
            );
        }
    };

    const renderUnitPlanEditCell = (context, value, item) => {
        return renderUnitPlanCell(value, item, context);
    };
    const renderUnitPlanCell = (value, item, context) => {
        return <div className="bulkedit__unit-plan-cell-content" >
            <GridReadOnlyCheckbox context={context} disabled />
        </div>
    };

    const getGridSource = () => grid ? grid.collectionView.sourceCollection : []
    const isSubmitDisabled = isOuterUser && !(getGridSource()).every(d => d.hasStartUnitPlan);
    const renderUnitPlanManagerLink = () => {
        if (getGridSource().some(d => d.dirty)) {
            return <Action key="1" className="bulkedit__action-unitplan" iconFont="glsunitplan" action={GSSchoolAction.EditUnitPlan} textLocaleId={SchoolLocale.UnitPlanBulkTitle} onClick={_ => {
                Modal.confirm({
                    title: fmtMsg({ id: SchoolLocale.BulkGoToUnitPlanManager }),
                    okText: fmtMsg({ id: GLLocale.Ok }),
                    cancelText: fmtMsg({ id: GLLocale.Cancel }),
                    onOk: () => props.history.push({ pathname: GLUtil.pathStringify(PathConfig.ClassUnitPlanBulk, params) }),
                })
            }} />;
        }
        return <Action key="1" className="bulkedit__action-unitplan" iconFont="glsunitplan" action={GSSchoolAction.EditUnitPlan} textLocaleId={SchoolLocale.UnitPlanBulkTitle} linkProps={{ to: GLUtil.pathStringify(PathConfig.ClassUnitPlanBulk, params) }} />
    }
    return (
        <Container fullWidth className="bce">
            <SecondaryTitle title={SchoolLocale.ClassBulkEdit}></SecondaryTitle>
            {school && school.maxUnit > -1 && (
                <Grid
                    ref={gridRef}
                    // itemsSource={dataSource}
                    headersVisibility={HeadersVisibility.All}
                    allowSorting={false}
                    selectionMode={SelectionMode.CellRange}
                    keyActionTab={KeyAction.Cycle}
                    newItemCreator={newItemCreator}
                    pagination={false}
                    actions={
                        isAnnualPrepComplete ? [
                            renderUnitPlanManagerLink(),
                        ] :
                            [
                                renderUnitPlanManagerLink(),
                                <Action key="2" materialIcon="add" textLocaleId={SchoolLocale.BulkNewClass} onClick={addNewClassHandler} />,
                            ]
                    }
                    beginningEdit={handleEdits}
                    cellDisableHandler={handleEdits}
                    cellEditEnded={cellEditEnded}
                    cellEditEnding={cellEditEnding}
                    allowDelete={false}
                    valid={setIsValid}
                    frozenColumns={2}
                    formatItem={highlightDuplicatePropmotedClass}
                    bordered
                >
                    <FlexGridCellTemplate
                        cellType={GridCellTemplateType.RowHeader}
                        template={(context: ICellTemplateContext) => {
                            const item = context.item;
                            return item && item.dirty ? (
                                <a href="javascript:void(0)" onClick={() => onDeleteRow(context)}>
                                    <MatIcon type="undo" />
                                </a>
                            ) : null;
                        }}
                    />
                    <Column
                        minWidth={100}
                        maxWidth={100}
                        isReadOnly={false}
                        binding="action"
                        header={fmtMsg({ id: SchoolLocale.BulkAction })}
                        dataMap={getActionDataMap(isPromotionAllowed, isAnnualPrepComplete)}
                    />
                    <Column
                        isReadOnly={false}
                        binding="className"
                        header={fmtMsg({ id: SchoolLocale.BulkClassName })}
                        render={(value) => value}
                        dataType={DataType.String}
                        validators={[
                            GridValidators.required(SchoolLocale.BulkClassName),
                            GridValidators.maxLength(50, SchoolLocale.BulkClassName),
                            classTermUniqueValidator,
                        ]}
                    />
                    <Column
                        dataMap={termDataMap}
                        isReadOnly={false}
                        binding="term"
                        header={fmtMsg({ id: SchoolLocale.BulkTerm })}
                        validators={[classTermUniqueValidator]}
                        isRequired={false}
                    />
                    <Column
                        minWidth={150}
                        maxWidth={150}
                        isReadOnly={false}
                        binding="startDate"
                        header={fmtMsg({ id: SchoolLocale.BulkStartDate })}
                        render={(value) => {
                            return DateHelper.toLocalString(value)
                        }}
                        dataType={DataType.Date}
                        renderEditor={(context) => {
                            return <GridInputDate format={dateFormat} context={context} />;
                        }}
                        validators={[
                            GridValidators.dateRequired(SchoolLocale.BulkStartDate),
                            GridValidators.maxDate("endDate", GSAdminLocale.NotificationCreateEarlierDate),
                            classTermUniqueValidator,
                        ]}

                    />
                    <Column
                        minWidth={150}
                        maxWidth={150}
                        isReadOnly={false}
                        binding="endDate"
                        dataType={DataType.Date}
                        header={fmtMsg({ id: SchoolLocale.BulkEndDate })}
                        render={(value) => DateHelper.toLocalString(value)}
                        renderEditor={(context) => {
                            return <GridInputDate format={dateFormat} context={context} />;
                        }}
                        isRequired={false}
                    />
                    <Column
                        minWidth={150}
                        maxWidth={150}
                        isReadOnly={false}
                        binding="closeDate"
                        header={fmtMsg({ id: SchoolLocale.BulkCloseDate })}
                        render={(value) => DateHelper.toLocalString(value)}
                        renderEditor={(context) => {
                            return <GridInputDate context={context} format={dateFormat} min={nextDate} value={nextDate} />;
                        }}
                        validators={[closeDateValidator]}
                    />
                    <Column
                        isReadOnly={false}
                        binding="keepTeachers"
                        header={fmtMsg({ id: SchoolLocale.BulkKeepTeachers })}
                        dataType={DataType.Boolean}
                        cellDisableHandler={handleEdits}
                        cssClass="bulkedit__checkbox-cell"
                        render={(value, item: SchoolBulkClassModel, context) => {
                            return (
                                <Row className="bulkedit__h100" type="flex" gutter={10} align="middle">
                                    {!context.isDisabled && (item.action === BulkAction.Promoted || item.action === BulkAction.Duplicated) ? (
                                        <>
                                            <Col>
                                                <GridCheckbox context={context} />
                                            </Col>
                                            {value && (
                                                <Col>
                                                    {renderTeacherStudentPopover(
                                                        item,
                                                        "teachers",
                                                        SchoolLocale.InvitationTitleTeacher,
                                                        (teacher: SchoolClassTeacher) => (
                                                            <>
                                                                {teacher.isMainTeacher && <Icon type="star" theme="filled" className="bulkedit__star" />}
                                                                {teacher.name}
                                                            </>
                                                        ),
                                                    )}
                                                </Col>
                                            )}
                                        </>
                                    ) : (
                                        <Col>
                                            {renderTeacherStudentPopover(
                                                item,
                                                "teachers",
                                                SchoolLocale.InvitationTitleTeacher,
                                                (teacher: SchoolClassTeacher) => (
                                                    <>
                                                        {teacher.isMainTeacher && <Icon type="star" theme="filled" className="bulkedit__star" />}
                                                        {teacher.name}
                                                    </>
                                                ),
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            );
                        }}
                    />
                    <Column
                        isReadOnly={false}
                        binding="keepStudents"
                        header={fmtMsg({ id: SchoolLocale.BulkKeepStudents })}
                        dataType={DataType.Boolean}
                        cellDisableHandler={handleEdits}
                        cssClass="bulkedit__checkbox-cell"
                        render={(value, item: SchoolBulkClassModel, context) => {
                            const isDuplicate = item.action === BulkAction.Duplicated;
                            const isPromoted = item.action === BulkAction.Promoted;
                            const { isDisabled } = context;
                            return (
                                <Row className="bulkedit__h100" type="flex" gutter={10} align="middle">
                                    {!isDisabled && isPromoted && (
                                        <>
                                            <Col>
                                                <GridCheckbox context={context} />
                                            </Col>
                                            {value && (
                                                <Col>
                                                    {renderTeacherStudentPopover(
                                                        item,
                                                        "students",
                                                        SchoolLocale.StudentStus,
                                                        (student: SchoolClassStudent) => student.name,
                                                    )}
                                                </Col>
                                            )}
                                        </>
                                    )}
                                    {isDisabled && !isDuplicate && (
                                        <Col>
                                            {renderTeacherStudentPopover(
                                                item,
                                                "students",
                                                SchoolLocale.StudentStus,
                                                (student: SchoolClassStudent) => student.name,
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            );
                        }}
                    />
                    <Column
                        isReadOnly={false}
                        binding="licenses"
                        header={fmtMsg({ id: SchoolLocale.BulkLicenses })}
                        renderEditor={(context) => <GridInputNumber context={context} min={0} format="n0" />}
                    />
                    <Column
                        isReadOnly={false}
                        binding="curriculumType"
                        dataMap={getCurriculumTypeDataMap(lsMaxUnit, maxUnit)}
                        header={fmtMsg({ id: SchoolLocale.BulkCurriculumType })}
                    />
                    <Column
                        isReadOnly={false}
                        binding="licenseType"
                        dataMap={getLicenseTypeValue(school, campusLicenses)}
                        header={fmtMsg({ id: SchoolLocale.BulkLicenseType })}
                        validators={[licenseTypeValidator]}
                    />
                    <Column
                        isReadOnly={false}
                        binding="studentAgeGrade"
                        header={fmtMsg({ id: SchoolLocale.BulkStudentAgeGrade })}
                        validators={[
                            GridValidators.required(SchoolLocale.BulkStudentAgeGrade),
                            GridValidators.maxLength(30, SchoolLocale.BulkStudentAgeGrade),
                        ]}
                    />
                    <Column
                        isReadOnly={false}
                        binding="startUnit"
                        header={fmtMsg({ id: SchoolLocale.BulkStartUnit })}
                        dataMap={getUnitColumnDataMap(true, school)}
                        validators={[GridValidators.max("maxUnit", SchoolLocale.ClassUnitValid), unitPerYearValidator]}
                    />
                    <Column
                        isReadOnly={false}
                        binding="maxUnit"
                        header={fmtMsg({ id: SchoolLocale.BulkMaxUnit })}
                        dataMap={getUnitColumnDataMap(false, school)}
                        validators={[unitPerYearValidator]}
                    />
                    <Column
                        isReadOnly={true}
                        binding="hasStartUnitPlan"
                        header={fmtMsg({ id: SchoolLocale.BulkHasStartUnitPlan })}
                        cssClass="bulkedit__unit-plan-cell"
                        render={renderUnitPlanCell}
                    />
                    <Column isReadOnly={false} binding="keepClassTimes" header={fmtMsg({ id: SchoolLocale.BulkKeepClassTimes })} />
                    <Column isReadOnly={true} binding="classTimes" header={fmtMsg({ id: SchoolLocale.BulkClassTimes })} />

                    <Column
                        isReadOnly={false}
                        binding="tsiMinutesPerWeek"
                        header={fmtMsg({ id: SchoolLocale.BulkTSIMinutesPerWeek })}
                        renderEditor={(context) => <GridInputNumber context={context} min={0} step={1} format="n0" />}
                    />
                    <Column
                        isReadOnly={false}
                        binding="tsiLessonsPerWeek"
                        header={fmtMsg({ id: SchoolLocale.BulkTSILessonsPerWeek })}
                        dataMap={new DataMap(new Array(7).fill(1).map((d, step) => d + step))}
                    />
                    <Column
                        isReadOnly={false}
                        binding="tsiLessonsPerYear"
                        header={fmtMsg({ id: SchoolLocale.BulkTSILessonsPerYear })}
                        renderEditor={(context) => <GridInputNumber context={context} min={0} step={1} format="n0" />}
                    />
                </Grid>
            )}
            <Row type="flex" gutter={8} justify="end">
                <Col>
                    <Button className="bulkedit__btn" type="primary" onClick={save}>
                        {fmtMsg(SchoolLocale.BulkSave)}
                    </Button>
                </Col>
                <Col>
                    {!isAnnualPrepComplete && (
                        <Button className="bulkedit__btn" type="primary" disabled={isSubmitDisabled} onClick={saveAndSubmit}>
                            {fmtMsg(SchoolLocale.BulkSaveandSubmit)}
                        </Button>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export const ClassBulkEditPage = withRouter(
    connect(
        ({ classes: { bulkClasses }, school: { current }, campus: { model } }: StateType) => ({
            bulkClasses,
            school: current,
            campus: model,
        }),
        { updateBulkEditClasses, initBulkEditor },
    )(ClassBulkEdit),
);
