import * as React from "react";
import { GLGlobal } from "gl-commonui";
import { CurriculumType, SubscriptionType } from "@app/service/class";
import { SubscribedStudents, Subscription } from ".";
import { StudentSubscriptionType } from "@app/util";
import { SchoolLocale } from "@app/locales/localeid";
import { RegionModel } from "@app/service/admin/regions";
import { ClassBillingCycleInfoModel } from "@app/states/school/class";
import { Loading } from "@app/components";

export const showSubscription = (
    curriculumType,
    subType,
    isDual,
    isSingle,
    counts,
    period,
    lastBilledPeriod,
    total,
    forceUpdateHanler,
    students,
    classRoles: string[],
    canEditLicense,
    regionId,
    onLicenseChange,
    onRemoveStudent,
    regionInfo: RegionModel,
    classInvoiceInfo: ClassBillingCycleInfoModel,
    disableFutureLicenseEdit
) => {
    const fmtMsg = GLGlobal.intl.formatMessage;
    const {
        LicenseSub,
        LicenseDigitalUpper,
        LicenseStudentCnt,
        LicenseDualUpper,
        LicenseChange,
        LicenseNewCnt,
        LicenseTextUpper,
        LicenseOtherCnt,
        LicenseText,
        LicenseNewOtherCnt,
        LicenseTypeLicense,
        LicenseDigital,
        LicenseSubDual,
        LicenseTypeUsageDualText,
        LicenseTypeUsageDualTextLower
    } = SchoolLocale;
    const getSubText = text =>
        isDual
            ? fmtMsg(
                  { id: LicenseSubDual },
                  { name: fmtMsg({ id: LicenseTypeUsageDualTextLower }) }
              )
            : text;

    if (typeof curriculumType === "undefined") {
        return <Loading visible={true} />;
    }
    if (curriculumType === CurriculumType.GrapeSEED) {
        if (subType === SubscriptionType.Digital) {
            return (
                <>
                    {!isDual && (
                        <Subscription
                            isDual={isDual}
                            isSingle={isSingle}
                            cnts={counts.sub}
                            period={period}
                            lastBilledPeriod={lastBilledPeriod}
                            title={getSubText(
                                fmtMsg(
                                    { id: LicenseSub },
                                    { type: fmtMsg({ id: LicenseDigital }) }
                                )
                            )}
                            currentCnt={fmtMsg({ id: LicenseStudentCnt })}
                            change={fmtMsg({ id: LicenseChange })}
                            newCnt={fmtMsg({ id: LicenseNewCnt })}
                            isSub
                            licenseTypeN={curriculumType}
                            subTypeN={subType}
                            counts={counts}
                            total={total}
                            relatedCnts={counts.license}
                            componentType="digital"
                            containerForceUpdate={forceUpdateHanler}
                            students={students}
                            canEditLicense={canEditLicense}
                            subscriptionType={StudentSubscriptionType.Digital}
                            onLicenseChange={onLicenseChange}
                            regionInfo={regionInfo}
                            classInvoiceInfo={classInvoiceInfo}
                            disableFutureLicenseEdit={disableFutureLicenseEdit}
                        >
                            <SubscribedStudents
                                classRoles={classRoles}
                                canEditLicense={canEditLicense}
                                subscriptionType={
                                    StudentSubscriptionType.Digital
                                }
                                componentType="digital"
                                isDual={isDual}
                                isSingle={isSingle}
                                regionId={regionId}
                                students={students}
                                onLicenseChange={onLicenseChange}
                                onRemoveStudent={onRemoveStudent}
                                subTypeN={subType}
                                regionInfo={regionInfo}
                                counts={counts}
                                disableFutureLicenseEdit={disableFutureLicenseEdit}
                            />
                        </Subscription>
                    )}
                    {!isSingle && (
                        <Subscription
                            isDual={isDual}
                            isSingle={isSingle}
                            cnts={counts.license}
                            period={period}
                            lastBilledPeriod={lastBilledPeriod}
                            title={fmtMsg(
                                { id: LicenseTypeLicense },
                                { type: fmtMsg({ id: LicenseDualUpper }) }
                            )}
                            currentCnt={fmtMsg(
                                { id: LicenseStudentCnt },
                                { type: fmtMsg({ id: LicenseText }) }
                            )}
                            change={fmtMsg({ id: LicenseChange })}
                            newCnt={fmtMsg(
                                { id: LicenseNewOtherCnt },
                                { type: fmtMsg({ id: LicenseText }) }
                            )}
                            licenseTypeN={curriculumType}
                            subTypeN={subType}
                            counts={counts}
                            total={total}
                            relatedCnts={counts.sub}
                            componentType="dual"
                            containerForceUpdate={forceUpdateHanler}
                            students={students}
                            canEditLicense={canEditLicense}
                            subscriptionType={StudentSubscriptionType.Dual}
                            onLicenseChange={onLicenseChange}
                            regionInfo={regionInfo}
                            classInvoiceInfo={classInvoiceInfo}
                            disableFutureLicenseEdit={disableFutureLicenseEdit}
                        >
                            <SubscribedStudents
                                classRoles={classRoles}
                                canEditLicense={canEditLicense}
                                subscriptionType={StudentSubscriptionType.Dual}
                                componentType="dual"
                                isDual={isDual}
                                isSingle={isSingle}
                                students={students}
                                onLicenseChange={onLicenseChange}
                                onRemoveStudent={onRemoveStudent}
                                subTypeN={subType}
                                regionInfo={regionInfo}
                                counts={counts}
                                disableFutureLicenseEdit={disableFutureLicenseEdit}
                            />
                        </Subscription>
                    )}
                </>
            );
        } else if (subType === SubscriptionType.Textbook) {
            return (
                <>
                    {!isDual && (
                        <Subscription
                            isDual={isDual}
                            isSingle={isSingle}
                            cnts={counts.sub}
                            period={period}
                            lastBilledPeriod={lastBilledPeriod}
                            title={getSubText(
                                fmtMsg(
                                    { id: LicenseSub },
                                    { type: fmtMsg({ id: LicenseText }) }
                                )
                            )}
                            currentCnt={fmtMsg({ id: LicenseStudentCnt })}
                            change={fmtMsg({ id: LicenseChange })}
                            newCnt={fmtMsg({ id: LicenseNewCnt })}
                            isSub
                            licenseTypeN={curriculumType}
                            subTypeN={subType}
                            counts={counts}
                            total={total}
                            relatedCnts={counts.license}
                            componentType="textbook"
                            containerForceUpdate={forceUpdateHanler}
                            students={students}
                            canEditLicense={canEditLicense}
                            subscriptionType={StudentSubscriptionType.TextBook}
                            onLicenseChange={onLicenseChange}
                            regionInfo={regionInfo}
                            classInvoiceInfo={classInvoiceInfo}
                            disableFutureLicenseEdit={disableFutureLicenseEdit}
                        >
                            <SubscribedStudents
                                classRoles={classRoles}
                                canEditLicense={canEditLicense}
                                subscriptionType={
                                    StudentSubscriptionType.TextBook
                                }
                                componentType="textbook"
                                isDual={isDual}
                                isSingle={isSingle}
                                students={students}
                                onLicenseChange={onLicenseChange}
                                onRemoveStudent={onRemoveStudent}
                                subTypeN={subType}
                                regionInfo={regionInfo}
                                counts={counts}
                                disableFutureLicenseEdit={disableFutureLicenseEdit}
                            />
                        </Subscription>
                    )}
                    {!isSingle && (
                        <Subscription
                            isDual={isDual}
                            isSingle={isSingle}
                            cnts={counts.license}
                            period={period}
                            lastBilledPeriod={lastBilledPeriod}
                            title={getSubText(
                                fmtMsg(
                                    { id: LicenseTypeLicense },
                                    { type: fmtMsg({ id: LicenseDualUpper }) }
                                )
                            )}
                            currentCnt={fmtMsg(
                                { id: LicenseStudentCnt },
                                { type: fmtMsg({ id: LicenseDigital }) }
                            )}
                            change={fmtMsg({ id: LicenseChange })}
                            newCnt={fmtMsg(
                                { id: LicenseNewOtherCnt },
                                { type: fmtMsg({ id: LicenseDigital }) }
                            )}
                            licenseTypeN={curriculumType}
                            subTypeN={subType}
                            counts={counts}
                            total={total}
                            relatedCnts={counts.sub}
                            componentType="dual"
                            containerForceUpdate={forceUpdateHanler}
                            students={students}
                            canEditLicense={canEditLicense}
                            subscriptionType={StudentSubscriptionType.Dual}
                            onLicenseChange={onLicenseChange}
                            regionInfo={regionInfo}
                            classInvoiceInfo={classInvoiceInfo}
                            disableFutureLicenseEdit={disableFutureLicenseEdit}
                        >
                            <SubscribedStudents
                                classRoles={classRoles}
                                canEditLicense={canEditLicense}
                                subscriptionType={StudentSubscriptionType.Dual}
                                componentType="dual"
                                isDual={isDual}
                                isSingle={isSingle}
                                students={students}
                                onLicenseChange={onLicenseChange}
                                onRemoveStudent={onRemoveStudent}
                                subTypeN={subType}
                                regionInfo={regionInfo}
                                counts={counts}
                                disableFutureLicenseEdit={disableFutureLicenseEdit}
                            />
                        </Subscription>
                    )}
                </>
            );
        }
    } else if (curriculumType === CurriculumType.LittleSEED) {
        return (
            <>
                <Subscription
                    cnts={counts.sub}
                    period={period}
                    lastBilledPeriod={lastBilledPeriod}
                    title={
                        fmtMsg({ id: LicenseSub }, { type: "" }).split("(")[0]
                    }
                    currentCnt={fmtMsg({ id: LicenseStudentCnt })}
                    change={fmtMsg({ id: LicenseChange })}
                    newCnt={fmtMsg({ id: LicenseNewCnt })}
                    isSub
                    licenseTypeN={curriculumType}
                    subTypeN={subType}
                    counts={counts}
                    total={total}
                    relatedCnts={counts.sub}
                    componentType="textbook"
                    containerForceUpdate={forceUpdateHanler}
                    students={students}
                    canEditLicense={canEditLicense}
                    subscriptionType={StudentSubscriptionType.TextBook}
                    onLicenseChange={onLicenseChange}
                    regionInfo={regionInfo}
                    classInvoiceInfo={classInvoiceInfo}
                    disableFutureLicenseEdit={disableFutureLicenseEdit}
                >
                    <SubscribedStudents
                        classRoles={classRoles}
                        canEditLicense={canEditLicense}
                        licenseTypeN={curriculumType}
                        subscriptionType={StudentSubscriptionType.TextBook}
                        componentType="textbook"
                        isDual={isDual}
                        isSingle={isSingle}
                        students={students}
                        onLicenseChange={onLicenseChange}
                        onRemoveStudent={onRemoveStudent}
                        subTypeN={subType}
                        regionInfo={regionInfo}
                        counts={counts}
                        disableFutureLicenseEdit={disableFutureLicenseEdit}
                    />
                </Subscription>
            </>
        );
    }
};
